import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useGetDistrictsMutation,
  useGetHospitalsMutation,
  useGetSubCountiesMutation,
  useRegisterHealthWorkerMutation,
} from "../../apis/authApi";
import { BlankCheckboxIcon, CheckedCheckboxIcon } from "../../assets/Icons";
import { addNotification } from "../../store/slices/toaster-slice";
import Button from "../common/Button";
import DropDown from "../common/Dropdown";

const SelectHealthFacility = ({ accountType, registerData }) => {
  const { userDetails } = useSelector((state) => state.user);
  const [allDistricts, setAllDistricts] = useState([]);
  const [allSubcounties, setAllSubcounties] = useState([]);
  const [allHospitals, setAllHospitals] = useState([]);
  const [getDistricts, districtsLoading] = useGetDistrictsMutation();
  const [getSubcounties, subcountiesLoading] = useGetSubCountiesMutation();
  const [getHospitals, hospitalsLoading] = useGetHospitalsMutation();
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedSubcounty, setSelectedSubcounty] = useState("");
  const [selectedHospital, setSelectedHospital] = useState("");
  const [selectedHospitalCode, setSelectedHospitalCode] = useState("");
  const [getRegistryHW, registryHWLoading] = useRegisterHealthWorkerMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    handleGetDistricts();
  }, []);

  // fetch data based on selected district
  useEffect(() => {
    if (selectedDistrict) {
      handleGetSubcounty();
    }
  }, [selectedDistrict]);

  // fetch data based on selected Sub-county
  useEffect(() => {
    if (selectedSubcounty) {
      handleGetHospital();
    }
  }, [selectedSubcounty]);

  const handleGetDistricts = async () => {
    try {
      const response = await getDistricts().unwrap();

      // // Convert the response back to an array of objects
      const uniqueDistrictsArray = Array.from(response?.result?.districts).map(
        (district) => ({
          label: district.district,
          value: district.district,
          id: district.id,
        })
      );
      // Update the state with unique districts
      setAllDistricts(uniqueDistrictsArray);
      
    } catch (error) {
      dispatch(addNotification({ type: "error", message: "Unable to fetch!" }));
    }
  };
  const handleGetSubcounty = async () => {
    try {
      let param = {
        districtId: selectedDistrict?.id,
      };
      const response = await getSubcounties(param).unwrap();

      // Convert the Set back to an array of objects
      const uniqueSubcountiesArray = Array.from(
        response?.result?.subCounties
      ).map((sub_county) => ({
        label: sub_county.subCountyName,
        value: sub_county.subCountyName,
        id: sub_county.id,
      }));
      // Update the state with unique sub-county
      setAllSubcounties(uniqueSubcountiesArray);
    } catch (error) {
      dispatch(addNotification({ type: "error", message: "Unable to fetch!" }));
    }
  };
  const handleGetHospital = async () => {
    try {
      let param = {
        districtId: selectedDistrict?.id,
        subCountyId: selectedSubcounty?.id,
      };
      const response = await getHospitals(param).unwrap();

      // // Convert the Set back to an array of objects
      const uniqueHospitalArray = Array.from(
        response?.result?.healthFacility
      ).map((hospital) => ({
        label: hospital.name,
        value: hospital.name,
        ...hospital,
      }));
      // // Update the state with unique hospital
      setAllHospitals(uniqueHospitalArray);
    } catch (error) {
      dispatch(addNotification({ type: "error", message: "Unable to fetch!" }));
    }
  };

  const handleSelectHospital = (hospital) => {
    setSelectedHospitalCode(hospital);
  };

  // Register Healthworker
  const registerHealthWorker = async () => {
    try {
      let userId = sessionStorage.getItem("userID");
      const data = {
        userId: userId ?? parseInt(userDetails?.userId),
        healthFacilityId: selectedHospitalCode.id,
        hwType: accountType,
      };
      const response = await getRegistryHW(data).unwrap();
      sessionStorage.removeItem("userID");
      addNotification({ type: "success", message: "Successfully Register" });
      navigate("/confirmation");
    } catch (error) {
      dispatch(addNotification({ type: "error", message: "Unable to fetch!" }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    registerHealthWorker();
  };

  return (
    <div className="choose-account lg:pb-8 xl:pt-[100px] xl:px-[50px] lg:pt-[70px] lg:px-[40px] md:p-8 p-5 md:pt-[70px] pt-[70px] flex flex-col h-full max-h-[calc(100%-44px)] relative">
      <div className="lg:max-w-[450px] flex flex-col justify-between flex-1">
        <div className="flex-1">
          <div>
            <h3 className="xl:text-3xl lg:text-2xl md:text-xl text-lg h-inter font-bold">
              Select Hospital
            </h3>
            <p className="text-color_8692A6 xl:text-lg md:text-base text-sm pt-4 h-inter">
              To complete this journey, please select your Health Facility
            </p>
          </div>
          <div className="pt-4 border-t border-[#F5F5F5] mt-[14px]">
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col lg:gap-6 gap-4">
                <DropDown
                  options={allDistricts}
                  inputWidth={"100%"}
                  isMultiSelected={false}
                  placeholder="Select Districts"
                  onSelectChange={(value) => setSelectedDistrict(value)}
                  isLoading={districtsLoading?.isLoading}
                  selectedValue={selectedDistrict}
                />
                <DropDown
                  options={allSubcounties}
                  isSearchable={true}
                  inputWidth={"100%"}
                  isMultiSelected={false}
                  placeholder="Select Sub-counties"
                  onSelectChange={(value) => setSelectedSubcounty(value)}
                  isLoading={subcountiesLoading?.isLoading}
                  selectedValue={selectedSubcounty}
                  disabled={selectedDistrict ? false : true}
                />
                <DropDown
                  options={allHospitals}
                  isSearchable={true}
                  inputWidth={"100%"}
                  isMultiSelected={false}
                  placeholder="Select Hospital"
                  disabled={
                    selectedSubcounty && selectedDistrict ? false : true
                  }
                  onSelectChange={(value) => setSelectedHospital(value)}
                  isLoading={hospitalsLoading?.isLoading}
                  selectedValue={selectedHospital}
                />
              </div>
              {allHospitals?.length > 0 && (
                <div className="my-7">
                  <h3 className="lg:text-lg text-base h-inter font-bold pt-3 pb-5">
                    Choose Hospital
                  </h3>
                  <div className="flex gap-3 flex-wrap">
                    {allHospitals.map((hospital) => {
                      return (
                        <div
                          className="relative basis-[calc(33.33%-8px)] p-3 pt-5 bg-white shadow-cardShadow cursor-pointer"
                          onClick={() => {
                            handleSelectHospital(hospital);
                          }}
                        >
                          <p className="text-xs font-medium">
                            {hospital?.code}
                          </p>
                          <p className="text-sm font-medium">
                            {hospital?.name}
                          </p>

                          <div className="absolute top-2 right-2">
                            {selectedHospitalCode.code === hospital?.code ? (
                              <CheckedCheckboxIcon />
                            ) : (
                              <BlankCheckboxIcon />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              <div className="lg:mt-9 mt-6">
                <Button
                  type="submit"
                  btnType="normal"
                  // isLoading={registerUserLoading?.isLoading}
                >
                  Complete Process
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectHealthFacility;
