import React, { useEffect, useState } from "react";
import { BlankCheckboxIcon, CheckedCheckboxIcon } from "../../../assets/Icons";

const ListWithCheckbox = ({
  list,
  fontClass = "",
  name,
  ListWrapperClass = "px-[32px] py-[26px]",
  changeEvent,
  isDisabled = false,
  selectedIdx = [],
  tag = "",
}) => {

  const [selectedItems, setSelectedItems] = useState(selectedIdx);

  const toggleCheckbox = (index) => {};
  const handleCheckboxChange = (e) => {
    let { name, value } = e.target;
    const newSelectedItems = [...selectedItems];
    if (newSelectedItems.includes(parseInt(value))) {
      newSelectedItems.splice(newSelectedItems.indexOf(parseInt(value)), 1);
    } else {
      newSelectedItems.push(parseInt(value));
    }
    setSelectedItems(newSelectedItems);
    changeEvent(e);
  };

  useEffect(() => {
    if (
      tag === "vaccination-card" &&
      !arraysEqual(selectedItems, selectedIdx)
    ) {
      setSelectedItems(selectedIdx);
    }
  }, [tag, selectedIdx, selectedItems]);

  // Helper function to compare arrays
  const arraysEqual = (arr1, arr2) => {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
  };

  return (
    <div className={ListWrapperClass}>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:gap-x-[100px] lg:gap-x-[50px] md:gap-x-[30px]">
        {list?.length > 0 &&
          list?.map((item, index) => {
            const isChecked = selectedItems.includes(item?.id);
            return (
              <div
                className="flex items-center justify-between xl:mb-5 mb-3 relative gap-3"
                key={index}
                onClick={() => toggleCheckbox(index)}
              >
                <label
                  className={`flex items-center space-x-2 cursor-pointer ${fontClass}`}
                >
                  <span>{item?.reason}</span>
                </label>
                <div className="w-[23px] h-[23px] relative p-[1.5px]">
                  <input
                    type="checkbox"
                    name={name}
                    value={item?.id}
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    className="specialCare-check form-checkbox"
                    disabled={isDisabled}
                  />
                  <span className="absolute left-0 top-0"></span>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ListWithCheckbox;
