import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "../../scss/accounts/login.scss";
import { Logo } from "../../assets/Icons";
import Input from "../../components/form/input";
import Button from "../../components/common/Button";
import AccountFooterLink from "../../components/register/AccountFooterLink";
import { useLoginMutation } from "../../apis/authApi";
import { useDispatch } from "react-redux";
import { addNotification } from "../../store/slices/toaster-slice";
import { setUserDetails } from "../../store/slices/userDetail-slice";

const validateLoginForm = (data) => {
  let errors = {};
  let isValid = true;

  if (!data?.userName) {
    errors.userName = "Username is required";
    isValid = false;
  }
  if (!data?.password) {
    errors.password = "Password is required";
    isValid = false;
  }
  return { isValid, errors };
};

const initialState = {
  userName: "",
  password: "",
};

const Login = () => {
  const [formData, setFormData] = useState(initialState);
  const [formError, setFormError] = useState({});
  const [loginUser, loginUserLoading] = useLoginMutation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Function to handle change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((state) => ({ ...state, [name]: value }));
    setFormError((state) => ({ ...state, [name]: "" }));
  };

  // Handle User Login
  const handleLoginUser = async () => {
    try {
      const userData = {
        userName: formData.userName,
        password: formData.password,
      };

      const response = await loginUser(userData).unwrap();
      dispatch(
        addNotification({ type: "success", message: "Successfully Login" })
      );
      sessionStorage.setItem("access_token", response?.result?.user?.token);
      dispatch(setUserDetails(response?.result?.user));
      navigate("/dashboard");
    } catch (error) {
      if (error?.data?.result?.hospital == false) {
        dispatch(setUserDetails(error?.data?.result));
        sessionStorage.setItem("userID", error?.data?.result?.userId);
        dispatch(
          addNotification({ type: "error", message: error?.data?.message })
        );
        navigate(
          `/register?step=selectHospital&hospital=${error?.data?.result?.hospital}`
        );
      } else {
        dispatch(
          addNotification({ type: "error", message: error?.data?.message })
        );
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { isValid, errors } = validateLoginForm(formData);
    setFormError(errors);

    if (isValid) {
      setFormError({});
      handleLoginUser();
    }
  };

  return (
    <div className="login-page">
      <div className="flex min-h-screen lg:flex-row flex-col">
        <div className="basis-1/2 login-banner bg-cover bg-center bg-no-repeat bg-account-banner flex items-center justify-center max-h-screen sticky top-0 left-0 max-lg:hidden">
          <div className="flex flex-col gap-6 account-logo">
            <Logo />
            <h2 className="xl:text-[35px] lg:text-[28px] text-black">
              Welcome to MedRef
            </h2>
            <p className="text-base text-black flex items-center gap-1">
              If you don’t have an account You can{" "}
              <Link to="/register" className="text-primary font-semibold">
                Register here !
              </Link>
            </p>
          </div>
        </div>
        <div className="block lg:hidden pt-8">
          <div className="flex flex-col gap-4 items-center account-logo">
            <Logo />
            <div className="text-center">
              <h2 className="text-2xl text-black">Welcome to MedRef</h2>
            </div>
          </div>
        </div>
        <div className=" lg:basis-1/2 basis-full flex-1 pb-8 lg:pb-8 xl:pt-[100px] xl:px-[50px] lg:pt-[70px] lg:px-[40px] md:p-8 p-5 md:pt-[40px] pt-[40px] flex">
          <div className="lg:max-w-[450px] flex flex-col justify-between flex-1">
            <div>
              <div className="">
                <h3 className="xl:text-3xl lg:text-2xl md:text-xl text-lg h-inter font-bold">
                  Login to Healthcare Provider Account!
                </h3>
                <p className="text-color_8692A6 xl:text-lg md:text-base text-sm pt-4 h-inter">
                  For the purpose of MedRef regulation, your details are
                  required.
                </p>
              </div>

              <div className="pt-4 border-t border-[#F5F5F5] mt-[14px]">
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-col lg:gap-6 gap-4">
                    <Input
                      label="Username"
                      placeholder={"Enter your username"}
                      type="text"
                      className="border border-color_758092 h-[40px] rounded-[6px] px-4 py-2"
                      name="userName"
                      changeEvent={handleChange}
                      isErrorMsg={formError?.userName}
                      attributes={{ value: formData?.userName }}
                      isFocus={true}
                    />
                    <div className="">
                      <Input
                        label="Password"
                        placeholder={"Enter your password"}
                        type="password"
                        className="border border-color_758092 h-[40px] rounded-[6px] px-4 py-2"
                        name="password"
                        changeEvent={handleChange}
                        isErrorMsg={formError?.password}
                        attributes={{ value: formData?.password }}
                      />
                      <Link
                        to="/forgot-password"
                        className="pt-1.5 block text-right text-primary lg:text-sm  text-xs"
                      >
                        Forgot Password?
                      </Link>
                    </div>
                    <p className="md:text-sm text-xs text-[#989898]">
                      By continuing you agree to our{" "}
                      <Link to="" className="text-primary">
                        Terms of use
                      </Link>{" "}
                      and our{" "}
                      <Link to="" className="text-primary">
                        Privacy Policy
                      </Link>
                    </p>
                  </div>
                  <div className="lg:mt-9 mt-6">
                    <Button
                      type="submit"
                      isLoading={loginUserLoading.isLoading}
                    >
                      Log in
                    </Button>
                  </div>
                </form>
                <div className="pt-3 lg:hidden">
                  <p className="lg:text-base md:text-base text-sm text-black flex items-center justify-center gap-1 max-md:flex-col">
                    If you don’t have an account You can{" "}
                    <Link to="/register" className="text-primary font-semibold">
                      Register here !
                    </Link>
                  </p>
                </div>
              </div>
            </div>

            <AccountFooterLink />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
