import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PasswordLikeInput from "../../components/PasswordLikeInput";
import MinistryLabel from "../../components/common/MinistryLabel/MinistryLabel";
import Input from "../../components/form/input";
import { calculateAge, regEx } from "../../utils/helper";
import { addNotification } from "../../store/slices/toaster-slice";
import Button from "../../components/common/Button";

const validateRegForm = (data) => {
  let errors = {};
  let isValid = true;

  if (!data?.name) {
    errors.name = "Name is required";
    isValid = false;
  } else if (!regEx.NAME.test(data?.name)) {
    errors.name = "Invalid name";
    isValid = false;
  }
  if (!data?.nin) {
    errors.nin = "NIN is required";
    isValid = false;
  }
  if (!data?.category) {
    errors.category = "Please select your category";
    isValid = false;
  }
  if (!data?.district) {
    errors.district = "District is required";
    isValid = false;
  }
  if (!data?.subcounty) {
    errors.subcounty = "Sub county is required";
    isValid = false;
  }
  if (!data?.parish) {
    errors.parish = "Parish is required";
    isValid = false;
  }
  if (!data?.village) {
    errors.village = "Village place is required";
    isValid = false;
  }
  return { isValid, errors };
};

const initialState = {
  name: "",
  nin: 0,
  sex: "Female",
  category: "",
  dob: new Date(),
  contact: "",
  district: "",
  subcounty: "",
  parish: "",
  village: "",
};

const AddNewHPV = () => {
  const [formData, setFormData] = useState(initialState);
  const [formError, setFormError] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDateChange = (name, value) => {
    setFormData((prevObj) => ({
      ...prevObj,
      [name]: value,
    }));
  };
  const handleNinInput = (name, value) => {
    setFormData((prevObj) => ({
      ...prevObj,
      [name]: value,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevObj) => ({
      ...prevObj,
      [name]: value,
    }));
  };

  const handleRegisterTD = () => {
    try {
    } catch (error) {
      dispatch(addNotification({ type: "error", message: "error" }));
    }
  };

  // Handle Submit
  const handleSubmit = (e) => {
    e.preventDefault();
    const { isValid, errors } = validateRegForm(formData);
    setFormError(errors);

    if (isValid) {
      setFormError({});
      handleRegisterTD();
    }
  };
  return (
    <div className="xl:px-[35px] xl:py-12 lg:px-5 lg:py-7 p-4">
      <div className="flex gap-3 justify-between md:items-end max-md:flex-col">
        <div>
          <MinistryLabel title="Add HPV Card" />
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="lg:mt-8 mt-6 bg-[#FFFFFF] rounded-xl lg:px-[26px] px-[14px] lg:py-[32px] py-[20px] flex flex-col gap-3 md:gap-5 xl:gap-7">
          <div className="flex flex-col md:flex-row gap-3 md:gap-5 xl:gap-7 flex-wrap ">
            <PasswordLikeInput
              label="NIN"
              name="nin"
              className="w-auto"
              changeEvent={handleNinInput}
              isErrorMsg={formError?.nin}
              attributes={{ value: formData?.nin }}
            />
          </div>
          <div className="flex flex-col md:flex-row gap-3 md:gap-5 xl:gap-7 flex-wrap">
            <Input
              label="Name"
              placeholder={"Full name"}
              type="text"
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
              changeEvent={handleInputChange}
              name="name"
              isErrorMsg={formError?.name}
              attributes={{ value: formData?.name }}
            />
            <Input
              label="Date of Birth"
              placeholder={"DD/MM/YYYY"}
              dateFormat={"dd/MM/yyyy"}
              type="datePicker"
              name="dob"
              changeEvent={handleDateChange}
            />
            <Input
              label="Age"
              placeholder={"Your age"}
              type="text"
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
              name="age"
              isDisabled={true}
              attributes={{ value: calculateAge(formData?.dob) }}
            />
          </div>
          <div className="flex flex-col md:flex-row gap-3 md:gap-5 xl:gap-7 flex-wrap">
            <Input
              label="Category"
              placeholder={"Enter Category"}
              type="radio"
              options={[
                { name: "National", id: "national" },
                { name: "Refugee", id: "refugee" },
                { name: "Foreigner", id: "foreigner" },
              ]}
              isErrorMsg={formError?.category}
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full text-font"
              changeEvent={handleInputChange}
              name="category"
            />
          </div>
          <div className="flex flex-col md:flex-row gap-3 md:gap-5 xl:gap-7 flex-wrap">
            <Input
              label="Caretaker"
              placeholder={"Enter Caretaker"}
              type="text"
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
              changeEvent={handleInputChange}
              name="caretaker"
              isErrorMsg={formError?.caretaker}
              attributes={{ value: formData?.caretaker }}
            />
            <Input
              label="Contact"
              placeholder={"Enter Mother’s Contact No."}
              type="text"
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
              changeEvent={handleInputChange}
              name="contact"
              attributes={{
                
                value: formData?.contact,
              }}
              isErrorMsg={formError?.contact}
            />
            <Input
              label="Name of School"
              placeholder={"Your School Name"}
              type="text"
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
              changeEvent={handleInputChange}
              name="schoolName"
              attributes={{ value: calculateAge(formData?.schoolName) }}
              isErrorMsg={formError?.schoolName}
            />
            <Input
              label="Class in school"
              placeholder={"Your Class in School"}
              type="text"
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
              changeEvent={handleInputChange}
              name="schoolClass"
              attributes={{ value: calculateAge(formData?.schoolClass) }}
              isErrorMsg={formError?.schoolClass}
            />
            <Input
              label="District/City"
              placeholder={"Enter District/City"}
              type="text"
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
              changeEvent={handleInputChange}
              name="district"
              isErrorMsg={formError?.district}
              attributes={{ value: formData?.district }}
            />
            <Input
              label="Sub Country/ Division"
              placeholder={"Enter Sub Country/ Division"}
              type="text"
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
              changeEvent={handleInputChange}
              name="subcounty"
              isErrorMsg={formError?.subcounty}
              attributes={{ value: formData?.subcounty }}
            />
            <Input
              label="Parish/Ward:"
              placeholder={"Enter Parish/Ward:"}
              type="text"
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
              changeEvent={handleInputChange}
              name="parish"
              isErrorMsg={formError?.parish}
              attributes={{ value: formData?.parish }}
            />
            <Input
              label="LC 1/Village/Cell"
              placeholder={"Enter LC 1/Village/Cell"}
              type="text"
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
              changeEvent={handleInputChange}
              name="village"
              isErrorMsg={formError?.village}
              attributes={{ value: formData?.village }}
            />
          </div>

          <div className="flex flex-col md:flex-row gap-3 md:gap-5 xl:gap-7 flex-wrap"></div>

          <div className="flex flex-col md:flex-row gap-3 md:gap-5 xl:gap-7 flex-wrap"></div>
          <div className="lg:mt-9 mt-6 flex justify-end">
            <Button
              type="submit"
              btnType="normal"
              //   isLoading={registerTDLoading?.isLoading}
              classname="md:max-w-[232px]"
            >
              Create Card
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddNewHPV;
