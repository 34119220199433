import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAddVaccinationRecordMutation } from "../../apis/authApi";
import { addNotification } from "../../store/slices/toaster-slice";
import Button from "../common/Button";
import DropDown from "../common/Dropdown";
import Input from "../form/input";
import { formatDateAsscen } from "../../utils/helper";
import dayjs from "dayjs";

// 1. Date should be disable and gray if vaccine is timely taken
// 2. User will not be allowed to perform operation on upcoming dates

const validateRegForm = (data) => {
  let errors = {};
  let isValid = true;

  if (!data?.bodyWeight) {
    errors.bodyWeight = "Current body weight is required";
    isValid = false;
  }
  if (!data?.vaccineMode) {
    errors.vaccineMode = "Vaccination mode is required";
    isValid = false;
  }
  return { isValid, errors };
};

const ChildVaccinePopup = ({
  closePopup,
  data,
  currentValue,
  refreshTable,
  childId,
  childNo,
}) => {
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState({});
  const [vaccineData, setVaccineData] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [alreadyVaccinated, setAlreadyVaccinated] = useState(false);
  const [futureSchedule, setFutureSchedule] = useState(false);
  const [addVaccineRecord, vaccineRecordLoading] =
    useAddVaccinationRecordMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    const vaccine = data[0].vaccineSchedules.filter(
      (schedule) => schedule.id === currentValue?.vaccines?.[0] && schedule
    );
    setVaccineData(vaccine);
  }, [data, currentValue]);

  useEffect(() => {
    const currentDate = new Date();
    setAlreadyVaccinated(
      vaccineData?.[0]?.vaccinatedDate || vaccineData?.[0]?.status === "T"
        ? true
        : false
    );
    setFutureSchedule(
      new Date(currentValue?.scheduleDate) > currentDate ? true : false
    );
  }, [vaccineData]);

  const handleDateChange = (name, value) => {
    setFormData((prevObj) => ({
      ...prevObj,
      [name]: value,
    }));
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevObj) => ({
      ...prevObj,
      [name]: value,
    }));
  };

  const handleUpdateVaccine = async () => {
    try {
      let vaccineData = {
        childWeight: parseInt(formData?.bodyWeight),
        mode: formData?.vaccineMode,
        childId: childId,
        vaccines: currentValue?.vaccines,
        vaccinatedDate: formData?.vaccinatedDate
          ? formData?.vaccinatedDate
          : new Date(),
      };
      const response = await addVaccineRecord(vaccineData).unwrap();

      dispatch(
        addNotification({
          type: "success",
          message: "Vaccine recorded successfully",
        })
      );
      closePopup();
      refreshTable(childNo);
    } catch (error) {
      dispatch(
        addNotification({
          type: "error",
          message: error?.data?.result,
        })
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { isValid, errors } = validateRegForm(formData);
    setFormError(errors);

    if (isValid) {
      handleUpdateVaccine();
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="flex lg:gap-6 gap-4 max-md:flex-col">
          <div className="lg:basis-[calc(50%-12px)] basis-[calc(50%-8px)]">
            <Input
              label="Child No."
              placeholder={"Enter Child No."}
              type="text"
              isReadOnly={true}
              className="border !border-primary lg:h-[40px] h-[40px] rounded-[6px] px-4 py-2 w-full"
              name="childNo"
              attributes={{ value: childNo }}
            />
          </div>
          <div className="lg:basis-[calc(50%-12px)] basis-[calc(50%-8px)]">
            {alreadyVaccinated ? (
              <Input
                label="Vaccinated On"
                placeholder={"Vaccinated Date"}
                type="text"
                isReadOnly={true}
                className="normal-input"
                name="vaccinatedOn"
                attributes={{
                  value: formatDateAsscen(vaccineData?.[0]?.vaccinatedDate),
                }}
              />
            ) : (
              <Input
                label="Scheduled Date"
                placeholder={"Scheduled Date"}
                type="text"
                isReadOnly={true}
                className="normal-input"
                name="scheduleAt"
                attributes={{
                  value: formatDateAsscen(currentValue?.scheduleDate),
                }}
              />
            )}
          </div>
        </div>
        <div className="flex lg:gap-6 gap-4 max-md:flex-col mt-5">
          <div className="lg:basis-[calc(50%-12px)] basis-[calc(50%-8px)]">
            <Input
              label="Vaccines"
              type="text"
              isReadOnly={true}
              className="border !border-primary lg:h-[40px] h-[40px] rounded-[6px] px-4 py-2 w-full"
              name="vaccineName"
              attributes={{ value: currentValue.vaccineName }}
            />
          </div>
          {!alreadyVaccinated && !futureSchedule && (
            <div className="lg:basis-[calc(50%-12px)] basis-[calc(50%-8px)]">
              <Input
                label="Vaccination Date"
                placeholder={"DD/MM/YYYY"}
                type="datePicker"
                name="vaccinatedDate"
                attribute={{ minDate: dayjs(currentValue?.scheduleDate) }}
                changeEvent={handleDateChange}
              />
            </div>
          )}
          {alreadyVaccinated && (
            <div className="lg:basis-[calc(50%-12px)] basis-[calc(50%-8px)]">
              <Input
                label="Body Weight (in kg)"
                type="text"
                isReadOnly={true}
                className="border !border-primary lg:h-[40px] h-[40px] rounded-[6px] px-4 py-2 w-full"
                name="weight"
                attributes={{ value: vaccineData?.[0]?.weight }}
              />
            </div>
          )}
        </div>

        {!futureSchedule && !alreadyVaccinated && (
          <div className="flex lg:gap-6 gap-4 max-md:flex-col mt-5">
            <div className="lg:basis-[calc(50%-12px)] basis-[calc(50%-8px)]">
              <Input
                label="Current Body Weight (in kg)"
                placeholder={"Enter Current Body Weight"}
                type="number"
                className="border !border-primary lg:h-[40px] h-[40px] rounded-[6px] px-4 py-2 w-full"
                changeEvent={handleInputChange}
                isErrorMsg={formError?.bodyWeight}
                name="bodyWeight"
                attributes={{ value: formData?.bodyWeight }}
              />
            </div>
            <div className="lg:basis-[calc(50%-12px)] basis-[calc(50%-8px)]">
              <Input
                label="Vaccination Mode"
                placeholder={"Vaccination Mode"}
                type="radio"
                options={[
                  { name: "Static", id: "static" },
                  { name: "Outreached", id: "outreached" },
                ]}
                isErrorMsg={formError?.vaccineMode}
                className="normal-input"
                changeEvent={handleInputChange}
                name="vaccineMode"
              />
            </div>
          </div>
        )}
        {alreadyVaccinated && (
          <div className="flex lg:gap-6 gap-4 max-md:flex-col mt-5">
            <div className="lg:basis-[calc(50%-12px)] basis-[calc(50%-8px)]">
              <Input
                label="Vaccine Mode"
                type="text"
                isReadOnly={true}
                className="border !border-primary lg:h-[40px] h-[40px] rounded-[6px] px-4 py-2 w-full"
                name="vaccineMode"
                attributes={{ value: vaccineData?.[0]?.mode }}
              />
            </div>
          </div>
        )}

        <div className="flex gap-3 items-center lg:mt-9 mt-6">
          <div className="w-1/2">
            <Button
              type="submit"
              btnType="normal"
              isLoading={vaccineRecordLoading?.isLoading}
              classname={`${
                (futureSchedule || alreadyVaccinated) &&
                "opacity-50 pointer-events-none"
              }`}
            >
              Save
            </Button>
          </div>
          <div className="w-1/2">
            <Button type="submit" btnType="normal" clickHandler={closePopup}>
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChildVaccinePopup;
