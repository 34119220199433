import { createSlice } from "@reduxjs/toolkit";

const toasterSlice = createSlice({
  name: "toaster",
  initialState: [],
  reducers: {
    addNotification: (state, action) => {
      return action.payload;
    },
    clearNotifications: (state) => {
      return null;
    }, 
  },
});

export const { addNotification, clearNotifications } =
  toasterSlice.actions;

export default toasterSlice.reducer;
