import React from "react";
import { formatDate, formatDateAsscen } from "../../utils/helper";
import useMediaQuery from "../common/mediaQuery/MediaQueryHook";

const TDVaccinationTable = ({ data }) => {
  const isLargeScreen = useMediaQuery("(min-width: 768px)");

  return (
    <div className="vaccination-table">
      <table className="">
        <thead>
          {isLargeScreen ? (
            <tr>
              <td>Details</td>
              <td>Information</td>
              <td>Details</td>
              <td>Information</td>
            </tr>
          ) : (
            <tr>
              <td>Details</td>
              <td>Information</td>
            </tr>
          )}
        </thead>
        {isLargeScreen ? (
          <tbody>
            <tr>
              <td>Client No.</td>
              <td className="font-semibold">{data?.clientNo ?? "---"}</td>
              <td>Client Name</td>
              <td className="font-semibold">{data?.name ?? "---"}</td>
            </tr>
            <tr>
              <td>Client Category</td>
              <td className="font-semibold">{data?.category ?? "---"}</td>
              <td>Sex</td>
              <td className="font-semibold">{data?.gender ?? "---"}</td>
            </tr>
            <tr>
              <td>Date of Birth</td>
              <td className="font-semibold">
                {formatDateAsscen(data?.dateOfBirth) ?? "---"}
              </td>
              <td>Age</td>
              <td className="font-semibold">{data?.age ?? "---"}</td>
            </tr>
            <tr>
              <td>District/City</td>
              <td className="font-semibold">{data?.district ?? "---"}</td>
              <td>Sub Country/ Division</td>
              <td className="font-semibold">{data?.subCounty ?? "---"}</td>
            </tr>
            <tr>
              <td>Parish/Ward:</td>
              <td className="font-semibold">{data?.ward ?? "---"}</td>
              <td>LC 1/Village/Cell</td>
              <td className="font-semibold">{data?.village ?? "---"}</td>
            </tr>
            <tr>
              <td>Contact:</td>
              <td className="font-semibold">{data?.contact ?? "---"}</td>
              <td>NIN</td>
              <td className="font-semibold">{data?.nin ?? "---"}</td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td>Client No.</td>
              <td className="font-semibold">{data?.clientNo ?? "---"}</td>
            </tr>
            <tr>
              <td>Client Name</td>
              <td className="font-semibold">{data?.name ?? "---"}</td>
            </tr>
            <tr>
              <td>Client Category</td>
              <td className="font-semibold">{data?.category ?? "---"}</td>
            </tr>
            <tr>
              <td>Sex</td>
              <td className="font-semibold">{data?.gender ?? "---"}</td>
            </tr>
            <tr>
              <td>NIN</td>
              <td className="font-semibold">{data?.nin ?? "---"}</td>
            </tr>
            <tr>
              <td>Date of Birth</td>
              <td className="font-semibold">
                {formatDateAsscen(data?.dateOfBirth) ?? "---"}
              </td>
            </tr>
            <tr>
              <td>Age</td>
              <td className="font-semibold">{data?.age ?? "---"}</td>
            </tr>
            <tr>
              <td>District/City</td>
              <td className="font-semibold">{data?.district ?? "---"}</td>
            </tr>
            <tr>
              <td>Sub Country/ Division</td>
              <td className="font-semibold">{data?.subCounty ?? "---"}</td>
            </tr>
            <tr>
              <td>Parish/Ward:</td>
              <td className="font-semibold">{data?.ward ?? "---"}</td>
            </tr>
            <tr>
              <td>LC 1/Village/Cell</td>
              <td className="font-semibold">{data?.village ?? "---"}</td>
            </tr>
            <tr>
              <td>Contact:</td>
              <td className="font-semibold">{data?.contact ?? "---"}</td>
            </tr>
            <tr>
              <td>Created At</td>
              <td className="font-semibold">
                {data?.created_at ? formatDate(data?.created_at) : "---"}
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
};

export default TDVaccinationTable;
