import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useAddNutritionRecordMutation,
  useDownloadCardMutation,
  useGetChildListWithCidMutation,
  useGetChildNutritionWithCidMutation,
  useGetChildVaccinesWithCidMutation,
  useGetSpecialCareMutation,
} from "../../apis/authApi";
import { DownloadIcon, SearchIcon } from "../../assets/Icons";
import ChildVaccinationTable from "../../components/childRegister/ChildVaccinationTable";
import BlueHeaderContainer from "../../components/common/BlueHeaderContainer/BlueHeaderContainer";
import Button from "../../components/common/Button";
import ListWithCheckbox from "../../components/common/ListWithCheckbox/ListWithCheckbox";
import MinistryLabel from "../../components/common/MinistryLabel/MinistryLabel";
import TableWithDropDowns from "../../components/common/TableWithDropDowns/TableWithDropDowns";
import ComponentLoader from "../../components/common/loader/ComponentLoader";
import NoDataFound from "../../components/common/loader/NoDataFound";
import TableWithLeftAndTopHeader from "../../components/common/tableWithLeftAndTopHeader/TableWithLeftAndTopHeader";
import Input from "../../components/form/input";
import "../../scss/childRegister/vaccination.scss";
import { addNotification } from "../../store/slices/toaster-slice";
import { BASE_URL } from "../../utils/helper";
import Popup from "../../components/common/Popup";
import UpdateContactDetails from "../../components/childRegister/UpdateContactDetails";

const tableHeader = [
  "Dose",
  "6M",
  "1yr",
  "1.5yr",
  "2yr",
  "2.5yr",
  "3yr",
  "3.5yr",
  "4yr",
  "4.5yr",
  "5yr",
];

const leftHeader = [
  {
    TableData: ["Vitamin A", "1", "1", "1", "1", "1", "1", "1", "1", "1", "1"],
  },
  {
    TableData: ["De-Worming", "1", "1", "1", "1", "1", "1", "1", "1", "1", "1"],
  },
];

const ChildVaccinationCard = () => {
  const { childVaccineDetails } = useSelector((state) => state.childVaccine);
  const { childNutritionDetails } = useSelector(
    (state) => state.childNutrition
  );
  const [getSpecialCare, specialCareLoading] = useGetSpecialCareMutation();
  const [getChildWithId, childWithIdLoading] = useGetChildListWithCidMutation();
  const [getChildVaccinesWithCid, childVaccinesWithCidLoading] =
    useGetChildVaccinesWithCidMutation();

  const [getChildNutritionTable, ChildNutritionTableLoading] =
    useGetChildNutritionWithCidMutation();

  const [downloadCardLink, downloadCardLoading] = useDownloadCardMutation();

  const [specialCareList, setSpecialCareList] = useState({});
  const [selectedIdx, setSelectedIdx] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchByChildNumber, SetSearchByChildNumber] = useState("");
  const [searchedChild, SetSearchedChild] = useState({});
  const [vaccinesData, setVaccinesData] = useState({});

  const [nutritionTableData, setNutritionTableData] = useState({});
  const [addNutritionRecord, addNutritionRecordLoading] =
    useAddNutritionRecordMutation();

  const [updateContactPopup, setUpdateContactPopup] = useState(false);

  const { childNo } = useParams();

  useEffect(() => {
    handleGetSpecialCare();

    if (childNo) {
      SetSearchByChildNumber(childNo);
      callAllApisUsingChildNumber(childNo);
    }
  }, [childNo]);

  useEffect(() => {
    if (searchedChild) {
      const selectedIdx = searchedChild?.specialCare?.map((item) => item);
      setSelectedIdx(selectedIdx);
    }
  }, [searchedChild]);

  const handleGetSpecialCare = async () => {
    try {
      const response = await getSpecialCare().unwrap();
      setSpecialCareList(response?.result?.specialCares);
    } catch (error) {
      dispatch(addNotification({ type: "error", message: error?.data?.error }));
    }
  };

  const handleGetChildWithCid = async (childNo) => {
    if (childNo) {
      try {
        const response = await getChildWithId(childNo).unwrap();
        SetSearchedChild(response?.result?.childrenData?.[0] ?? "");
      } catch (error) {
        dispatch(
          addNotification({ type: "error", message: error?.data?.error })
        );
      }
    }
  };

  const handleGetVaccineDataUsingCid = async (childNo) => {
    try {
      let param = {
        childNo: childNo,
      };
      const response = await getChildVaccinesWithCid(param).unwrap();
      setVaccinesData(response?.result?.vaccines);
    } catch (error) {
      dispatch(addNotification({ type: "error", message: error?.data?.error }));
    }
  };

  const handleGetNutritionTableData = async (childNo) => {
    try {
      let param = {
        childNo: childNo,
      };
      const response = await getChildNutritionTable(param).unwrap();
      setNutritionTableData(response?.result?.nutritions);
    } catch (error) {
      dispatch(addNotification({ type: "error", message: error?.data?.error }));
    }
  };

  const handleUpdateNutritionVaccine = async (vaccineData) => {
    try {
      const response = await addNutritionRecord(vaccineData).unwrap();

      dispatch(
        addNotification({
          type: "success",
          message: "Vaccine recorded successfully",
        })
      );
      handleGetNutritionTableData(searchByChildNumber);
    } catch (error) {
      dispatch(
        addNotification({
          type: "error",
          message:
            error?.data?.child || error?.data?.vaccine || error?.data?.weight,
        })
      );
    }
  };

  const callAllApisUsingChildNumber = (id) => {
    handleGetChildWithCid(id);
    handleGetVaccineDataUsingCid(id);
    handleGetNutritionTableData(id);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "child number") {
      SetSearchByChildNumber(value);
    }
  };

  // Handle Update Contact Details Popup
  const handleUpdateContactPopup = () => {
    setUpdateContactPopup(!updateContactPopup);
  };

  return (
    <div className="xl:px-[35px] xl:py-12 lg:px-5 lg:py-7 p-4 child-vaccination">
      <div className="flex gap-3 justify-between md:items-end max-md:flex-col">
        <div>
          <MinistryLabel title="Child Vaccination Card" />
        </div>
      </div>
      <div className="my-6 flex md:items-center md:justify-between max-md:flex-col gap-4">
        <div className="flex items-center">
          <Input
            placeholder={"Search by child no."}
            type="text"
            className="normal-input xl:w-[475px] lg:w-[250px]"
            changeEvent={handleInputChange}
            name="child number"
            value={searchByChildNumber}
          />
          <div className="ml-[20px]">
            <Button
              type="submit"
              btnType="normal"
              isLoading={childWithIdLoading.isLoading}
              clickHandler={() =>
                callAllApisUsingChildNumber(searchByChildNumber)
              }
            >
              <SearchIcon />
              Search
            </Button>
          </div>
        </div>
        {Object.keys(searchedChild)?.length > 0 && (
          <div className="flex gap-4 items-center">
            <Link
              to={`${BASE_URL}/child/download/vaccinationcard/${searchByChildNumber}`}
              target="_blank"
              className="btn active:text-white"
            >
              <DownloadIcon />
              Download
            </Link>
          </div>
        )}
      </div>
      {specialCareLoading?.isLoading || childWithIdLoading?.isLoading ? (
        <ComponentLoader />
      ) : Object.keys(searchedChild)?.length > 0 ? (
        <>
          <div className="bg-white w-full rounded-xl">
            <div className="p-2 mb-4 flex justify-end">
              <Button
                type="submit"
                btnType="normal"
                // isLoading={childWithIdLoading.isLoading}
                clickHandler={() => handleUpdateContactPopup()}
                classname="w-min"
              >
                Update Contact
              </Button>
            </div>
            {searchedChild && <ChildVaccinationTable data={searchedChild} />}
          </div>

          <div className="w-full my-6 flex flex-col lg:flex-row gap-6">
            <div className="w-full">
              <div>
                <BlueHeaderContainer
                  headertext={"Tick Reasons For Special Care"}
                  className="bg-white rounded-xl"
                  headerClass="h-[44px] rounded-t-[12px] bg-primary text-white text-[16px] font-semibold leading-normal"
                >
                  <ListWithCheckbox
                    list={specialCareList}
                    changeEvent={() => {}}
                    isDisabled={true}
                    fontClass={
                      "font-normal text-[12px] text-font tracking-[0.6px]"
                    }
                    ListWrapperClass={"p-4"}
                    selectedIdx={selectedIdx}
                    tag="vaccination-card"
                  />
                </BlueHeaderContainer>
              </div>
              <div className="mt-6">
                <BlueHeaderContainer
                  headertext={"Age in completed months/years"}
                  className="bg-white rounded-xl"
                  headerClass="h-[44px] rounded-t-[12px] bg-primary text-white text-[14px] font-semibold leading-normal"
                >
                  <TableWithLeftAndTopHeader
                    tableHeader={tableHeader}
                    tableLeftHeaderWithData={nutritionTableData}
                    childId={searchedChild?.id}
                    updateData={handleUpdateNutritionVaccine}
                    isLoading={ChildNutritionTableLoading?.isLoading}
                  />
                </BlueHeaderContainer>
              </div>
            </div>
          </div>
          <div>
            {vaccinesData?.length > 0 ? (
              <TableWithDropDowns
                tableData={vaccinesData}
                childId={searchedChild?.id}
                childNo={searchedChild?.childNo}
                refreshTable={handleGetVaccineDataUsingCid}
                loading={childVaccinesWithCidLoading?.isLoading}
              />
            ) : null}
          </div>
        </>
      ) : (
        <div className="w-full h-[200px] flex justify-center items-center bg-white rounded-xl">
          <NoDataFound />
        </div>
      )}

      {updateContactPopup && (
        <Popup
          title="Update Contact Details"
          width="400px"
          closeBtn={false}
          closeHandler={handleUpdateContactPopup}
        >
          <UpdateContactDetails closeHandler={handleUpdateContactPopup} data={searchedChild} updateRecord={handleGetChildWithCid}/>
        </Popup>
      )}
    </div>
  );
};

export default ChildVaccinationCard;
