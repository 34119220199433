import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  childVaccineDetails: null,
};

export const childVaccineDetails = createSlice({
  name: "childVaccineDetailMarkUp",
  initialState,
  reducers: {
    setChildVaccineDetails(state, action) {
      state.childVaccineDetails = action.payload;
    },
  },
});

export const { setChildVaccineDetails } = childVaccineDetails.actions;
export default childVaccineDetails.reducer;
