import React from "react";
import { formatDate, formatDateAsscen } from "../../utils/helper";
import useMediaQuery from "../common/mediaQuery/MediaQueryHook";

const ChildVaccinationTable = ({ data }) => {
  const isLargeScreen = useMediaQuery("(min-width: 768px)");

  return (
    <div className="vaccination-table">
      <table className="">
        <thead>
          {isLargeScreen ? (
            <tr>
              <td>Details</td>
              <td>Information</td>
              <td>Details</td>
              <td>Information</td>
            </tr>
          ) : (
            <tr>
              <td>Details</td>
              <td>Information</td>
            </tr>
          )}
        </thead>
        {isLargeScreen ? (
          <tbody>
            <tr>
              <td>Child No.</td>
              <td className="font-semibold">{data?.childNo ?? "---"}</td>
              <td>Child’s Name</td>
              <td className="font-semibold">{data?.name ?? "---"}</td>
            </tr>
            <tr>
              <td>Child Category</td>
              <td className="font-semibold">{data?.category ?? "---"}</td>
              <td>NIN</td>
              <td className="font-semibold">{data?.nin ?? "---"}</td>
            </tr>
            <tr>
              <td>Sex</td>
              <td className="font-semibold">{data?.gender ?? "---"}</td>
              <td>Place of Birth</td>
              <td className="font-semibold">{data?.place ?? "---"}</td>
            </tr>
            <tr>
              <td>Birth Weight</td>
              <td className="font-semibold">{data?.birthWeight ?? "---"}</td>
              <td>Next Kin</td>
              <td className="font-semibold">{data?.parent?.nextKin ?? "---"}</td>
            </tr>
            <tr>
              <td>Birth Order</td>
              <td className="font-semibold">{data?.birthOrder ?? "---"}</td>
              <td>Next Kin Address</td>
              <td className="font-semibold">
                {data?.parent?.nextKinAddress ?? "---"}
              </td>
            </tr>
            <tr>
              <td>Date of Birth</td>
              <td className="font-semibold">{formatDateAsscen(data?.dateOfBirth) ?? "---"}</td>
              <td>Next Kin Occupation</td>
              <td className="font-semibold">
                {data?.parent?.nextKinOccupation ?? "---"}
              </td>
            </tr>
            <tr>
              <td>Mother’s Name</td>
              <td className="font-semibold">{data?.parent?.motherName ?? "---"}</td>
              <td>District/City</td>
              <td className="font-semibold">{data?.address?.district ?? "---"}</td>
            </tr>
            <tr>
              <td>Mother’s Contact No.</td>
              <td className="font-semibold">
                {data?.parent?.motherContact ?? "---"}
              </td>
              <td>Sub Country/ Division</td>
              <td className="font-semibold">{data?.address?.subCounty ?? "---"}</td>
            </tr>
            <tr>
              <td>Father’s Name</td>
              <td className="font-semibold">{data?.parent?.fatherName ?? "---"}</td>
              <td>Parish/Ward:</td>
              <td className="font-semibold">{data?.address?.ward ?? "---"}</td>
            </tr>
            <tr>
              <td>Father’s Contact No.</td>
              <td className="font-semibold">
                {data?.parent?.fatherContact ?? "---"}
              </td>
              <td>LC 1/Village/Cell</td>
              <td className="font-semibold">{data?.address?.village ?? "---"}</td>
            </tr>
            <tr>
              <td>Father’s Occupation</td>
              <td className="font-semibold">
                {data?.parent?.fatherOccupation ? data?.parent?.fatherOccupation : "---"}
              </td>
              <td>Mother’s Occupation</td>
              <td className="font-semibold">
                {data?.parent?.fatherOccupation ? data?.parent?.fatherOccupation : "---"}
              </td>
            </tr>
            <tr>
              <td>Next Kin Contact No</td>
              <td className="font-semibold">
                {data?.parent?.nextKinContact ?? "---"}
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td>Child No.</td>
              <td className="font-semibold">{data?.childNo ?? "---"}</td>
            </tr>
            <tr>
              <td>Child’s Name</td>
              <td className="font-semibold">{data?.name ?? "---"}</td>
            </tr>
            <tr>
              <td>Child Category</td>
              <td className="font-semibold">{data?.category ?? "---"}</td>
            </tr>
            <tr>
              <td>NIN</td>
              <td className="font-semibold">{data?.nin ?? "---"}</td>
            </tr>
            <tr>
              <td>Next Kin</td>
              <td className="font-semibold">{data?.parent?.nextKin ?? "---"}</td>
            </tr>
            <tr>
              <td>Sex</td>
              <td className="font-semibold">{data?.gender ?? "---"}</td>
            </tr>
            <tr>
              <td>Next Kin Address</td>
              <td className="font-semibold">
                {data?.parent?.nextKinAddress ?? "---"}
              </td>
            </tr>
            <tr>
              <td>Birth Weight</td>
              <td className="font-semibold">{data?.birthWeight ?? "---"}</td>
            </tr>
            <tr>
              <td>Next Kin Occupation</td>
              <td className="font-semibold">
                {data?.parent?.nextKinOccupation ?? "---"}
              </td>
            </tr>
            <tr>
              <td>Birth Order</td>
              <td className="font-semibold">{data?.birthOrder ?? "---"}</td>
            </tr>
            <tr>
              <td>Next Kin Contact No</td>
              <td className="font-semibold">
                {data?.parent?.nextKinContact ?? "---"}
              </td>
            </tr>
            <tr>
              <td>Date of Birth</td>
              <td className="font-semibold">{formatDateAsscen(data?.dateOfBirth) ?? "---"}</td>
            </tr>
            <tr>
              <td>Place of Birth</td>
              <td className="font-semibold">{data?.place ?? "---"}</td>
            </tr>
            <tr>
              <td>Mother’s Name</td>
              <td className="font-semibold">{data?.parent?.motherName ?? "---"}</td>
            </tr>
            <tr>
              <td>District/City</td>
              <td className="font-semibold">{data?.address?.district ?? "---"}</td>
            </tr>
            <tr>
              <td>Mother’s Contact No.</td>
              <td className="font-semibold">
                {data?.parent?.motherContact ?? "---"}
              </td>
            </tr>
            <tr>
              <td>Sub Country/ Division</td>
              <td className="font-semibold">{data?.address?.subCounty ?? "---"}</td>
            </tr>
            <tr>
              <td>Father’s Name</td>
              <td className="font-semibold">{data?.parent?.fatherName ?? "---"}</td>
            </tr>
            <tr>
              <td>Parish/Ward:</td>
              <td className="font-semibold">{data?.address?.ward ?? "---"}</td>
            </tr>
            <tr>
              <td>Father’s Contact No.</td>
              <td className="font-semibold">
                {data?.parent?.fatherContact ?? "---"}
              </td>
            </tr>
            <tr>
              <td>LC 1/Village/Cell</td>
              <td className="font-semibold">{data?.address?.village ?? "---"}</td>
            </tr>
            <tr>
              <td>Father’s Occupation</td>
              <td className="font-semibold">
                {data?.parent?.fatherOccupation ?? "---"}
              </td>
            </tr>
            <tr>
              <td>Mother’s Occupation</td>
              <td className="font-semibold">
                {data?.parent?.fatherOccupation ? data?.parent?.fatherOccupation : "---"}
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
};

export default ChildVaccinationTable;
