import React from "react";
import TableLoader from "../common/loader/TableLoader";
import TableNoDataFound from "../common/loader/TableNoDataFound";
import ChildRegisterTableBody from "./ChildRegisterTableBody";

const ChildRegisterTable = ({ childList, loading }) => {
  return (
    <div>
      <table className=" border-collapse" id='register-table'>
        <thead>
          <tr>
            <td>1</td>
            <td>2</td>
            <td>3</td>
            <td>4</td>
            <td>5</td>
            <td>6</td>
            <td colSpan={2}>7</td>
            <td colSpan={2}>8</td>
            <td colSpan={2}>9</td>
            <td colSpan={2}>10</td>
            <td>11</td>
            <td colSpan={4}>12</td>
            <td colSpan={5}>13</td>
            <td colSpan={2}>14</td>
            <td colSpan={5}>15</td>
            <td colSpan={2}>16</td>
            <td colSpan={3}>17</td>
            <td colSpan={2}>18</td>
            <td colSpan={2}>19</td>
            <td colSpan={2}>20</td>
            <td colSpan={2}>21</td>
            <td colSpan={2}>22</td>
            <td>23</td>
            <td>24</td>
            <td>25</td>
            <td>26</td>
            <td>27</td>
            <td colSpan={2}>28</td>
            <td colSpan={4}>29</td>
            <td colSpan={2}>30</td>
            <td>31</td>
          </tr>
          <tr>
            <td rowSpan={2}>Child No.</td>
            <td>Child’s Name</td>
            <td rowSpan={6}>Address</td>
            <td rowSpan={6}>
              Child <br />
              Category
              <br /> N/R/F
            </td>
            <td rowSpan={3}>date of birth</td>
            <td rowSpan={3}>Sex</td>
            <td rowSpan={1} colSpan={2}>
              At birth
            </td>
            <td rowSpan={2}>
              Weight <br />
              (in Kgs)
            </td>
            <td rowSpan={6} className="vertical-text">
              Feeding Practices (Use Codes)
            </td>
            <td colSpan={2}>At 6 weeks</td>
            <td rowSpan={2}>
              Weight <br />
              (in Kgs)
            </td>
            <td rowSpan={6} className="vertical-text">
              Feeding Practices (Use Codes)
            </td>
            <td rowSpan={6} className="vertical-text">
              Received LLIN & Data
            </td>
            <td rowSpan={6} className="vertical-text">
              Infant Screened for HIV
            </td>
            <td rowSpan={6} className="vertical-text">
              Infant Exposed (Y/N)
            </td>
            <td rowSpan={6} className="vertical-text">
              Infant Exposed (Y/N/NA)
            </td>
            <td rowSpan={6} className="vertical-text">
              Infant Tested (Y/N/NA)
            </td>
            <td rowSpan={2} colSpan={5}>
              At 10 weeks
            </td>
            <td rowSpan={2}>
              Weight <br />
              (in Kgs)
            </td>
            <td rowSpan={6} className="vertical-text">
              Feeding Practices (Use Codes)
            </td>
            <td rowSpan={2} colSpan={5}>
              At 14 weeks
            </td>
            <td rowSpan={2}>
              Weight <br />
              (in Kgs)
            </td>
            <td rowSpan={6} className="vertical-text">
              Feeding Practices (Use Codes)
            </td>
            <td rowSpan={2} colSpan={3}>
              Data Malaria Vaccine
            </td>
            <td rowSpan={2}>
              MUAC
              <br /> (CM +R/Y/G)
            </td>
            <td rowSpan={6} className="vertical-text">
              Feeding Practices (Use Codes)
            </td>
            <td colSpan={2}>At 9 Months</td>
            <td rowSpan={2}>
              MUAC
              <br /> (CM +R/Y/G)
            </td>
            <td rowSpan={6} className="vertical-text">
              Feeding Practices (Use Codes)
            </td>
            <td rowSpan={2} colSpan={2}>
              At 18 Months
            </td>
            <td rowSpan={2}>
              MUAC
              <br /> (CM +R/Y/G)
            </td>
            <td rowSpan={6} className="vertical-text">
              Feeding Practices (Use Codes)
            </td>
            <td rowSpan={6} className="vertical-text">
              Fully Immunized by 1 Year
            </td>
            <td rowSpan={6} className="vertical-text">
              Infant screened for HIV
            </td>
            <td rowSpan={6} className="vertical-text">
              Infant Exposed (Y/N/NA)
            </td>
            <td rowSpan={6} className="vertical-text">
              Infant Tested (Y/N/NA)
            </td>
            <td rowSpan={6} className="vertical-text">
              Fully Immunized by 2 years
            </td>
            <td rowSpan={3} colSpan={2}>
              Disability
              <br /> (Write Code)
            </td>
            <td rowSpan={2} colSpan={4}>
              Vitamin A<br /> Administration
            </td>
            <td rowSpan={2} colSpan={2}>
              De-Worming
            </td>
            <td rowSpan={6} className="w-[300px] font-bold">
              Remarks
            </td>
          </tr>
          <tr>
            <td rowSpan={1}>Mother’s Name</td>
            <td rowSpan={1} colSpan={2}>
              Date HepB zero
            </td>
            <td colSpan={2}>Date Polio 1</td>
            <td rowSpan={4} className="vertical-text">
              Yellow fever
              <br /> vaccine
            </td>
            <td rowSpan={4} className="vertical-text">
              Date Measles
              <br /> Rubella 1 (MR1)
              <br /> Vaccination
            </td>
          </tr>
          <tr>
            <td rowSpan={4}>NIN</td>
            <td rowSpan={1}>Father’s Name</td>
            <td rowSpan={1} colSpan={2}>
              Date Polio zero
            </td>
            <td rowSpan={2}>
              Weight for age
              <br /> Z score (SU/U/N/
              <br /> PW/O)
            </td>
            <td colSpan={2}>Date Rota 1</td>
            <td rowSpan={2}>
              Weight for age
              <br /> Z score (SU/U/N/
              <br /> PW/O)
            </td>
            <td rowSpan={3} className="vertical-text">
              Date Polio 2
            </td>
            <td rowSpan={3} className="vertical-text">
              Date PCV 2
            </td>
            <td rowSpan={3} className="vertical-text">
              Date IPV 2
            </td>
            <td rowSpan={3} className="vertical-text">
              Date DPT-HepB-Hib 2
            </td>
            <td rowSpan={3} className="vertical-text">
              Date Rota 2
            </td>
            <td rowSpan={2}>
              Weight for age
              <br /> Z score (SU/U/N/
              <br /> PW/O)
            </td>
            <td rowSpan={3} className="vertical-text">
              Date Polio 3
            </td>
            <td rowSpan={3} className="vertical-text">
              Date PCV 3
            </td>
            <td rowSpan={3} className="vertical-text">
              Date IPV 3
            </td>
            <td rowSpan={3} className="vertical-text">
              Date DPT-HepB-Hib 3
            </td>
            <td rowSpan={3} className="vertical-text">
              Date Rota 3
            </td>
            <td rowSpan={2}>
              Weight for age
              <br /> Z score (SU/U/N/
              <br /> PW/O)
            </td>
            <td rowSpan={3} className="vertical-text">
              At 6 months
              <br /> (Malaria 1)
            </td>
            <td rowSpan={3} className="vertical-text">
              At 7 months
              <br /> (Malaria 2)
            </td>
            <td rowSpan={3} className="vertical-text">
              At 8 months
              <br /> (Malaria 3)
            </td>
            <td>Weight (in Kgs)</td>
            <td>Weight (in Kgs)</td>
            <td rowSpan={3} className="vertical-text">
              Date Malaria
              <br /> vaccine 4
            </td>
            <td rowSpan={3} className="vertical-text">
              Date Measles
              <br /> Rubella 2 (MR1)
              <br /> Vaccination
            </td>
            <td>Weight (in Kgs)</td>
            <td rowSpan={4} className="vertical-text">
              Current age (in Months)
            </td>
            <td rowSpan={4} className="vertical-text">
              Doses
            </td>
            <td rowSpan={4}>
              Date
              <br /> Administered
            </td>
            <td rowSpan={4} className="vertical-text">
              Nutrition
              <br /> Status
            </td>
            <td rowSpan={4} className="vertical-text">
              Doses
            </td>
            <td rowSpan={4} className="vertical-text">
              Date
              <br /> Administered
            </td>
          </tr>
          <tr>
            <td rowSpan={3}>Contact No.</td>
            <td rowSpan={3}>
              Age
              <br /> (in months)
            </td>
            <td rowSpan={3}>PAB</td>
            <td rowSpan={1} colSpan={2}>
              Date BCG
            </td>
            <td colSpan={2}>Date DPT-HepB-Hib 1</td>
            <td rowSpan={2}>
              Weight for age
              <br /> Z score (SU/U/N/
              <br /> PW/O)
            </td>
            <td rowSpan={2}>
              Weight for age
              <br /> Z score (SU/U/N/
              <br /> PW/O)
            </td>
            <td rowSpan={2}>
              Weight for age
              <br /> Z score (SU/U/N/
              <br /> PW/O)
            </td>
            <td rowSpan={3} colSpan={2}>
              <b>
                Maternal eMTCT
                <br /> Status
              </b>
            </td>
          </tr>
          <tr>
            <td rowSpan={2} colSpan={2}>
              Indicate (T) if dose <br />
              was given timely <br />
              or(L) if given late
            </td>
            <td rowSpan={2}>INR No.</td>
            <td colSpan={2}>Date PCV 1</td>
            <td rowSpan={2}>INR No.</td>
            <td rowSpan={2}>INR No.</td>
            <td rowSpan={2}>INR No.</td>
          </tr>
          <tr>
            <td colSpan={2}>Date IPV 1</td>
            <td rowSpan={1} colSpan={5}>
              Indicate (T) if dose was
              <br /> given timely or (L)
              <br /> if given late
            </td>
            <td rowSpan={1} colSpan={5}>
              Indicate (T) if dose was
              <br /> given timely or (L)
              <br /> if given late
            </td>
            <td rowSpan={1} colSpan={3}>
              Indicate (T) if dose was
              <br /> given timely or (L)
              <br /> if given late
            </td>
            <td>INR No.</td>
            <td rowSpan={1} colSpan={2}>
              Indicate (T) if dose was
              <br /> given timely or (L)
              <br /> if given late
            </td>
            <td>INR No.</td>
            <td rowSpan={1} colSpan={2}>
              Indicate (T) if dose was
              <br /> given timely or (L)
              <br /> if given late
            </td>
            <td>INR No.</td>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <TableLoader />
          ) : childList?.length > 0 ? (
            childList.map((child, index) => {
              return <ChildRegisterTableBody child={child} key={index} />;
            })
          ) : (
            <TableNoDataFound />
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ChildRegisterTable;
