import React, { useEffect, useState } from "react";
import { EditIcon } from "../../assets/Icons";
import DefaulterTable from "../../components/childRegister/DefaulterTable";
import Button from "../../components/common/Button";
import Dropdown from "../../components/common/Dropdown";
import MinistryLabel from "../../components/common/MinistryLabel/MinistryLabel";
import "../../scss/childRegister/defaulter.scss";
import { useGetVaccinationDefaultersMutation } from "../../apis/authApi";
import { addNotification } from "../../store/slices/toaster-slice";
import { useDispatch } from "react-redux";
import CustomPagination from "../../components/common/pagination/CustomPagination";
import { useNavigate } from "react-router-dom";
import HPVDefaulterTable from "../../components/hpv/HPVDefaulterTable";

const DoseOption = [
  { value: "dose1", label: "Dose 1" },
  { value: "dose2", label: "Dose 2" },
];
const VillageOption = [
  { value: "village1", label: "Village 1" },
  { value: "village2", label: "Village 2" },
];
const ParishOption = [
  { value: "parish1", label: "Parish 1" },
  { value: "parish2", label: "Parish 2" },
];
const SubCountryOption = [
  { value: "sub1", label: "Sub 1" },
  { value: "sub2", label: "Sub 2" },
];
const countryOption = [
  { value: "country1", label: "Country 1" },
  { value: "country2", label: "Country 2" },
];
const DistrictOption = [
  { value: "district1", label: "District 1" },
  { value: "district2", label: "District 2" },
];
const CityOption = [
  { value: "city1", label: "City 1" },
  { value: "city2", label: "City 2" },
];

const HPVDefaulters = () => {
  const [dose, setDose] = useState(null);
  const [village, setVillage] = useState(null);
  const [parish, setParish] = useState(null);
  const [subCountry, setSubCountry] = useState(null);
  const [country, setCountry] = useState(null);
  const [district, setDistrict] = useState(null);
  const [city, setCity] = useState(null);

  const [getDefaulter, defaultersLoading] =
    useGetVaccinationDefaultersMutation();
  const [defaulterLists, setDefaulterLists] = useState({});
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    handleGetDefaulter();
  }, [page]);

  const handleGetDefaulter = async () => {
    try {
      const query = {
        page: page,
      };
      let response = await getDefaulter(query).unwrap();
      setDefaulterLists(response);
    } catch (error) {
      if (error?.status == 401 || error?.status == 403) {
        sessionStorage.removeItem("access_token");
        navigate("/login");
        dispatch(
          addNotification({
            type: "error",
            message: "Token is invalid or expired",
          })
        );
      } else {
        dispatch(
          addNotification({ type: "error", message: "Unable to fetch!" })
        );
      }
    }
  };
  return (
    <div className="child-defaulter xl:px-[35px] xl:py-12 lg:px-5 lg:py-7 p-4">
      <div className="flex gap-3 justify-between md:items-end max-md:flex-col">
        <div>
          <MinistryLabel title="Vaccine Defaulters" />
        </div>
        {/* <div>
          <Button type="button" btnType='small'>
            <EditIcon /> Edit
          </Button>
        </div> */}
      </div>

      <div className="mt-8 bg-white rounded-xl overflow-auto ">
        <HPVDefaulterTable
          lists={defaulterLists?.results}
          loading={defaultersLoading?.isLoading}
        />
      </div>
      {defaulterLists?.count > 10 && (
        <CustomPagination
          setPage={setPage}
          page={page}
          count={defaulterLists?.count}
        />
      )}
    </div>
  );
};

export default HPVDefaulters;
