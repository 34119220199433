import React from "react";
import UgandaMoH from "../../../assets/images/ministry-of-health-uganda.png";

const MinistryLabel = ({ title }) => {
  return (
    <div>
      {/* <div className="h-[45px] flex items-center">
        <div className="text-[11px] font-semibold leading-[12px]">
          Republic of Uganda
        </div>
        <div className="w-[43px] h-[43px] mx-1">
          <img src={UgandaMoH} alt="MOH-UGANDA" />
        </div>
        <div className="text-[11px] font-semibold leading-[12px]">
          Ministry of Health
        </div>
      </div> */}
      <div className="">
        <h5 className="xl:text-2xl lg:text-xl md:text-lg text-base font-medium leading-[22px] uppercase">
          {title}
        </h5>
      </div>
    </div>
  );
};

export default MinistryLabel;
