import React from "react";
import { Link } from "react-router-dom";

const AccountFooterLink = () => {
  return (
    <div className="flex gap-8 items-center justify-center py-3 lg:max-w-[450px]">
      <Link
        to=""
        className="text-sm font-medium tracking-[0.408px] text-font transition-all duration-300 hover:text-primary"
      >
        Help
      </Link>
      <Link
        to=""
        className="text-sm font-medium tracking-[0.408px] text-font transition-all duration-300 hover:text-primary"
      >
        Privacy Policy
      </Link>
      <Link
        to=""
        className="text-sm font-medium tracking-[0.408px] text-font transition-all duration-300 hover:text-primary"
      >
        Contact
      </Link>
    </div>
  );
};

export default AccountFooterLink;
