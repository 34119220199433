import React, { useState } from "react";
import { DownloadIcon, SearchIcon } from "../../assets/Icons";
import Button from "../../components/common/Button";
import MinistryLabel from "../../components/common/MinistryLabel/MinistryLabel";
import Popup from "../../components/common/Popup";
import Input from "../../components/form/input";
import HPVVaccinationTable from "../../components/hpv/HPVVaccinationTable";
import HPVVaccinePopup from "../../components/hpv/HPVVaccinePopup";
import "../../scss/tdRegister/tdRegister.scss";

const HPVVaccinationCard = () => {
  const [dosePopup, setDosePopup] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "child number") {
      //   SetSearchByChildNumber(value);
    }
  };

  const handleHPVDosePopup = (dose) => {
    setDosePopup(!dosePopup);
  };
  return (
    <div className="xl:px-[35px] xl:py-12 lg:px-5 lg:py-7 p-4 td-vaccination">
      <div className="flex gap-3 justify-between md:items-end max-md:flex-col">
        <div>
          <MinistryLabel title="Vaccination Card" />
        </div>
      </div>
      <div className="my-6 flex md:items-center md:justify-between max-md:flex-col gap-4">
        <div className="flex items-center">
          <Input
            placeholder={"Search by patient no."}
            type="text"
            className="normal-input xl:w-[475px] lg:w-[250px]"
            changeEvent={handleInputChange}
            name="child number"
            //   value={searchByChildNumber}
          />
          <div className="ml-[20px]">
            <Button
              type="submit"
              btnType="normal"
              // isLoading={addVaccineRecordLoading.isLoading}
              // clickHandler={() =>
              //   callAllApisUsingChildNumber(searchByChildNumber)
              // }
            >
              <SearchIcon />
              Search
            </Button>
          </div>
        </div>
        {/* {searchedChild && ( */}
        <div>
          <Button
            type="submit"
            btnType="normal"
            // isLoading={downloadCardLoading.isLoading}
            // clickHandler={() => handleDownloadCard(searchByChildNumber)}
          >
            <DownloadIcon />
            Download
          </Button>
        </div>
        {/* )} */}
      </div>
      <div className="bg-white w-full rounded-xl">
        <HPVVaccinationTable />
      </div>
      <div className="bg-white w-full rounded-xl mt-5 dose-table overflow-auto scrollbar scrollbar-w-1.5 scrollbar-h-1.5 scrollbar-thumb-primary scrollbar-track-slate-200">
        <table>
          <thead>
            <tr>
              <th>Dose</th>
              <th>Date of Dose</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>HPV1</td>
              <td>
                <span className="flex items-center justify-center">
                  <Button
                    clickHandler={() => handleHPVDosePopup("HPV1")}
                    classname="!w-auto"
                  >
                    Schedule Dose
                  </Button>
                </span>
              </td>
            </tr>
            <tr>
              <td>HPV2</td>
              <td>
                <span className="flex items-center justify-center">
                  <Button
                    clickHandler={() => handleHPVDosePopup("HPV2")}
                    classname="!w-auto"
                  >
                    Schedule Dose
                  </Button>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {dosePopup && (
        <Popup
          title="Update Vaccine Details"
          width="600px"
          closeBtn={true}
          closeHandler={handleHPVDosePopup}
        >
          <HPVVaccinePopup closePopup={handleHPVDosePopup} />
        </Popup>
      )}
    </div>
  );
};

export default HPVVaccinationCard;
