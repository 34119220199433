import React, { useState } from "react";

const PhoneInput = (props) => {
  const [visible, setVisible] = useState(false);
  const {
    type,
    className,
    label,
    placeholder,
    name,
    isFocus = false,
    isErrorMsg = null,
    changeEvent,
    attributes,
    isReadOnly = false,
    isDisabled = false,
  } = props;

  const checkEvent = (e) => {
    const { name, value } = e.target;
    if (changeEvent) {
      changeEvent(e);
    }
  };
  return (
    <div className={`input-wrapper flex flex-col ${isErrorMsg ? "error" : ""}`}>
      {label ? (
        <label
          className={
            isReadOnly ? "input-label-read-only" : "input-label pb-2.5"
          }
        >
          {label}
        </label>
      ) : null}
      <div
        className={` ${
          className ? className : "w-full border border-black"
        } input-tag relative !pl-12 flex items-center`}
      >
        <span className="absolute left-2 block border-r-2 pr-1 border-color_758092CC">
          +256
        </span>
        <input
          type={type}
          placeholder={placeholder}
          name={name}
          onChange={checkEvent}
          readOnly={isReadOnly}
          className="bg-transparent outline-none w-full pl-1 h-full"
          autoFocus={isFocus}
          disabled={isDisabled}
          {...attributes}
        />
      </div>
      {isErrorMsg !== null && (
        <p className="error-text">
          <span className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M8 12.173a0.591 0.591 0 1 0 0 -1.182 0.591 0.591 0 0 0 0 1.182Zm0 -2.123a0.444 0.444 0 0 1 -0.445 -0.445v-5.333a0.444 0.444 0 1 1 0.889 0v5.333a0.444 0.444 0 0 1 -0.444 0.444Z"
                fill="red"
              />
              <path
                d="M8 15.111a7.112 7.112 0 1 1 0 -14.222 7.112 7.112 0 0 1 0 14.222Zm0 -13.333a6.222 6.222 0 1 0 0 12.445 6.222 6.222 0 0 0 0 -12.445Z"
                fill="red"
              />
            </svg>
          </span>
          {isErrorMsg}
        </p>
      )}
    </div>
  );
};

export default PhoneInput;
