import React, { useState, useRef } from "react";

const PasswordLikeInput = ({
  className,
  name,
  label,
  changeEvent,
  isErrorMsg = null,
}) => {
  const [password, setPassword] = useState(["", "", "", "", "", "","", "", "", "", "", "", "", ""]);
  const inputRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];

  const handleChange = (e, index) => {
    const { name, value } = e.target;

    const updatedPassword = [...password];
    updatedPassword[index] = value;
    setPassword(updatedPassword);

    if (value !== "" && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }

    changeEvent(name, updatedPassword.join(""));
  };

  const handleBackspace = (e, index) => {
    if (e.key === "Backspace" && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  return (
    <div
      className={`${className} nin-input flex flex-col gap-2 ${
        isErrorMsg ? "error" : ""
      }`}
    >
      <label className="input-label h-[20px]">{label}</label>
      <div className="w-full">
        <div className="flex items-center lg:gap-2 gap-1 flex-wrap">
          {password.map((char, index) => (
            <input
              key={index}
              type="text"
              value={char}
              name={name}
              onChange={(e) => handleChange(e, index)}
              onKeyUp={(e) => handleBackspace(e, index)}
              className="lg:w-[31px] w-[25px] lg:h-[40px] h-[40px] text-center border border-primary rounded-md focus:outline-none focus:border-primary"
              maxLength="1"
              ref={inputRefs[index]}
            />
          ))}
        </div>
        {isErrorMsg !== null && (
          <p className="error-text">
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M8 12.173a0.591 0.591 0 1 0 0 -1.182 0.591 0.591 0 0 0 0 1.182Zm0 -2.123a0.444 0.444 0 0 1 -0.445 -0.445v-5.333a0.444 0.444 0 1 1 0.889 0v5.333a0.444 0.444 0 0 1 -0.444 0.444Z"
                  fill="red"
                />
                <path
                  d="M8 15.111a7.112 7.112 0 1 1 0 -14.222 7.112 7.112 0 0 1 0 14.222Zm0 -13.333a6.222 6.222 0 1 0 0 12.445 6.222 6.222 0 0 0 0 -12.445Z"
                  fill="red"
                />
              </svg>
            </span>
            {isErrorMsg}
          </p>
        )}
      </div>
    </div>
  );
};

export default PasswordLikeInput;
