import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../../pages/accounts/Login";
import Register from "../../pages/accounts/Register";
import Confirmation from "../../pages/supportPages/Confirmation";
import Layout from "./Layout";
import ForgotPassword from "../../pages/accounts/ForgotPassword";
import ResetPassword from "../../pages/accounts/ResetPassword";

const Routing = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route path="confirmation" element={<Confirmation />} />
      <Route path="/*" element={<Layout />} />
    </Routes>
  );
};

export default Routing;
