import React from "react";
import TableLoader from "../common/loader/TableLoader";
import TableNoDataFound from "../common/loader/TableNoDataFound";
import { formatDate, formatDateAsscen } from "../../utils/helper";
import { Link } from "react-router-dom";

const TDRegisterTable = ({ list, loading }) => {
  return (
    <div>
      <table className="border-collapse w-full" id="td-register-table">
        <thead>
          <tr>
            <td>1</td>
            <td>2</td>
            <td>3</td>
            <td>4</td>
            <td>5</td>
            <td>6</td>
            <td>7</td>
            <td>8</td>
            <td>9</td>
            <td>10</td>
            <td>11</td>
            <td>12</td>
          </tr>
          <tr>
            <td>Date</td>
            <td>Serial No/ Card No</td>
            <td>NIN</td>
            <td>Client Name</td>
            <td>
              Client <br />
              Category
              <br /> N/R/F
            </td>
            <td>Date of Birth</td>
            <td>
              Mother/Caregiver <br /> Name & Contact
            </td>
            <td>Address</td>
            <td className="vertical-text">Age</td>
            <td className="vertical-text">Class</td>
            <td>Dose & TD Date</td>
            <td>POC</td>
          </tr>
        </thead>
        <tbody>
          {list?.length > 0 ? (
            list.map((item) => {
              return (
                <>
                  <tr key={item?.id}>
                    <td rowSpan={5}>{formatDate(item?.createdAt)}</td>
                    <td rowSpan={5}>
                      <Link
                        to={`/vaccination-card/${item?.clientNo}`}
                        className="text-primary"
                      >
                        {item?.clientNo}
                      </Link>
                    </td>
                    <td rowSpan={5}>{item?.nin}</td>
                    <td rowSpan={5}>{item?.name}</td>
                    <td rowSpan={5}>{item?.category}</td>
                    <td rowSpan={5}>{formatDate(item?.dateOfBirth)}</td>
                    <td rowSpan={3}>{item?.motherName}</td>
                    <td rowSpan={5}>
                      {item?.village},<br /> {item?.ward},
                      <br />
                      {item?.subCounty},<br /> {item?.district}
                    </td>
                    <td rowSpan={5}>{item?.age}</td>
                    <td>
                      {item?.doses?.[0]?.clientClass &&
                      item?.doses?.[0]?.mode === "School"
                        ? item?.doses?.[0]?.clientClass
                        : item?.doses?.[0]?.mode === ""
                        ? ""
                        : "N/A"}
                    </td>
                    <td>
                      {item?.doses?.[0]?.vaccinatedDate
                        ? `Dose 1: ${formatDateAsscen(
                            item?.doses?.[0]?.vaccinatedDate
                          )}`
                        : ""}
                    </td>
                    <td>
                      {item?.doses?.[0]?.mode === "Static"
                        ? "F"
                        : item?.doses?.[0]?.mode === "Outreached"
                        ? "O/R"
                        : item?.doses?.[0]?.mode === "School"
                        ? "Sch"
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {item?.doses?.[1]?.clientClass &&
                      item?.doses?.[1]?.mode === "School"
                        ? item?.doses?.[1]?.clientClass
                        : item?.doses?.[1]?.mode === ""
                        ? ""
                        : "N/A"}
                    </td>
                    <td>
                      {item?.doses?.[1]?.vaccinatedDate
                        ? `Dose 2: ${formatDateAsscen(
                            item?.doses?.[1]?.vaccinatedDate
                          )}`
                        : ""}
                    </td>
                    <td>
                      {item?.doses?.[1]?.mode === "Static"
                        ? "F"
                        : item?.doses?.[1]?.mode === "Outreached"
                        ? "O/R"
                        : item?.doses?.[1]?.mode === "School"
                        ? "Sch"
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {item?.doses?.[2]?.clientClass &&
                      item?.doses?.[2]?.mode === "School"
                        ? item?.doses?.[2]?.clientClass
                        : item?.doses?.[2]?.mode === ""
                        ? ""
                        : "N/A"}
                    </td>
                    <td>
                      {item?.doses?.[2]?.vaccinatedDate
                        ? `Dose 3: ${formatDateAsscen(
                            item?.doses?.[2]?.vaccinatedDate
                          )}`
                        : ""}
                    </td>
                    <td>
                      {item?.doses?.[2]?.mode === "Static"
                        ? "F"
                        : item?.doses?.[2]?.mode === "Outreached"
                        ? "O/R"
                        : item?.doses?.[2]?.mode === "School"
                        ? "Sch"
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan={2}>{item?.contact}</td>
                    <td>
                      {item?.doses?.[3]?.clientClass &&
                      item?.doses?.[3]?.mode === "School"
                        ? item?.doses?.[3]?.clientClass
                        : item?.doses?.[3]?.mode === ""
                        ? ""
                        : "N/A"}
                    </td>
                    <td>
                      {item?.doses?.[3]?.vaccinatedDate
                        ? `Dose 4: ${formatDateAsscen(
                            item?.doses?.[3]?.vaccinatedDate
                          )}`
                        : ""}
                    </td>
                    <td>
                      {item?.doses?.[3]?.mode === "Static"
                        ? "F"
                        : item?.doses?.[3]?.mode === "Outreached"
                        ? "O/R"
                        : item?.doses?.[3]?.mode === "School"
                        ? "Sch"
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {item?.doses?.[4]?.clientClass &&
                      item?.doses?.[4]?.mode === "School"
                        ? item?.doses?.[4]?.clientClass
                        : item?.doses?.[4]?.mode === ""
                        ? ""
                        : "N/A"}
                    </td>
                    <td>
                      {item?.doses?.[4]?.vaccinatedDate
                        ? `Dose 5: ${formatDateAsscen(
                            item?.doses?.[4]?.vaccinatedDate
                          )}`
                        : ""}
                    </td>
                    <td>
                      {item?.doses?.[4]?.mode === "Static"
                        ? "F"
                        : item?.doses?.[4]?.mode === "Outreached"
                        ? "O/R"
                        : item?.doses?.[4]?.mode === "School"
                        ? "Sch"
                        : ""}
                    </td>
                  </tr>
                </>
              );
            })
          ) : (
            <TableNoDataFound />
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TDRegisterTable;
