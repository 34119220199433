import { Provider } from "react-redux";
import Routing from "./components/common/Routing";
import { store } from "./store";
import Toaster from "./components/common/Toaster";

function App() {
  return (
    <Provider store={store}>
      <Toaster />
      <Routing />
    </Provider>
  );
}

export default App;
