import React from "react";
import '../../../scss/common/index.scss';

const ComponentLoader = () => {
  return (
    <div className="relative w-full h-[200px] bg-[rgba(0,0,0,0.05)] flex justify-center items-center">
      <span className="loader"></span>
    </div>
  );
};

export default ComponentLoader;
