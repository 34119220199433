import React from "react";
import AccountFooterLink from "../../components/register/AccountFooterLink";
import { Logo } from "../../assets/Icons";
import { Link } from "react-router-dom";

const Confirmation = () => {
  return (
    <div className="confirmation-page">
      <div className="flex min-h-screen lg:flex-row flex-col">
        <div className="basis-1/2 bg-cover bg-center bg-no-repeat bg-account-banner flex items-center justify-center max-h-screen sticky top-0 left-0 max-lg:hidden">
          <div className="flex flex-col gap-6 account-logo">
            <Logo />
            <h2 className="xl:text-[35px] lg:text-[28px] text-black">
              Welcome to MedRef
            </h2>
          </div>
        </div>

        <div className="block lg:hidden pt-8">
          <div className="flex flex-col gap-6 items-center account-logo">
            <Logo />
            <h2 className="text-2xl text-black">Welcome to MedRef</h2>
          </div>
        </div>
        <div className="lg:basis-1/2 basis-full flex-1 max-lg:h-full max-lg:flex max-lg:flex-col">
          <div className="h-[calc(100%-50px)] flex items-center w-full flex-1 max-lg:justify-center flex-col">
            <div className="flex-1 lg:max-w-[450px] max-w-[300px] text-center loading-animation flex flex-col-reverse gap-5">
              <h3 className="xl:text-2xl lg:text-xl text-lg h-inter font-medium">
                Waiting for the approval from your authorized Hospital
              </h3>
              <div class="dots">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>
            </div>
            <div className="flex-1 mt-7">
              <h5 className="xl:text-lg lg:text-base text-sm h-inter font-medium">
                Go back to{" "}
                <Link to="/login" className="text-primary">
                  Login
                </Link>
              </h5>
            </div>
          </div>
          <AccountFooterLink />
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
