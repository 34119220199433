import React from "react";

const BlueHeaderContainer = ({
  className,
  headertext,
  headerClass,
  children,
}) => {
  return (
    <div className={`${className}`}>
      <div
        className={`flex justify-center items-center bg-primary text-white ${headerClass}`}
      >
        {headertext}
      </div>
      <div>{children}</div>
    </div>
  );
};

export default BlueHeaderContainer;
