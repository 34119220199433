import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useGetChildListMutation } from "../../apis/authApi";
import { DownloadIcon } from "../../assets/Icons";
import MinistryLabel from "../../components/common/MinistryLabel/MinistryLabel";
import CustomPagination from "../../components/common/pagination/CustomPagination";
import SearchInput from "../../components/common/searchInput/searchInput";
import HPVRegisterTable from "../../components/hpv/HPVRegisterTable";
import { addNotification } from "../../store/slices/toaster-slice";
import Button from "../../components/common/Button";

const HPVRegister = () => {
  const [searchValue, setSearchValue] = useState("");
  const [openFilters, setOpenFilters] = useState(false);
  const [getChild, getChildLoading] = useGetChildListMutation();
  const [TDData, setTDData] = useState({});
  const [isScrolled, setIsScrolled] = useState(false);
  const [prevScrollY, setPrevScrollY] = useState(0);
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    handleGetChildData();
  }, [page, searchValue]);

  const handleGetChildData = async () => {
    try {
      const query = {
        search: searchValue,
        page: page,
      };
      const response = await getChild(query).unwrap();
      setTDData(response);
      addNotification({ type: "success", message: "Successfully Register" });
    } catch (error) {
      if (error?.status == 401 || error?.status == 403) {
        sessionStorage.removeItem("access_token");
        navigate("/login");
        dispatch(
          addNotification({
            type: "error",
            message: "Token is invalid or expired",
          })
        );
      } else {
        dispatch(
          addNotification({ type: "error", message: "Unable to fetch!" })
        );
      }
    }
  };

  // Event handler for the scroll event
  const handleScroll = () => {
    const registerBookSection = document.getElementById("registerBook");
    let headerHeight = document.querySelector(".app-header");
    if (registerBookSection) {
      let { top } = registerBookSection.getBoundingClientRect();
      let isScrollingDown = window.scrollY > prevScrollY;
      setIsScrolled(!isScrollingDown && top <= headerHeight.clientHeight + 20);
      setPrevScrollY(window.scrollY);
    }
  };

  const handleTableScroll = () => {
    const registerBookSection = document.getElementById("registerBook");
    if (registerBookSection.scrollTop === 0) {
      setIsScrolled(false);
    }
  };

  // Attach the scroll event listener when the component mounts
  useEffect(() => {
    // const bodyContent = document.querySelector(".body-content");
    // const registerBookSection = document.getElementById("registerBook");
    // bodyContent.addEventListener("scroll", handleScroll);
    // registerBookSection.addEventListener("scroll", handleTableScroll);
    // // Clean up the event listener when the component unmounts`
    // return () => {
    //   bodyContent.removeEventListener("scroll", handleScroll);
    //   registerBookSection.removeEventListener("scroll", handleTableScroll);
    // };
  }, [prevScrollY]); // Empty dependency array ensures the effect runs only once

  return (
    <div className="child-register xl:px-[35px] xl:py-12 lg:px-5 lg:py-7 p-4">
      <div className="flex gap-3 justify-between md:items-end max-md:flex-col">
        <div>
          <MinistryLabel title="Human Papillomavirus (HPV) REGISTER" />
          <div className="mt-3">
            <SearchInput setSearchValue={setSearchValue} placeholder="Search by patient no." />
          </div>
        </div>
        <div className="">
          <Button type="submit" btnType="normal">
            <DownloadIcon /> Download
          </Button>
        </div>
      </div>
      <div
        id="registerBook"
        className={`lg:mt-8 mt-6 bg-white py-4 px-6 rounded-xl overflow-auto scrollbar scrollbar-w-1.5 scrollbar-h-1.5 scrollbar-thumb-primary scrollbar-track-slate-200 ${
          isScrolled ? "tableScrolling" : ""
        }`}
      >
        <HPVRegisterTable />
      </div>
      {TDData?.count > 10 && (
        <CustomPagination setPage={setPage} page={page} count={TDData?.count} />
      )}
    </div>
  );
};

export default HPVRegister;
