import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";
import { DateRangePicker } from 'rsuite';
import { Stack } from 'rsuite';

const predefinedRanges = [
  {
    label: "Today",
    value: [startOfDay(new Date()), endOfDay(new Date())],
    placement: "left",
  },
  {
    label: "Yesterday",
    value: [
      startOfDay(subDays(new Date(), 1)),
      endOfDay(subDays(new Date(), 1)),
    ],
    placement: "left",
  },
  {
    label: "This week",
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
    placement: "left",
  },
  {
    label: "Last week",
    value: (value) => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), -7),
      ];
    },
    placement: "left",
  },
  {
    label: "Last 7 days",
    value: [subDays(new Date(), 6), new Date()],
    placement: "left",
  },
  {
    label: "Last 30 days",
    value: [subDays(new Date(), 29), new Date()],
    placement: "left",
  },
  {
    label: "This month",
    value: [startOfMonth(new Date()), new Date()],
    placement: "left",
  },
  {
    label: "Last month",
    value: [
      startOfMonth(addMonths(new Date(), -1)),
      endOfMonth(addMonths(new Date(), -1)),
    ],
    placement: "left",
  },
  {
    label: "This year",
    value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
    placement: "left",
  },
];

const CustomDateRange = ({ setDateRange = "" }) => (
  <Stack direction="column" spacing={8} alignItems="flex-start">
    <DateRangePicker
      ranges={predefinedRanges}
      showOneCalendar
      placeholder="Select range..."
      style={{ width: 300 }}
      onChange={(value) => {
        setDateRange({ from: value?.[0], to: value?.[1] });
      }}
    />
  </Stack>
);

export default CustomDateRange;
