import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useAddTDDoseRecordMutation } from "../../apis/authApi";
import { addNotification } from "../../store/slices/toaster-slice";
import Button from "../common/Button";
import DropDown from "../common/Dropdown";
import Input from "../form/input";

const validateRegForm = (data) => {
  let errors = {};
  let isValid = true;

  if (!data?.vaccineMode) {
    errors.vaccineMode = "Vaccination mode is required";
    isValid = false;
  }
  if (data?.vaccineMode == "School" && !data?.clientClass) {
    errors.clientClass = "Class is required";
    isValid = false;
  }
  return { isValid, errors };
};

const vaccineStatusOptions = [
  {
    label: "Taken",
    value: "taken",
  },
  { label: "Not Taken", value: "not taken" },
];

const TDAppointmentPopup = ({ closePopup, data, refreshTable }) => {
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState({});
  const [selectedDate, setSelectedDate] = useState("");
  const [vaccineStatus, setVaccineStatus] = useState([
    {
      status: vaccineStatusOptions[0].value, // Set the initial status value
    },
  ]);
  const [addVaccineRecord, vaccineRecordLoading] = useAddTDDoseRecordMutation();

  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevObj) => ({
      ...prevObj,
      [name]: value,
    }));
  };

  const handleUpdateVaccine = async () => {
    try {
      let takenIds = vaccineStatus
        .filter((vaccine) => vaccine.label === "Taken")
        .map((vaccine) => {
          return vaccine.doseId;
        });
      let vaccineData = {
        clientId: data?.id,
        mode: formData?.vaccineMode,
        doses: takenIds,
        // vaccinatedDate: selectedDate ?? new Date(),
      };

      if (formData?.vaccineMode == "School") {
        vaccineData = { ...vaccineData, clientClass: formData?.clientClass };
      }
      const response = await addVaccineRecord(vaccineData).unwrap();

      dispatch(
        addNotification({
          type: "success",
          message: "Vaccine recorded successfully",
        })
      );
      refreshTable();
      closePopup();
    } catch (error) {
      dispatch(
        addNotification({
          type: "error",
          message: error?.data?.result,
        })
      );
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const { isValid, errors } = validateRegForm(formData);
    setFormError(errors);

    if (isValid) {
      handleUpdateVaccine();
    }
  };

  useEffect(() => {
    setVaccineStatus(
      data.doses.map((doseObj) => ({
        ...doseObj,
        label: "Not Taken",
        value: "not-taken",
      }))
    );
    setSelectedDate(data?.scheduledDate);
  }, [data]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="pb-[80px]">
          <div className="flex lg:gap-6 gap-4 max-md:flex-col">
            <div className="basis-1/2">
              <Input
                label="Client No."
                placeholder={"Enter Client No."}
                type="text"
                isReadOnly={true}
                className="border !border-primary lg:h-[40px] h-[40px] rounded-[6px] px-4 py-2 w-full"
                name="clientNo"
                attributes={{ value: data?.clientNo }}
              />
            </div>
            <div className="basis-1/2">
              <Input
                label="Client Name"
                placeholder={"Client Name"}
                type="text"
                isReadOnly={true}
                className="normal-input"
                name="name"
                attributes={{ value: data?.name }}
              />
            </div>
          </div>
          <div className="flex lg:gap-6 gap-4 max-md:flex-col mt-5">
            <div className="basis-3/5">
              <Input
                label="Vaccination Mode"
                placeholder={"Vaccination Mode"}
                type="radio"
                options={[
                  { name: "Static", id: "static" },
                  { name: "Outreached", id: "outreached" },
                  { name: "School", id: "school" },
                ]}
                isErrorMsg={formError?.vaccineMode}
                className="normal-input"
                changeEvent={handleInputChange}
                name="vaccineMode"
              />
            </div>
            {formData?.vaccineMode === "School" && (
              <div className="w-2/5">
                <Input
                  label="Class in School"
                  type="text"
                  placeholder={"Enter class in school"}
                  className="border !border-primary lg:h-[40px] h-[40px] rounded-[6px] px-4 py-2 w-full"
                  name="clientClass"
                  changeEvent={handleInputChange}
                  isErrorMsg={formError?.clientClass}
                  attributes={{ value: formData?.clientClass }}
                />
              </div>
            )}
          </div>

          <div className="flex lg:gap-6 gap-4 flex-col mt-5">
            {data.doses?.length > 0 &&
              data.doses.map((el) => {
                return (
                  <div className="flex items-center gap-4" key={el.vaccineId}>
                    <span className="sm:w-1/3 w-1/2 py-1 p-2 h-[40px] bg-[#FAFAFA] flex items-center">
                      {el.doseName}
                    </span>
                    <div className="sm:w-2/3 w-1/2">
                      <DropDown
                        options={vaccineStatusOptions}
                        isClearable={false}
                        inputWidth={"100%"}
                        isMultiSelected={false}
                        onSelectChange={(status) => {
                          setVaccineStatus((prevStatus) => {
                            const updatedStatus = prevStatus.map((vaccine) =>
                              vaccine.vaccineId === el.vaccineId
                                ? { ...vaccine, ...status }
                                : vaccine
                            );
                            return updatedStatus;
                          });
                        }}
                        selectedValue={
                          vaccineStatus.find(
                            (vaccine) => vaccine.vaccineId === el.vaccineId
                          ) || "Not Taken"
                        }
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="flex gap-3 items-center fixed left-0 bottom-0 w-full xl:p-6 sm:p-5 p-2.5 bg-white shadow-inner">
          <div className="w-1/3">
            <Link
              to={`/vaccination-card/${data?.clientNo}`}
              className="btn whitespace-nowrap hover:bg-white leading-none text-white"
            >
              View More
            </Link>
          </div>
          <div className="w-1/3">
            <Button
              type="submit"
              btnType="normal"
              isLoading={vaccineRecordLoading?.isLoading}
            >
              Save
            </Button>
          </div>
          <div className="w-1/3">
            <Button type="submit" btnType="normal" clickHandler={closePopup}>
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TDAppointmentPopup;
