import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  useForgetPasswordMutation,
  useVerifyOTPMutation,
} from "../../apis/authApi";
import { Logo } from "../../assets/Icons";
import Button from "../../components/common/Button";
import Input from "../../components/form/input";
import AccountFooterLink from "../../components/register/AccountFooterLink";
import { addNotification } from "../../store/slices/toaster-slice";
import { regEx } from "../../utils/helper";

const validateLoginForm = (data) => {
  let errors = {};
  let isValid = true;

  if (!data?.contact) {
    errors.contact = "Contact is required";
    isValid = false;
  } else if (!regEx.PHONE.test(data?.contact)) {
    errors.contact = "Please enter valid contact number";
    isValid = false;
  }
  return { isValid, errors };
};

const initialState = {
  contact: "",
};

const ForgotPassword = () => {
  const [formData, setFormData] = useState(initialState);
  const [formError, setFormError] = useState({});
  const [allowOTP, setAllowOTP] = useState(false);
  const [forgetPassword, forgetPasswordLoading] = useForgetPasswordMutation();
  const [verifyOTP, verifyOTPLoading] = useVerifyOTPMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Function to handle change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((state) => ({ ...state, [name]: value }));
    setFormError((state) => ({ ...state, [name]: "" }));
  };

  // Handle User Login
  const handleForgetPassword = async () => {
    try {
      const userData = {
        contact:  `+256${formData.contact}`,
      };
      const response = await forgetPassword(userData).unwrap();
      if (response?.message == "OK") {
        setAllowOTP(true);
        setFormData((state) => ({
          ...state,
          verificationCode: response?.result?.verificationCode,
        }));
      }
      dispatch(
        addNotification({
          type: "success",
          message: "Successfully Sent the OTP",
        })
      );
    } catch (error) {
      dispatch(
        addNotification({ type: "error", message: error?.data?.result })
      );
    }
  };

  const handleVerifyOTP = async () => {
    try {
      const userData = {
        verificationCode: formData.verificationCode,
        otp: formData.otp,
      };
      const response = await verifyOTP(userData).unwrap();
      if (response?.message == "OK") {
        navigate("/reset-password", { state: formData?.verificationCode });
      }
      dispatch(
        addNotification({
          type: "success",
          message: "OTP Verified",
        })
      );
    } catch (error) {
      dispatch(
        addNotification({ type: "error", message: error?.data?.result })
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { isValid, errors } = validateLoginForm(formData);
    setFormError(errors);

    if (isValid) {
      setFormError({});
      if (allowOTP) {
        handleVerifyOTP();
      } else {
        handleForgetPassword();
      }
    }
  };
  return (
    <div className="login-page">
      <div className="flex min-h-screen lg:flex-row flex-col">
        <div className="basis-1/2 login-banner bg-cover bg-center bg-no-repeat bg-account-banner flex items-center justify-center max-h-screen sticky top-0 left-0 max-lg:hidden">
          <div className="flex flex-col gap-6 account-logo">
            <Logo />
            <h2 className="xl:text-[35px] lg:text-[28px] text-black">
              Welcome to MedRef
            </h2>
            <p className="text-base text-black flex items-center gap-1">
              Go back to
              <Link to="/login" className="text-primary font-semibold">
                Login
              </Link>
            </p>
          </div>
        </div>
        <div className="block lg:hidden pt-8">
          <div className="flex flex-col gap-4 items-center account-logo">
            <Logo />
            <div className="text-center">
              <h2 className="text-2xl text-black">Welcome to MedRef</h2>
            </div>
          </div>
        </div>
        <div className=" lg:basis-1/2 basis-full flex-1 pb-8 lg:pb-8 xl:pt-[100px] xl:px-[50px] lg:pt-[70px] lg:px-[40px] md:p-8 p-5 md:pt-[40px] pt-[40px] flex">
          <div className="lg:max-w-[450px] flex flex-col justify-between flex-1">
            <div>
              <div className="">
                <h3 className="xl:text-3xl lg:text-2xl md:text-xl text-lg h-inter font-bold">
                  Forgot Password of Account!
                </h3>
                <p className="text-color_8692A6 xl:text-lg md:text-base text-sm pt-4 h-inter">
                  For the purpose of MedRef regulation, your details are
                  required.
                </p>
              </div>

              <div className="pt-4 border-t border-[#F5F5F5] mt-[14px]">
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-col lg:gap-6 gap-4">
                    <Input
                      label="Contact No."
                      placeholder={"Enter your contact no"}
                      type="tel"
                      className="border border-color_758092 h-[40px] rounded-[6px] px-4 py-2"
                      name="contact"
                      changeEvent={handleChange}
                      isErrorMsg={formError?.contact}
                      attributes={{ value: formData?.contact }}
                      isDisable={allowOTP}
                      isFocus={true}
                    />
                    {allowOTP && (
                      <div className="">
                        <Input
                          label="OTP"
                          placeholder={"Enter your OTP"}
                          type="text"
                          className="border border-color_758092 h-[40px] rounded-[6px] px-4 py-2"
                          name="otp"
                          changeEvent={handleChange}
                          isErrorMsg={formError?.otp}
                          attributes={{ value: formData?.otp }}
                        />
                      </div>
                    )}
                  </div>

                  <div className="lg:mt-9 mt-6">
                    {!allowOTP ? (
                      <Button
                        type="submit"
                        onClick={handleForgetPassword}
                        isLoading={forgetPasswordLoading.isLoading}
                      >
                        Send OTP
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        onClick={handleVerifyOTP}
                        isLoading={verifyOTPLoading.isLoading}
                      >
                        Verify
                      </Button>
                    )}
                  </div>
                </form>
                <div className="pt-3 lg:hidden">
                  <p className="lg:text-base md:text-base text-sm text-black flex items-center justify-center gap-1 max-md:flex-col">
                    If you don’t have an account You can{" "}
                    <Link to="/register" className="text-primary font-semibold">
                      Register here !
                    </Link>
                  </p>
                </div>
              </div>
            </div>

            <AccountFooterLink />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
