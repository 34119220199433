import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import React, { useState } from "react";
import dayjs from "dayjs";

const newTheme = (theme) =>
  createTheme({
    ...theme,
    components: {
      MuiDateCalendar: {
        styleOverrides: {
          root: {
            color: "#263a43",
            borderRadius: 20,
            backgroundColor: "#fff",
            padding: 0,
          },
        },
      },
    },
  });

const DatePickerApp = (props) => {
  const {
    type,
    attribute,
    className,
    label,
    placeholder,
    name,
    changeEvent,
    dateFormat = "dd/MM/yyyy",
    isFocus = false,
    isErrorMsg = null,
    isReadOnly = false,
    isDisabled = false,
    disableFuture = true,
    value,
  } = props;
  const [startDate, setStartDate] = useState(new Date());

  const handleDate = (date) => {
    setStartDate(date);
    changeEvent(name, date);
  };
  return (
    <div
      className={`datePicker flex gap-2.5 flex-col ${
        isDisabled ? "cursor-not-allowed" : ""
      }`}
    >
      <label className="input-label">{label}</label>
      {type == "datePicker" && (
        <ThemeProvider theme={(newTheme) => newTheme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                defaultValue={dayjs(new Date())}
                value={value}
                onChange={(newValue) => handleDate(newValue)}
                name={name}
                // label={placeholder}
                disableFuture={disableFuture}
                format="DD/MM/YYYY"
                disabled={isDisabled}
                className={`${
                  isDisabled ? "!bg-[#e6e3e3] !cursor-not-allowed" : ""
                }`}
                {...attribute}
              />
            </DemoContainer>
          </LocalizationProvider>
        </ThemeProvider>
      )}
    </div>
  );
};

export default DatePickerApp;
