import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MinistryLabel from "../../components/common/MinistryLabel/MinistryLabel";
import Input from "../../components/form/input";
import "../../scss/childRegister/childRegister.scss";
import ChildRegisterTable from "../../components/childRegister/ChildRegisterTable";
import { CaretDownIcon, DownloadIcon, PlusWhiteIcon } from "../../assets/Icons";
import Button from "../../components/common/Button";
import { useGetChildListMutation } from "../../apis/authApi";
import { addNotification } from "../../store/slices/toaster-slice";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
import CustomPagination from "../../components/common/pagination/CustomPagination";
import SearchInput from "../../components/common/searchInput/searchInput";

const ChildRegister = () => {
  const [openFilters, setOpenFilters] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [getChild, getChildLoading] = useGetChildListMutation();
  const [childData, setChildData] = useState({});
  const [isScrolled, setIsScrolled] = useState(false);
  const [prevScrollY, setPrevScrollY] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [page, setPage] = useState(1);
  const tableScrollRef = useRef();
  const registerBookRef = useRef();
  const tableScrollWrapperRef = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    handleGetChildData();
  }, [page, searchValue]);

  useEffect(() => {
    setTimeout(() => {
      const registerTable = document.getElementById("register-table");
      if (registerTable) {
        setScrollWidth(registerTable?.clientWidth + 50);
      }
    }, 1000);
  }, []);

  const handleGetChildData = async () => {
    try {
      const query = {
        search: searchValue,
        page: page,
      };
      const response = await getChild(query).unwrap();
      setChildData(response);
      addNotification({ type: "success", message: "Successfully Register" });
    } catch (error) {
      if (error?.status == 401 || error?.status == 403) {
        sessionStorage.removeItem("access_token");
        navigate("/login");
        dispatch(
          addNotification({
            type: "error",
            message: "Token is invalid or expired",
          })
        );
      } else {
        dispatch(
          addNotification({ type: "error", message: "Unable to fetch!" })
        );
      }
    }
  };

  const handleScroll = (sourceDiv, targetDiv) => {
    const registerTable = document.getElementById("register-table");
    const targetScrollLeft = sourceDiv.scrollLeft;
    targetDiv.current.scrollLeft = targetScrollLeft;
  };

  return (
    <div className="child-register xl:px-[35px] xl:py-12 lg:px-5 lg:py-7 p-4 relative">
      <div className="flex justify-between flex-col">
        <div>
          <MinistryLabel title="CHILD REGISTER" />
        </div>
        <div className="mt-6 flex md:items-center md:justify-between max-md:flex-col gap-4">
          <SearchInput
            setSearchValue={setSearchValue}
            placeholder="Search by child no."
          />
          <div>
            {/* <Button type="submit" btnType="normal">
              <DownloadIcon /> Download
            </Button> */}
          </div>
        </div>
      </div>
      <div
        ref={registerBookRef}
        className={`relative lg:mt-8 mt-6 bg-white py-4 px-6 rounded-xl overflow-auto scrollbar-none scrollbar-w-1.5 scrollbar-h-1.5 scrollbar-thumb-primary scrollbar-track-slate-200`}
      >
        <ChildRegisterTable
          childList={childData?.result?.childrenData}
          loading={getChildLoading?.isLoading}
        />
      </div>
      <div
        ref={tableScrollWrapperRef}
        className="bg-white sticky left-0 bottom-0 w-full h-2.5 z-[10] overflow-x-auto scrollbar-h-2"
        onScroll={() => {
          handleScroll(tableScrollWrapperRef.current, registerBookRef);
        }}
      >
        <div ref={tableScrollRef} style={{ width: `${scrollWidth}px` }}></div>
      </div>
      {childData?.result?.page?.total > 10 && (
        <CustomPagination
          setPage={setPage}
          page={page}
          count={childData?.result?.page?.total}
        />
      )}
    </div>
  );
};

export default ChildRegister;
