import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useGetDistrictsMutation,
  useGetSubCountiesMutation,
  useRegisterTDMutation,
} from "../../apis/authApi";
import PasswordLikeInput from "../../components/PasswordLikeInput";
import Button from "../../components/common/Button";
import DropDown from "../../components/common/Dropdown";
import MinistryLabel from "../../components/common/MinistryLabel/MinistryLabel";
import Input from "../../components/form/input";
import { addNotification } from "../../store/slices/toaster-slice";
import { calculateAge, regEx } from "../../utils/helper";

const validateRegForm = (data) => {
  let errors = {};
  let isValid = true;

  if (!data?.name) {
    errors.name = "Name is required";
    isValid = false;
  } else if (!regEx.NAME.test(data?.name)) {
    errors.name = "Invalid name";
    isValid = false;
  }

  if (!data?.contact) {
    errors.contact = "Contact is required";
    isValid = false;
  } else if (!regEx.PHONE.test(data?.contact)) {
    errors.contact = "Please enter valid contact number";
    isValid = false;
  }
  if (!data?.category) {
    errors.category = "Please select your category";
    isValid = false;
  }
  if (!data?.district) {
    errors.district = "District is required";
    isValid = false;
  }
  if (!data?.subCounty) {
    errors.subCounty = "Sub county is required";
    isValid = false;
  }
  if (!data?.parish) {
    errors.parish = "Parish is required";
    isValid = false;
  }
  if (!data?.village) {
    errors.village = "Village is required";
    isValid = false;
  }
  if (!data?.motherName) {
    errors.motherName = "Mother/Caretaker name is required";
    isValid = false;
  }
  if (data?.age < 15) {
    errors.age = "Your age must be greater than 15 years.";
    isValid = false;
  }
  return { isValid, errors };
};

const initialState = {
  name: "",
  nin: "",
  gender: "female",
  category: "",
  dateOfBirth: new Date(),
  contact: "",
  motherName: "",
  district: "",
  subCounty: "",
  parish: "",
  village: "",
  age: "",
};

const AddNewTD = () => {
  const [formData, setFormData] = useState(initialState);
  const [formError, setFormError] = useState({});
  const [allDistricts, setAllDistricts] = useState([]);
  const [allSubcounties, setAllSubcounties] = useState([]);
  const [registerTD, registerTDLoading] = useRegisterTDMutation();
  const [getDistricts, districtsLoading] = useGetDistrictsMutation();
  const [getSubcounties, subcountiesLoading] = useGetSubCountiesMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    handleGetDistricts();
  }, []);

  // fetch data based on selected district
  useEffect(() => {
    if (formData?.district) {
      handleGetsubCounty();
    }
  }, [formData?.district]);

  const handleGetDistricts = async () => {
    try {
      const response = await getDistricts().unwrap();

      // // Convert the response back to an array of objects
      const uniqueDistrictsArray = Array.from(response?.result?.districts).map(
        (district) => ({
          label: district.district,
          value: district.district,
          id: district.id,
        })
      );
      // Update the state with unique districts
      setAllDistricts(uniqueDistrictsArray);
    } catch (error) {
      dispatch(addNotification({ type: "error", message: "Unable to fetch!" }));
    }
  };
  const handleGetsubCounty = async () => {
    try {
      let param = {
        districtId: formData?.district?.id,
      };
      const response = await getSubcounties(param).unwrap();

      // Convert the Set back to an array of objects
      const uniqueSubcountiesArray = Array.from(
        response?.result?.subCounties
      ).map((sub_county) => ({
        label: sub_county.subCountyName,
        value: sub_county.subCountyName,
        id: sub_county.id,
      }));
      // Update the state with unique sub-county
      setAllSubcounties(uniqueSubcountiesArray);
    } catch (error) {
      dispatch(addNotification({ type: "error", message: "Unable to fetch!" }));
    }
  };

  const handleDateChange = (name, value) => {
    setFormData((prevObj) => ({
      ...prevObj,
      [name]: value,
      age: calculateAge(value),
    }));
  };
  const handleNinInput = (name, value) => {
    setFormData((prevObj) => ({
      ...prevObj,
      [name]: value,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevObj) => ({
      ...prevObj,
      [name]: value,
    }));
  };
  const handleAddressChange = (name, value) => {
    setFormData((prevObj) => ({
      ...prevObj,
      [name]: value,
    }));
  };

  const handleRegisterTD = async () => {
    try {
      let clientInfo = {
        nin: formData?.nin ? formData?.nin :null,
        name: formData?.name,
        dateOfBirth: formData?.dateOfBirth,
        gender: "Female",
        motherName: formData?.motherName,
        category: formData?.category,
        contact: formData?.contact && `+256${formData?.contact}`,
        district: formData?.district?.value,
        subCounty: formData?.subCounty?.value,
        ward: formData?.parish,
        village: formData?.village,
        age: calculateAge(formData?.dob),
      };

      const response = await registerTD(clientInfo).unwrap();
      dispatch(
        addNotification({ type: "success", message: response?.data?.result })
      );
      navigate(`/vaccination-card/${response?.message?.clientNo}`);
      formData(initialState);
    } catch (error) {
      dispatch(
        addNotification({ type: "error", message: error?.data?.result })
      );
    }
  };

  // Handle Submit
  const handleSubmit = (e) => {
    e.preventDefault();
    const { isValid, errors } = validateRegForm(formData);
    setFormError(errors);

    if (isValid) {
      setFormError({});
      handleRegisterTD();
    }
  };
  return (
    <div className="xl:px-[35px] xl:py-12 lg:px-5 lg:py-7 p-4">
      <div className="flex gap-3 justify-between md:items-end max-md:flex-col">
        <div>
          <MinistryLabel title="Add TD Card" />
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="lg:mt-8 mt-6 bg-[#FFFFFF] rounded-xl lg:px-[26px] px-[14px] lg:py-[32px] py-[20px] flex flex-col gap-3 md:gap-5 xl:gap-7">
          <div className="flex flex-col md:flex-row gap-3 md:gap-5 xl:gap-7 flex-wrap ">
            <PasswordLikeInput
              label="NIN"
              name="nin"
              className="w-auto"
              changeEvent={handleNinInput}
              attributes={{ value: formData?.nin }}
            />
          </div>
          <div className="flex flex-col md:flex-row gap-3 md:gap-5 xl:gap-7 flex-wrap">
            <Input
              label="Name"
              placeholder={"Full name"}
              type="text"
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
              changeEvent={handleInputChange}
              name="name"
              isErrorMsg={formError?.name}
              attributes={{ value: formData?.name }}
            />
            <Input
              label="Date of Birth"
              type="datePicker"
              name="dob"
              changeEvent={handleDateChange}
            />
            <Input
              label="Age"
              placeholder={"Your age"}
              type="text"
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
              name="age"
              isDisabled={true}
              isErrorMsg={formError?.age}
              attributes={{ value: formData?.age }}
            />
          </div>
          <div className="flex flex-col md:flex-row gap-3 md:gap-5 xl:gap-7 flex-wrap">
            <Input
              label="Category"
              placeholder={"Enter Category"}
              type="radio"
              options={[
                { name: "National", id: "national" },
                { name: "Refugee", id: "refugee" },
                { name: "Foreigner", id: "foreigner" },
              ]}
              isErrorMsg={formError?.category}
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full text-font"
              changeEvent={handleInputChange}
              name="category"
            />
          </div>

          <div className="flex flex-col md:flex-row gap-3 md:gap-5 xl:gap-7 flex-wrap">
            <Input
              label="Mother’s Name"
              placeholder={"Enter Mother’s Name"}
              type="text"
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
              changeEvent={handleInputChange}
              name="motherName"
              isErrorMsg={formError?.motherName}
              attributes={{ value: formData?.motherName }}
            />
            <Input
              label="Contact"
              placeholder={"Enter Contact No."}
              type="tel"
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
              changeEvent={handleInputChange}
              name="contact"
              attributes={{
                value: formData?.contact,
              }}
              isErrorMsg={formError?.contact}
            />
            <div
              className={`input-wrapper flex flex-col xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full ${
                formError?.district ? "error" : ""
              } `}
            >
              <label className="input-label mb-2.5">District/City</label>
              <DropDown
                options={allDistricts}
                inputWidth={"100%"}
                isMultiSelected={false}
                placeholder="Select Districts"
                isLoading={districtsLoading?.isLoading}
                onSelectChange={(value) =>
                  handleAddressChange("district", value)
                }
                selectedValue={formData?.district}
              />
              {formError?.district !== null && (
                <p className="error-text">
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M8 12.173a0.591 0.591 0 1 0 0 -1.182 0.591 0.591 0 0 0 0 1.182Zm0 -2.123a0.444 0.444 0 0 1 -0.445 -0.445v-5.333a0.444 0.444 0 1 1 0.889 0v5.333a0.444 0.444 0 0 1 -0.444 0.444Z"
                        fill="red"
                      />
                      <path
                        d="M8 15.111a7.112 7.112 0 1 1 0 -14.222 7.112 7.112 0 0 1 0 14.222Zm0 -13.333a6.222 6.222 0 1 0 0 12.445 6.222 6.222 0 0 0 0 -12.445Z"
                        fill="red"
                      />
                    </svg>
                  </span>
                  {formError?.district}
                </p>
              )}
            </div>
            <div
              className={`input-wrapper flex flex-col xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full ${
                formError?.subCounty ? "error" : ""
              } `}
            >
              <label className="input-label mb-2.5">
                Sub Country/ Division
              </label>
              <DropDown
                options={allSubcounties}
                isSearchable={true}
                inputWidth={"100%"}
                isMultiSelected={false}
                placeholder="Select Sub-counties"
                isLoading={subcountiesLoading?.isLoading}
                onSelectChange={(value) =>
                  handleAddressChange("subCounty", value)
                }
                selectedValue={formData?.subCounty}
                disabled={formData?.district ? false : true}
              />
              {formError?.subCounty !== null && (
                <p className="error-text">
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M8 12.173a0.591 0.591 0 1 0 0 -1.182 0.591 0.591 0 0 0 0 1.182Zm0 -2.123a0.444 0.444 0 0 1 -0.445 -0.445v-5.333a0.444 0.444 0 1 1 0.889 0v5.333a0.444 0.444 0 0 1 -0.444 0.444Z"
                        fill="red"
                      />
                      <path
                        d="M8 15.111a7.112 7.112 0 1 1 0 -14.222 7.112 7.112 0 0 1 0 14.222Zm0 -13.333a6.222 6.222 0 1 0 0 12.445 6.222 6.222 0 0 0 0 -12.445Z"
                        fill="red"
                      />
                    </svg>
                  </span>
                  {formError?.subCounty}
                </p>
              )}
            </div>
            <Input
              label="Parish/Ward:"
              placeholder={"Enter Parish/Ward:"}
              type="text"
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
              changeEvent={handleInputChange}
              name="parish"
              isErrorMsg={formError?.parish}
              attributes={{ value: formData?.parish }}
            />
            <Input
              label="LC 1/Village/Cell"
              placeholder={"Enter LC 1/Village/Cell"}
              type="text"
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
              changeEvent={handleInputChange}
              name="village"
              isErrorMsg={formError?.village}
              attributes={{ value: formData?.village }}
            />
          </div>

          <div className="lg:mt-9 mt-6 flex justify-end">
            <Button
              type="submit"
              btnType="normal"
              isLoading={registerTDLoading?.isLoading}
              classname="md:max-w-[232px]"
            >
              Create Card
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddNewTD;
