import React, { useState } from "react";
import { InfoIcon } from "../../assets/Icons";
import ViewDefaulterDetailsPopup from "../childRegister/ViewDefaulterDetailsPopup";
import Popup from "../common/Popup";

const DefaulterTDCard = ({ data }) => {
  const [popupStatus, setPopupStatus] = useState(false);

  const handleVaccinesPopup = () => {
    setPopupStatus(!popupStatus);
  };
  return (
    <div className="bg-white rounded p-5 flex midLap:items-center justify-between shadow-cardShadow2 w-full">
      <div className="flex midLap:gap-1.5 max-midLap:flex-col md:gap-5 gap-3">
        <div className="md:w-[50px] md:h-[50px] w-[30px] h-[30px]  rounded-full bg-color_FF1212">
          {/* <img src="" alt="" /> */}
        </div>
        <div>
          <h5 className="text-xs text-color_FF1212">{data?.name}</h5>
          <p className="text-[10px] pt-2 text-color_FF1212 flex gap-0.5">
            <span className="font-semibold">Child No:</span>
            <span className="font-normal">{data?.clientNo ?? "---"}</span>
          </p>
          <div className="pt-2 flex sm:items-center sm:gap-4 gap-2 text-[10px] text-color_FF1212  max-sm:flex-wrap">
            <div className="flex gap-0.5">
              <span className="font-semibold">Mother no: </span>
              <span className="font-normal">{data?.contact ?? "---"}</span>
            </div>
            <div className="flex gap-0.5">
              <span className="font-semibold">Age: </span>
              <span className="font-normal">{data?.age ?? "---"}</span>
            </div>
            <div className="flex gap-0.5">
              <span className="font-semibold">Gender: </span>
              <span className="font-normal">{data?.gender ?? "---"}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-1 flex-col">
        {/* <span className="border border-color_FF1212 rounded-full w-[22px] h-[22px] flex items-center justify-center cursor-pointer">
          <EditIcon width="12px" height="12px" fill="#FF1212" />
        </span> */}
        <span
          className="border border-color_FF1212 rounded-full w-[22px] h-[22px] flex items-center justify-center cursor-pointer"
          onClick={handleVaccinesPopup}
        >
          <InfoIcon width="12px" height="12px" fill="#FF1212" />
        </span>
        {/* <span className="border border-color_FF1212 rounded-full w-[22px] h-[22px] flex items-center justify-center cursor-pointer">
          <RemainderIcon width="12px" height="12px" fill="#FF1212" />
        </span> */}
      </div>

      {popupStatus && (
        <Popup
          title="Client Missed Vaccine"
          width="400px"
          closeBtn={true}
          closeHandler={handleVaccinesPopup}
        >
          <ViewDefaulterDetailsPopup
            closePopup={handleVaccinesPopup}
            vaccines={data?.doses}
          />
        </Popup>
      )}
    </div>
  );
};

export default DefaulterTDCard;
