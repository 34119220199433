import React from "react";
import Button from "../common/Button";
import Input from "../form/input";
import { useState } from "react";
import { regEx } from "../../utils/helper";
import { addNotification } from "../../store/slices/toaster-slice";
import { useDispatch } from "react-redux";
import { useUpdateChildContactMutation } from "../../apis/authApi";
import { useEffect } from "react";

const validateRegForm = (data) => {
  let errors = {};
  let isValid = true;
  if (!data?.motherContact) {
    errors.motherContact = "Mother Contact is required";
    isValid = false;
  } else if (!regEx.PHONE.test(data?.motherContact)) {
    errors.motherContact = "Please enter valid contact number";
    isValid = false;
  }
  return { isValid, errors };
};
const UpdateContactDetails = (props) => {
  const { closeHandler, data, updateRecord } = props;
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState({});
  const [updateContact, updateContactLoading] = useUpdateChildContactMutation();
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevObj) => ({
      ...prevObj,
      [name]: value,
    }));
  };
  const handleUpdateContact = async () => {
    try {
      let query = {
        childId: data?.id,
      };
      let contactData = {
        motherContact: `+256${formData?.motherContact}`,
        fatherContact: formData?.fatherContact
          ? `+256${formData?.fatherContact}`
          : null,
      };
      let response = await updateContact({ query, contactData }).unwrap();
      updateRecord(data?.childNo);
      dispatch(
        addNotification({ type: "success", message: "Successfully Updated" })
      );
      closeHandler();
    } catch (error) {
      dispatch(
        addNotification({
          type: "error",
          message: error?.data?.result,
        })
      );
    }
  };

  useEffect(() => {
    setFormData(() => ({
      motherContact: (data?.parent?.motherContact).replace("+256", ""),
      fatherContact: data?.parent?.fatherContact?.replace("+256", ""),
    }));
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { isValid, errors } = validateRegForm(formData);
    setFormError(errors);

    if (isValid && !updateContactLoading?.isLoading) {
      setFormError({});
      handleUpdateContact();
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="w-full flex flex-col gap-4">
          <Input
            label="Mother’s Contact No."
            placeholder={"Enter Contact No."}
            type="tel"
            className="normal-input w-full"
            changeEvent={handleInputChange}
            name="motherContact"
            attributes={{
              value: formData?.motherContact,
            }}
            isErrorMsg={formError?.motherContact}
          />
          <Input
            label="Father's Contact No."
            placeholder={"Enter Contact No."}
            type="tel"
            className="normal-input w-full"
            changeEvent={handleInputChange}
            name="fatherContact"
            attributes={{
              value: formData?.fatherContact,
            }}
          />
        </div>
        <div className="flex items-center gap-5 mt-5">
          <Button type="submit" btnType="normal" clickHandler={closeHandler}>
            Cancel
          </Button>
          <Button
            type="submit"
            btnType="normal"
            isLoading={updateContactLoading?.isLoading}
          >
            Update
          </Button>
        </div>
      </form>
    </div>
  );
};

export default UpdateContactDetails;
