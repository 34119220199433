import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  childNutritionDetails: null,
};

export const childNutritionDetails = createSlice({
  name: "childNutritionDetailMarkUp",
  initialState,
  reducers: {
    setChildNutritionDetails(state, action) {
      state.childNutritionDetails = action.payload;
    },
  },
});

export const { setChildNutritionDetails } = childNutritionDetails.actions;
export default childNutritionDetails.reducer;
