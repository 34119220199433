import React from "react";
import { HelpIcon, Logo, LogoutIcon } from "../../assets/Icons";
import ChildRegisterMenu from "../common/menus/ChildRegisterMenu";
import { Link, redirect, useNavigate } from "react-router-dom";
import TDMenu from "../common/menus/TDMenu";
import { useDispatch, useSelector } from "react-redux";
import { addNotification } from "../../store/slices/toaster-slice";
import HPVMenu from "../common/menus/HPVMenu";

const Sidebar = () => {
  const { userDetails } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    sessionStorage.removeItem("access_token");
    dispatch(
      addNotification({ type: "success", message: "Successfully Logout" })
    );
    navigate("/login");
  };
  return (
    <div className="sidebar flex-1 pt-8 lg:pb-[60px] md:pb-10 pb-6 flex flex-col lg:gap-[70px] gap-10">
      <div className="logo w-full flex items-center justify-center">
        <Logo />
      </div>
      <div className="flex flex-col justify-between gap-5 flex-1">
        <div className="menu-wrapper">
          {userDetails?.hwType === "child" && <ChildRegisterMenu />}
          {userDetails?.hwType === "td" && <TDMenu />}
          {userDetails?.hwType === "hpv" && <HPVMenu />}
        </div>
        <div>
          <div className="menu-wrapper">
            <ul className="menu">
              <li>
                <Link
                  className={`menu-link flex gap-3 items-center `}
                >
                  <span>
                    <HelpIcon />
                  </span>
                  Help
                </Link>
              </li>
              <li>
                <div
                  className={`menu-link flex gap-3 items-center  cursor-pointer`}
                  onClick={handleLogout}
                >
                  <span>
                    <LogoutIcon />
                  </span>
                  Log out
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
