import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  AdminIcon,
  CheckedIcon,
  UserIcon,
  UsersIcon,
} from "../../assets/Icons";
import Button from "../common/Button";

const ChooseAccount = ({ handleSteps }) => {
  const [chooseAccount, setChooseAccount] = useState("health_worker");

  const handleChooseAccount = (account) => {
    setChooseAccount(account);
  };
  return (
    <div className="choose-account lg:pb-8 lg:pt-[70px] lg:px-[50px] md:p-8 p-5 md:pt-[50px] pt-[50px] flex flex-col h-full max-h-[calc(100%-44px)]">
      <div className="lg:mb-[50px] sm:mb-8 mb-4">
        <p className="lg:text-lg md:text-base text-sm text-color_758092 flex items-center gap-1 justify-end">
          Already have an account?
          <Link to="/login" className="text-primary font-medium">
            Sign In
          </Link>
        </p>
      </div>
      <div className="lg:max-w-[450px] flex flex-col justify-between flex-1">
        <div className="flex-1">
          <div>
            <h3 className="xl:text-3xl lg:text-2xl md:text-xl text-lg h-inter font-bold">
              Choose Your Account
            </h3>
            <p className="text-color_8692A6 xl:text-lg md:text-base text-sm pt-4 h-inter">
              To begin this journey, tell us what type of account you’d be.
            </p>
          </div>
          <div className="lg:mt-10 lg:mb-8 my-6 flex flex-col gap-6">
            <div
              className={`account rounded-md relative bg-white shadow-cardShadow px-4 lg:py-6 py-4 cursor-pointer flex lg:gap-4 gap-2 justify-between items-center transition-all duration-300 border hover:border-primary hover:scale-105 max-sm:flex-col-reverse ${
                chooseAccount === "admin"
                  ? "active border-primary"
                  : "border-transparent"
              }`}
              onClick={() => handleChooseAccount("admin")}
            >
              <div className="flex lg:gap-4 gap-3 items-center w-full">
                <div className="icon-shape">
                  <AdminIcon />
                </div>
                <div className="flex-1">
                  <h5 className="text-base font-medium">Admin</h5>
                  <p className="md:text-sm text-xs text-color_8692A6 lg:pt-4 pt-2">
                    Admin can Manage all Activities
                  </p>
                </div>
              </div>
              {chooseAccount === "admin" && (
                <div className="max-sm:absolute top-4 right-4">
                  <CheckedIcon />
                </div>
              )}
            </div>
            <div
              className={`account rounded-md relative bg-white shadow-cardShadow px-4 lg:py-6 py-4 cursor-pointer flex lg:gap-4 gap-2 justify-between items-center transition-all duration-300 border hover:border-primary hover:scale-105 max-sm:flex-col-reverse ${
                chooseAccount === "health_worker"
                  ? "active border-primary"
                  : "border-transparent"
              }`}
              onClick={() => handleChooseAccount("health_worker")}
            >
              <div className="flex lg:gap-4 gap-3 items-center w-full">
                <div className="icon-shape">
                  <UserIcon />
                </div>
                <div>
                  <h5 className="text-base font-medium">Health Worker</h5>
                  <p className="md:text-sm text-xs text-color_8692A6 lg:pt-4 pt-2">
                    Health Worker to manage all activities.
                  </p>
                </div>
              </div>
              {chooseAccount === "health_worker" && (
                <div className="max-sm:absolute top-4 right-4">
                  <CheckedIcon />
                </div>
              )}
            </div>
            <div
              className={`account rounded-md relative bg-white shadow-cardShadow px-4 lg:py-6 py-4 cursor-pointer flex lg:gap-4 gap-2 justify-between items-center transition-all duration-300 border hover:border-primary hover:scale-105 max-sm:flex-col-reverse ${
                chooseAccount === "parent"
                  ? "active border-primary"
                  : "border-transparent"
              }`}
              onClick={() => handleChooseAccount("parent")}
            >
              <div className="flex lg:gap-4 gap-3 items-center w-full">
                <div className="icon-shape">
                  <UsersIcon />
                </div>
                <div>
                  <h5 className="text-base font-medium">Parent/Guardian</h5>
                  <p className="md:text-sm text-xs text-color_8692A6 lg:pt-4 pt-2">
                    Manage your Child Health activities
                  </p>
                </div>
              </div>
              {chooseAccount === "parent" && (
                <div className="max-sm:absolute top-4 right-4">
                  <CheckedIcon />
                </div>
              )}
            </div>
          </div>
          <div>
            <Button
              type="button"
              btnType='large'
              clickHandler={() => handleSteps("chooseAccount", chooseAccount)}
            >Next</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseAccount;
