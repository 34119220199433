import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChildDashboardChart from "../../components/childRegister/ChildDashboardChart";
import DefaulterChildCard from "../../components/common/DefaulterChildCard";
import MinistryLabel from "../../components/common/MinistryLabel/MinistryLabel";
import VaccinatedChildCard from "../../components/common/VaccinatedChildCard";
import ComponentLoader from "../../components/common/loader/ComponentLoader";
import NoDataFound from "../../components/common/loader/NoDataFound";
import CustomPagination from "../../components/common/pagination/CustomPagination";
import "../../scss/childRegister/childDashboard.scss";
import { addNotification } from "../../store/slices/toaster-slice";

const HPVDashboard = () => {
  const [countsData, setCountsData] = useState({});
  const [vaccinatedData, setVaccinatedData] = useState({});
  const [defaulterData, setDefaulterData] = useState({});
  const [vaccinatedPage, setVaccinatedPage] = useState(1);
  const [defaulterPage, setDefaulterPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="child-dashboard xl:px-[35px] xl:py-12 lg:px-5 lg:py-7 p-4">
      <div className="flex gap-3 justify-between md:items-end max-md:flex-col">
        <div>
          <MinistryLabel title="Home" />
        </div>
      </div>
      <div className="pt-6 flex gap-6 max-lg:flex-col">
        <div className="flex flex-col gap-4 lg:w-1/2">
          <div className="cards">
            <div className="card">
              <p className="md:text-sm text-xs text-[#000000B2] font-medium">
                Static Vaccinated (Dose)
              </p>
              <p className="xl:pt-6 lg:pt-4 pt-2 xl:text-2xl lg:text-lg md:text-base text-sm text-font font-bold">
                {countsData?.static ? countsData?.static : 0}
              </p>
            </div>
            <div className="card">
              <p className="md:text-sm text-xs text-[#000000B2] font-medium">
                Out- Reached Vaccinated (Dose)
              </p>
              <p className="xl:pt-6 lg:pt-4 pt-2 xl:text-2xl lg:text-lg md:text-base text-sm text-font font-bold">
                {countsData?.outreached ? countsData?.outreached : 0}
              </p>
            </div>
            <div className="card">
              <p className="md:text-sm text-xs text-[#000000B2] font-medium">
                This Month Vaccinated (Dose)
              </p>
              <p className="xl:pt-6 lg:pt-4 pt-2 xl:text-2xl lg:text-lg md:text-base text-sm text-font font-bold">
                {countsData?.total ? countsData?.total : 0}
              </p>
            </div>
            <div className="card chart-card">
              <p className="md:text-sm text-xs text-[#000000B2] font-medium">
                Vaccine Appointments
              </p>
              <p className="xl:pt-6 lg:pt-4 pt-2 xl:text-2xl lg:text-lg md:text-base text-sm text-font font-bold">
                {countsData?.appointments ? countsData?.appointments : 0}
              </p>
            </div>
            <div className="card chart-card">
              <p className="md:text-sm text-xs text-[#000000B2] font-medium">
                Vaccine Defaulters
              </p>
              <p className="xl:pt-6 lg:pt-4 pt-2 xl:text-2xl lg:text-lg md:text-base text-sm text-font font-bold">
                {countsData?.defaulters ? countsData?.defaulters : 0}
              </p>
            </div>
          </div>
        </div>
        <div className="flex-1 lg:w-1/2 min-h-[100px] bg-white">
          <ChildDashboardChart
            vaccinated={countsData?.total}
            defaulter={countsData?.defaulters}
          />
        </div>
      </div>

      {/* <div className="lg:mt-8 mt-6 md:p-6 p-4 bg-white rounded-[20px] border border-stroke_color">
        <div className="heading mb-5">
          <h4 className="lg:text-lg text-base font-semibold text-black">
            Vaccinated Client's
          </h4>
        </div>
        <div className="flex xl:gap-10 md:gap-7 gap-5 max-md:flex-col flex-wrap">
          {vaccinatedLoading.isLoading ? (
            <ComponentLoader />
          ) : vaccinatedData?.results?.length ? (
            vaccinatedData?.results.map((child, index) => {
              return (
                <div
                  className="xl:basis-[calc(50%-20px)] md:basis-[calc(50%-14px)]"
                  key={index}
                >
                  <VaccinatedChildCard data={child} />
                </div>
              );
            })
          ) : (
            <NoDataFound />
          )}
        </div>
        {vaccinatedData?.count > 10 && (
          <CustomPagination
            setPage={setVaccinatedPage}
            page={vaccinatedPage}
            count={vaccinatedData?.count}
          />
        )}
      </div>
      <div className="lg:mt-8 mt-6 md:p-6 p-4 bg-white rounded-[20px] border border-stroke_color">
        <div className="heading mb-5">
          <h4 className="lg:text-lg text-base font-semibold text-black">
            Defaulter’s List
          </h4>
        </div>
        <div className="flex xl:gap-10 md:gap-7 gap-5 max-md:flex-col flex-wrap">
          {defaulterLoading.isLoading ? (
            <ComponentLoader />
          ) : defaulterData?.results?.length ? (
            defaulterData?.results.map((child, index) => {
              return (
                <div
                  className="xl:basis-[calc(50%-20px)] md:basis-[calc(50%-14px)]"
                  key={index}
                >
                  <DefaulterChildCard data={child} />
                </div>
              );
            })
          ) : (
            <NoDataFound />
          )}
        </div>
        {defaulterData?.count > 10 && (
          <CustomPagination
            setPage={setDefaulterPage}
            page={defaulterPage}
            count={defaulterData?.count}
          />
        )}
      </div> */}
    </div>
  );
};

export default HPVDashboard;
