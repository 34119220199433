import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetTDRegisterBookMutation } from "../../apis/authApi";
import { DownloadIcon } from "../../assets/Icons";
import Button from "../../components/common/Button";
import MinistryLabel from "../../components/common/MinistryLabel/MinistryLabel";
import CustomPagination from "../../components/common/pagination/CustomPagination";
import SearchInput from "../../components/common/searchInput/searchInput";
import TDRegisterTable from "../../components/td/TDRegisterTable";
import { addNotification } from "../../store/slices/toaster-slice";

const TdRegister = () => {
  const [searchValue, setSearchValue] = useState("");
  const [getTD, getTDLoading] = useGetTDRegisterBookMutation();
  const [TDData, setTDData] = useState({});
  const [isScrolled, setIsScrolled] = useState(false);
  const [prevScrollY, setPrevScrollY] = useState(0);
  const [page, setPage] = useState(1);
  const [scrollWidth, setScrollWidth] = useState(0);
  const tableScrollRef = useRef();
  const registerBookRef = useRef();
  const tableScrollWrapperRef = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    handleGetTDData();
  }, [page, searchValue]);

  const handleGetTDData = async () => {
    try {
      const query = {
        search: searchValue,
        page: page,
      };
      const response = await getTD(query).unwrap();
      setTDData(response);
      addNotification({ type: "success", message: "Successfully Register" });
    } catch (error) {
      if (error?.status == 401 || error?.status == 403) {
        sessionStorage.removeItem("access_token");
        navigate("/login");
        dispatch(
          addNotification({
            type: "error",
            message: "Token is invalid or expired",
          })
        );
      } else {
        dispatch(
          addNotification({ type: "error", message: "Unable to fetch!" })
        );
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const registerTable = document.getElementById("td-register-table");
      if (registerTable) {
        setScrollWidth(registerTable.clientWidth + 50);
      }
    }, 1000);
  }, []);

  // Event handler for the scroll event
  const handleScroll = (sourceDiv, targetDiv) => {
    const targetScrollLeft = sourceDiv.scrollLeft;
    targetDiv.current.scrollLeft = targetScrollLeft;
  };

  return (
    <div className="child-register xl:px-[35px] xl:py-12 lg:px-5 lg:py-7 p-4 relative">
      <div className="flex gap-3 justify-between md:items-end max-md:flex-col">
        <div>
          <MinistryLabel title="TETANUS DIPHTHERIA (TD) VACCINE REGISTER" />
          <div className="mt-3">
            <SearchInput
              setSearchValue={setSearchValue}
              placeholder="Search by client no."
            />
          </div>
        </div>
        <div className="">
          <Button type="submit" btnType="normal">
            <DownloadIcon /> Download
          </Button>
        </div>
      </div>
      <div
        ref={registerBookRef}
        className={`relative lg:mt-8 mt-6 bg-white py-4 px-6 rounded-xl overflow-auto scrollbar-none`}
      >
        <TDRegisterTable
          list={TDData?.result?.clientData}
          loading={getTDLoading?.isLoading}
        />
      </div>
      <div
        ref={tableScrollWrapperRef}
        className="bg-white sticky left-0 bottom-0 w-full h-2.5 z-[10] overflow-x-auto scrollbar-h-2"
        onScroll={() => {
          handleScroll(tableScrollWrapperRef.current, registerBookRef);
        }}
      >
        <div ref={tableScrollRef} style={{ width: `${scrollWidth}px` }}></div>
      </div>
      {TDData?.result?.page?.total > 10 && (
        <CustomPagination
          setPage={setPage}
          page={page}
          count={TDData?.result?.page?.total}
        />
      )}
    </div>
  );
};

export default TdRegister;
