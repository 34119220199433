import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetTDVaccinationAppointmentsMutation } from "../../apis/authApi";
import MinistryLabel from "../../components/common/MinistryLabel/MinistryLabel";
import Popup from "../../components/common/Popup";
import CustomPagination from "../../components/common/pagination/CustomPagination";
import TDAppointmentPopup from "../../components/td/TDAppointmentPopup";
import TDAppointmentTable from "../../components/td/TDAppointmentTable";
import "../../scss/childRegister/appointment.scss";
import { addNotification } from "../../store/slices/toaster-slice";

const TDAppointments = () => {
  const [getAppointments, appointmentsLoading] =
    useGetTDVaccinationAppointmentsMutation();
  const [appointmentLists, setAppointmentLists] = useState({});
  const [openPopup, setOpenPopup] = useState(false);
  const [vaccineData, setVaccineData] = useState({});
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    handleGetAppointment();
  }, [page]);

  const handleGetAppointment = async () => {
    try {
      const query = {
        page: page,
      };
      let response = await getAppointments(query).unwrap();
      setAppointmentLists(response?.result);
    } catch (error) {
      if (error?.status == 401 || error?.status == 403) {
        sessionStorage.removeItem("access_token");
        navigate("/login");
        dispatch(
          addNotification({
            type: "error",
            message: "Token is invalid or expired",
          })
        );
      } else {
        dispatch(
          addNotification({ type: "error", message: "Unable to fetch!" })
        );
      }
    }
  };

  const handlePopup = () => {
    setOpenPopup(!openPopup);
  };

  return (
    <div className="child-appointment xl:px-[35px] xl:py-12 lg:px-5 lg:py-7 p-4">
      <div className="flex gap-3 justify-between md:items-end max-md:flex-col">
        <div>
          <MinistryLabel
            title={`Appointments List - ${new Date().toLocaleDateString(
              "en-GB"
            )}`}
          />
        </div>
      </div>

      <div className="mt-8 bg-white rounded-xl overflow-auto ">
        <TDAppointmentTable
          lists={appointmentLists?.clientData}
          handlePopup={handlePopup}
          setVaccineData={setVaccineData}
          loading={appointmentsLoading?.isLoading}
        />
      </div>
      {appointmentLists?.page?.total > 10 && (
        <CustomPagination
          setPage={setPage}
          page={page}
          count={appointmentLists?.page?.total}
        />
      )}

      {openPopup && (
        <Popup
          title="Vaccination Details"
          width="600px"
          closeBtn={true}
          closeHandler={handlePopup}
        >
          <TDAppointmentPopup
            closePopup={handlePopup}
            data={vaccineData}
            refreshTable={handleGetAppointment}
          />
        </Popup>
      )}
    </div>
  );
};

export default TDAppointments;
