import React, { useCallback, useEffect, useRef, useState } from "react";
import { SearchIcon } from "../../../assets/Icons";
import { debounce } from "../../../utils/helper";

const SearchInput = ({ setSearchValue, placeholder = "Search..." }) => {
  let [searchInputValue, setSearchInputValue] = useState("");
  const delayedQuery = useRef(debounce((q) => setSearchValue(q), 500)).current;
  useEffect(() => {
    if (setSearchValue) {
      setSearchValue("");
    }
  }, [searchInputValue]);

  // const handleSearchValue = (e) => {
  //   let { value } = e.target;
  //   setSearchInputValue(value);
  //   delayedQuery(value);
  // };

  const handleSearchValue = useCallback(
    (e) => {
      let { value } = e.target;
      setSearchInputValue(value);
      delayedQuery(value);
    },
    [delayedQuery]
  );

  return (
    <div className="search-field laptop:max-w-[460px] xl:max-w-[320px] md:max-w-[240px] w-full flex relative">
      <form>
        <input
          type="search"
          name="search"
          value={searchInputValue ?? ""}
          className="border !border-primary lg:h-[40px] h-[40px] rounded-[6px] lg:px-4 lg:py-2 px-3 py-1.5 !pl-10 w-full lg:max-w-[350px] text-sm outline-none
          "
          placeholder={placeholder}
          onChange={handleSearchValue}
          autoFocus={false}
          autoComplete="false"
        />
        <span className="absolute p-3 left-0 top-1/2 -translate-y-1/2">
          <SearchIcon fill="#000" />
        </span>
      </form>
    </div>
  );
};

export default SearchInput;
