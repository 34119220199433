import React, { useState } from "react";
import { Link } from "react-router-dom";
import { LeftChevron } from "../../assets/Icons";
import Button from "../common/Button";
import Input from "../form/input";
import { useDispatch } from "react-redux";
import { regEx } from "../../utils/helper";
import { useRegisterMutation } from "../../apis/authApi";
import { addNotification } from "../../store/slices/toaster-slice";
import DropDown from "../common/Dropdown";

const validateRegForm = (data) => {
  let errors = {};
  let isValid = true;
  if (!data?.firstName) {
    errors.firstName = "First name is required";
    isValid = false;
  } else if (!regEx.NAME.test(data?.firstName)) {
    errors.firstName = "Invalid first name";
    isValid = false;
  }
  if (!data?.lastName) {
    errors.lastName = "Last name is required";
    isValid = false;
  } else if (!regEx.NAME.test(data?.lastName)) {
    errors.lastName = "Invalid last name";
    isValid = false;
  }
  if (!data?.email) {
    errors.email = "Email is required";
    isValid = false;
  } else if (!regEx.EMAIL.test(data?.email)) {
    errors.email = "Please enter valid email address";
    isValid = false;
  }
  if (!data?.contact) {
    errors.contact = "Contact is required";
    isValid = false;
  } else if (!regEx.PHONE.test(data?.contact)) {
    errors.contact = "Please enter valid contact number";
    isValid = false;
  }
  if (!data?.username) {
    errors.username = "Username name is required";
    isValid = false;
  }
  if (!data?.title) {
    errors.title = "Title is required";
    isValid = false;
  }
  if (!data?.password) {
    errors.password = "Password is required";
    isValid = false;
  } else if (!regEx.PASSWORD.test(data?.password)) {
    errors.password =
      "Password must be between 8 to 15 characters, should contain at least one lowercase letter, one uppercase letter, one digit, and one special character";
    isValid = false;
  }

  return { isValid, errors };
};

const initialState = {
  username: "",
  title: "",
  firstName: "",
  lastName: "",
  email: "",
  contact: "",
  user_type: "health_worker",
  password: "",
};

const RegisterHealthWorker = ({
  handleSteps,
  accountType,
  handleRegisterData,
}) => {
  const [formData, setFormData] = useState(initialState);
  const [formError, setFormError] = useState({});
  const [registerUser, registerUserLoading] = useRegisterMutation();
  const dispatch = useDispatch();

  // Function to handle change

  const handleTitleChange = (name, value) => {
    setFormData((prevObj) => ({
      ...prevObj,
      [name]: value,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((state) => ({ ...state, [name]: value }));
    setFormError((state) => ({ ...state, [name]: "" }));
  };

  // Handle register user
  const handleRegisterUser = async () => {
    let response;
    try {
      let userData = {
        userName: formData.username,
        title: formData.title.value,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        contact: `+256${formData.contact}`,
        userType: "health_worker",
        password: formData.password,
      };
      response = await registerUser(userData).unwrap();
      sessionStorage.setItem("userID", response?.result?.id);
      dispatch(
        addNotification({ type: "success", message: "Successfully Register" })
      );
      handleRegisterData(response);
      setFormData(initialState);
      handleSteps("selectHospital", "");
    } catch (error) {
      dispatch(
        addNotification({
          type: "error",
          message: error?.data?.result,
        })
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { isValid, errors } = validateRegForm(formData);
    setFormError(errors);

    if (isValid) {
      setFormError({});
      handleRegisterUser();
    }
  };

  const NameTitle = [
    {
      label: "Dr.",
      value: "Dr.",
    },
    { label: "Nurse", value: "nurse" },
    { label: "Midwife", value: "midwife" },
  ];

  return (
    <div className="choose-account lg:pb-8 xl:pt-[100px] xl:px-[50px] lg:pt-[70px] lg:px-[40px] md:p-8 p-5 md:pt-[70px] pt-[70px] flex flex-col h-full max-h-[calc(100%-44px)] relative">
      <div
        className="absolute sm:left-6 left-4 top-6 cursor-pointer flex items-center gap-2"
        onClick={() => handleSteps("health_worker", "")}
      >
        <LeftChevron />{" "}
        <span className="text-base text-color_758092">Back</span>
      </div>
      <div className="lg:max-w-[450px] flex flex-col justify-between flex-1">
        <div className="flex-1">
          <div>
            <h3 className="xl:text-3xl lg:text-2xl md:text-xl text-lg h-inter font-bold">
              Register to Healthcare Provider Account!
            </h3>
            <p className="text-color_8692A6 xl:text-lg md:text-base text-sm pt-4 h-inter">
              For the purpose of MedRef regulation, your details are required.
            </p>
          </div>
          <div className="pt-4 border-t border-[#F5F5F5] mt-[14px]">
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col lg:gap-6 gap-4">
                <div
                  className={`input-wrapper flex flex-col w-full ${
                    formError?.title ? "error" : ""
                  } `}
                >
                  <label className="input-label mb-2.5">
                    Title
                  </label>
                  <DropDown
                    label="Title"
                    options={NameTitle}
                    inputWidth={"100%"}
                    isMultiSelected={false}
                    placeholder="Select title"
                    onSelectChange={(value) =>
                      handleTitleChange("title", value)
                    }
                    selectedValue={formData?.title}
                  />
                  {formError?.title !== null && (
                    <p className="error-text">
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M8 12.173a0.591 0.591 0 1 0 0 -1.182 0.591 0.591 0 0 0 0 1.182Zm0 -2.123a0.444 0.444 0 0 1 -0.445 -0.445v-5.333a0.444 0.444 0 1 1 0.889 0v5.333a0.444 0.444 0 0 1 -0.444 0.444Z"
                            fill="red"
                          />
                          <path
                            d="M8 15.111a7.112 7.112 0 1 1 0 -14.222 7.112 7.112 0 0 1 0 14.222Zm0 -13.333a6.222 6.222 0 1 0 0 12.445 6.222 6.222 0 0 0 0 -12.445Z"
                            fill="red"
                          />
                        </svg>
                      </span>
                      {formError?.title}
                    </p>
                  )}
                </div>
                <Input
                  label="First name"
                  placeholder={"Enter your first Name"}
                  type="text"
                  className="border border-color_758092 h-[40px] rounded-[6px] px-4 py-2"
                  name="firstName"
                  changeEvent={handleChange}
                  isErrorMsg={formError?.firstName}
                  attributes={{ value: formData?.firstName }}
                  isFocus={true}
                />

                <Input
                  label="Last name"
                  placeholder={"Enter your last Name"}
                  type="text"
                  className="border border-color_758092 h-[40px] rounded-[6px] px-4 py-2"
                  name="lastName"
                  changeEvent={handleChange}
                  isErrorMsg={formError?.lastName}
                  attributes={{ value: formData?.lastName }}
                />
                <Input
                  label="Email"
                  placeholder={"Enter your email"}
                  type="email"
                  className="border border-color_758092 h-[40px] rounded-[6px] px-4 py-2"
                  name="email"
                  changeEvent={handleChange}
                  isErrorMsg={formError?.email}
                  attributes={{ value: formData?.email }}
                />
                <Input
                  label="Contact No"
                  placeholder={"Enter your contact"}
                  type="tel"
                  className="border border-color_758092 h-[40px] rounded-[6px] px-4 py-2"
                  name="contact"
                  changeEvent={handleChange}
                  isErrorMsg={formError?.contact}
                  attributes={{ maxvalue: 10, value: formData?.contact }}
                />
                <Input
                  label="User name"
                  placeholder={"Enter your username"}
                  type="text"
                  className="border border-color_758092 h-[40px] rounded-[6px] px-4 py-2"
                  name="username"
                  changeEvent={handleChange}
                  isErrorMsg={formError?.username}
                  attributes={{ value: formData?.username }}
                />
                <Input
                  label="Password"
                  placeholder={"Enter your password"}
                  type="password"
                  className="border border-color_758092 h-[40px] rounded-[6px] px-4"
                  name="password"
                  changeEvent={handleChange}
                  isErrorMsg={formError?.password}
                  attributes={{ value: formData?.password }}
                />
                <p className="md:text-sm text-xs text-[#989898]">
                  By continuing you agree to our{" "}
                  <Link to="" className="text-primary">
                    Terms of use
                  </Link>{" "}
                  and our{" "}
                  <Link to="" className="text-primary">
                    Privacy Policy
                  </Link>
                </p>
              </div>
              <div className="lg:mt-9 mt-6">
                <Button
                  type="submit"
                  btnType="normal"
                  isLoading={registerUserLoading?.isLoading}
                >
                  Register
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterHealthWorker;
