import React, { useState } from "react";
import { InfoIcon } from "../../assets/Icons";
import { formatDate } from "../../utils/helper";
import Popup from "./Popup";
import ViewVaccinationDetailsPopup from "../childRegister/ViewVaccinationDetailsPopup";

const VaccinatedChildCard = ({ data }) => {
  const [popupStatus, setPopupStatus] = useState(false);

  const handleVaccinesPopup = () => {
    setPopupStatus(!popupStatus);
  };
  return (
    <div className="bg-white rounded p-5 flex midLap:items-center justify-between shadow-cardShadow2 w-full">
      <div className="flex midLap:gap-1.5 max-midLap:flex-col md:gap-5 gap-3">
        <div className="md:w-[50px] md:h-[50px] w-[30px] h-[30px] rounded-full bg-success">
          {/* <img src="" alt="" /> */}
        </div>
        <div>
          <h5 className="text-xs text-font capitalize">{data?.name}</h5>
          <p className="text-[10px] pt-2 text-font flex gap-0.5">
            <span className="font-semibold">Child No:</span>
            <span className="font-normal">
              {data?.childNo ?? "---"}
            </span>
          </p>
          <div className="pt-2 flex sm:items-center sm:gap-4 gap-2 text-[10px] text-font max-sm:flex-wrap">
            <div className="flex gap-0.5">
              <span className="font-semibold">Mother no: </span>
              <span className="font-normal">
                {data?.motherContact ?? "---"}
              </span>
            </div>
            <div className="flex gap-0.5">
              <span className="font-semibold">Age: </span>
              <span className="font-normal">{data?.age ?? "---"}</span>
            </div>
            <div className="flex gap-0.5">
              <span className="font-semibold">Gender: </span>
              <span className="font-normal">{data?.gender ?? "---"}</span>
            </div>
          </div>
          {/* <p className="text-[10px] pt-2 text-success flex gap-1.5 items-center">
            <span>Vaccinated</span>
            <span>
              {data?.vaccines?.[0]?.date
                ? formatDate(data?.vaccines?.[0]?.date, "/")
                : "---"}
            </span>
          </p> */}
        </div>
      </div>
      <div className="flex gap-1 flex-col">
        {/* <span className="border border-color_758092 rounded-full w-[22px] h-[22px] flex items-center justify-center cursor-pointer">
          <EditIcon width="12px" height="12px" fill="#263A43" />
        </span> */}
        <span
          className="border border-color_758092 rounded-full w-[22px] h-[22px] flex items-center justify-center cursor-pointer"
          onClick={handleVaccinesPopup}
        >
          <InfoIcon width="12px" height="12px" fill="#263A43" />
        </span>
        {/* <span className="border border-color_758092 rounded-full w-[22px] h-[22px] flex items-center justify-center cursor-pointer">
          <RemainderIcon width="12px" height="12px" fill="#263A43" />
        </span> */}
      </div>

      {popupStatus && (
        <Popup
          title="Child Vaccinated Details"
          width="400px"
          closeBtn={true}
          closeHandler={handleVaccinesPopup}
        >
          <ViewVaccinationDetailsPopup
            closePopup={handleVaccinesPopup}
            vaccines={data?.vaccinestaken}
          />
        </Popup>
      )}
    </div>
  );
};

export default VaccinatedChildCard;
