import React, { useEffect, useState } from "react";
import MinistryLabel from "../../components/common/MinistryLabel/MinistryLabel";
import Input from "../../components/form/input/index";
import { SearchIcon } from "../../assets/Icons";
import { useSearchOtherChildMutation } from "../../apis/authApi";
import { addNotification } from "../../store/slices/toaster-slice";
import { useDispatch } from "react-redux";
import Button from "../../components/common/Button";
import SearchInput from "../../components/common/searchInput/searchInput";
import TableNoDataFound from "../../components/common/loader/TableNoDataFound";
import { Link } from "react-router-dom";
import TableLoader from "../../components/common/loader/TableLoader";
import CustomPagination from "../../components/common/pagination/CustomPagination";

const SearchOtherHFChild = () => {
  const [getChild, childLoading] = useSearchOtherChildMutation();
  const [searchValue, setSearchValue] = useState("");
  const [childList, setChildList] = useState([]);
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();

  useEffect(() => {
    handleGetChildData();
  }, [page, searchValue]);

  const handleGetChildData = async () => {
    try {
      const query = {
        text: searchValue,
        page: page,
      };
      const response = await getChild(query).unwrap();
      setChildList(response?.result ?? "");
    } catch (error) {
      dispatch(addNotification({ type: "error", message: error?.data?.error }));
    }
  };

  return (
    <div className="xl:px-[35px] xl:py-12 lg:px-5 lg:py-7 p-4 child-vaccination">
      <div className="flex gap-3 justify-between md:items-end max-md:flex-col">
        <div>
          <MinistryLabel title="Search Child of Any Health Facility" />
        </div>
      </div>
      <div className="my-6 flex md:items-center md:justify-between max-md:flex-col gap-4">
        <div className="flex items-center">
          <SearchInput
            setSearchValue={setSearchValue}
            placeholder="Search by child"
          />
        </div>
      </div>
      <div className="mt-8 bg-white rounded-xl overflow-auto ">
        <div className="primary-table">
          <table className="">
            <thead>
              <tr>
                <td>#</td>
                <td>Child No.</td>
                <td>Child Name</td>
                <td>Mother's Name</td>
                <td>Contact</td>
                <td>Address (village & Sub-County)</td>
              </tr>
            </thead>
            <tbody>
              {childLoading?.isLoading ? (
                <TableLoader />
              ) : childList?.childrenData?.length > 0 ? (
                childList?.childrenData.map((list, index) => {
                  return (
                    <tr key={list?.id}>
                      <td>{index + 1}</td>
                      <td className="text-base">
                        <Link
                          to={`/vaccination-card/${list?.childNo}`}
                          className="text-primary"
                        >
                          {list?.childNo}
                        </Link>
                      </td>
                      <td>{list?.name}</td>
                      <td>{list?.parent?.motherName}</td>
                      <td>{list?.parent?.motherContact}</td>
                      <td>
                        {list?.address?.village}, {list?.address?.subCounty}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <TableNoDataFound />
              )}
            </tbody>
          </table>
        </div>
      </div>
      {childList?.page?.total > 10 && (
        <CustomPagination
          setPage={setPage}
          page={page}
          count={childList?.page?.total}
        />
      )}
    </div>
  );
};

export default SearchOtherHFChild;
