import React, { useState } from "react";
import { Logo } from "../../assets/Icons";
import AccountFooterLink from "../../components/register/AccountFooterLink";
import AccountType from "../../components/register/AccountType";
import ChooseAccount from "../../components/register/ChooseAccount";
import RegisterHealthWorker from "../../components/register/RegisterHealthWorker";
import SelectHealthFacility from "../../components/register/SelectHealthFacility";
import { useLocation, useParams } from "react-router-dom";

const Register = ({ initialStep = "chooseAccount" }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const loginStep = searchParams.get("step");
  const pendingHospital = searchParams.get("hospital");
  const [step, setStep] = useState(loginStep ? loginStep : initialStep);
  const [accountType, setAccountType] = useState("child");
  const [registerData, setRegisterData] = useState({});

  const handleSteps = (registerStep, value) => {
    if (value === "health_worker") {
      setStep("health_worker");
    } else {
      setStep(registerStep);
    }
  };

  const handleAccountType = (account) => {
    setAccountType(account);
  };

  const handleRegisterData = (data) => {
    setRegisterData(data);
  };

  return (
    <div className="register-page">
      <div className="flex min-h-screen lg:flex-row flex-col">
        <div className="basis-1/2 bg-cover bg-center bg-no-repeat bg-account-banner flex items-center justify-center max-h-screen sticky top-0 left-0 max-lg:hidden">
          <div className="flex flex-col gap-6 account-logo">
            <Logo />
            <h2 className="xl:text-[35px] lg:text-[28px] text-black">
              Welcome to MedRef
            </h2>
          </div>
        </div>

        <div className="block lg:hidden pt-8">
          <div className="flex flex-col gap-6 items-center account-logo">
            <Logo />
            <h2 className="text-2xl text-black">Welcome to MedRef</h2>
          </div>
        </div>
        <div className="lg:basis-1/2 basis-full flex-1">
          {step === "chooseAccount" && (
            <ChooseAccount handleSteps={handleSteps} />
          )}
          {step === "health_worker" && (
            <AccountType
              handleSteps={handleSteps}
              handleAccountType={handleAccountType}
              accountType={accountType}
              pendingHospital={pendingHospital && pendingHospital}
            />
          )}
          {step === "registerForm" && (
            <RegisterHealthWorker
              handleSteps={handleSteps}
              accountType={accountType}
              handleRegisterData={handleRegisterData}
            />
          )}
          {step === "selectHospital" && (
            <SelectHealthFacility
              accountType={accountType}
              registerData={registerData}
            />
          )}

          <AccountFooterLink />
        </div>
      </div>
    </div>
  );
};

export default Register;
