import { ResponsiveBar } from "@nivo/bar";
import React from "react";
import useMediaQuery from "../common/mediaQuery/MediaQueryHook";

const ChildDashboardChart = ({ data }) => {
  const customColors = {
    vaccinated: "#7161EF",
    defaulter: "#EF6161",
  };
  const isLargeScreen = useMediaQuery("(min-width: 768px)");
  if (Array.isArray(data)) {
    return (
      <div style={{ height: "450px" }}>
        <ResponsiveBar
          data={data}
          keys={["vaccinated", "defaulter"]}
          indexBy="time"
          margin={{
            top: 30,
            right: 20,
            bottom: 50,
            left: isLargeScreen ? 40 : 60,
          }}
          padding={0.3}
          groupMode="grouped"
          layout={isLargeScreen ? "vertical" : "horizontal"}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={(bar) => customColors[bar.id]}
          axisTop={null}
          axisRight={null}
          borderRadius={4}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -70,
            legendOffset: 32,
            style: {
              fontSize: 9,
            },
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor="#fff"
          role="application"
        />
      </div>
    );
  }
};

export default ChildDashboardChart;
