import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  AddIcon,
  DefaulterIcon,
  HomeIcon,
  InfoIcon,
  RegisterIcon,
  ScheduleIcon,
} from "../../../assets/Icons";

const HPVMenu = () => {
  const { pathname } = useLocation();

  return (
    <>
      <ul className="menu">
        <li>
          <Link
            to="/dashboard"
            className={`menu-link ${pathname === "/dashboard" && "active"}`}
          >
            <span>
              <HomeIcon />
            </span>
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/hpv-register"
            className={`menu-link ${pathname === "/hpv-register" && "active"}`}
          >
            <span>
              <RegisterIcon />
            </span>
            HPV Register Book
          </Link>
        </li>
        <li>
          <Link
            to="/add-patient"
            className={`menu-link ${pathname === "/add-patient" && "active"}`}
          >
            <span className="">
              <AddIcon />
            </span>
            Add New HPV
          </Link>
        </li>
        <li>
          <Link
            to="/vaccination-card"
            className={`menu-link ${
              pathname.includes("/vaccination-card") && "active"
            }`}
          >
            <span className="relative -top-1">
              <InfoIcon />
            </span>
            HPV Vaccine Card
          </Link>
        </li>
        <li>
          <Link
            to="/vaccination-appointments"
            className={`menu-link ${
              pathname === "/vaccination-appointments" && "active"
            }`}
          >
            <span>
              <ScheduleIcon />
            </span>
            HPV Vaccine Appointments
          </Link>
        </li>
        <li>
          <Link
            to="/vaccination-defaulters"
            className={`menu-link ${
              pathname === "/vaccination-defaulters" && "active"
            }`}
          >
            <span>
              <DefaulterIcon />
            </span>
            HPV Vaccine Defaulter’s
          </Link>
        </li>
      </ul>
    </>
  );
};

export default HPVMenu;
