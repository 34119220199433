import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../utils/helper";

// let API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
let API_BASE_URL = BASE_URL;

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_BASE_URL}`,
    prepareHeaders: (headers, { getState }) => {
      let token = sessionStorage.getItem("access_token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: `/user/login`,
        method: "POST",
        body: data,
      }),
    }),
    forgetPassword: builder.mutation({
      query: (data) => ({
        url: `/user/forgot-password`,
        method: "POST",
        body: data,
      }),
    }),
    verifyOTP: builder.mutation({
      query: (data) => ({
        url: `/user/verify-otp`,
        method: "POST",
        body: data,
      }),
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: `/user/reset-password`,
        method: "POST",
        body: data,
      }),
    }),
    registerHealthWorker: builder.mutation({
      query: (data) => ({
        url: `/user/register/hw`,
        method: "POST",
        body: data,
      }),
    }),
    register: builder.mutation({
      query: (data) => ({
        url: `/user/register`,
        method: "POST",
        body: data,
      }),
    }),
    getUserDetails: builder.mutation({
      query: () => ({
        url: `/user/getuserdata`,
        method: "GET",
      }),
    }),
    getDistricts: builder.mutation({
      query: () => ({
        url: `/user/hospitals/districts`,
        method: "GET",
      }),
    }),
    getSubCounties: builder.mutation({
      query: (param) => ({
        url: `/user/hospitals/sub-counties`,
        params: param,
        method: "GET",
      }),
    }),
    getHospitals: builder.mutation({
      query: (param) => ({
        url: `/user/hospitals`,
        params: param,
        method: "GET",
      }),
    }),
    getDashboardVaccinatedCounts: builder.mutation({
      query: (data) => ({
        url: `/child/dashboard/vaccinated`,
        method: "POST",
        body: data,
      }),
    }),
    getChildDashboardVaccinated: builder.mutation({
      query: (data) => ({
        url: `/child/dashboard/vaccinated-cards`,
        method: "POST",
        body: data,
      }),
    }),
    getChildDashboardDefaulters: builder.mutation({
      query: (data) => ({
        url: `/child/dashboard/defaulter-cards`,
        method: "POST",
        body: data,
      }),
    }),
    getChildDashboardChart: builder.mutation({
      query: (data) => ({
        url: `/child/dashboard/vaccinatedvsdefaulted`,
        method: "POST",
        body: data,
      }),
    }),
    getChildList: builder.mutation({
      query: (query) => ({
        url: `/child/list`,
        params: query,
        method: "GET",
      }),
    }),
    getChildListWithCid: builder.mutation({
      query: (childNo) => ({
        url: `/child/vaccination-card/${childNo}`,
        // params: param,
        method: "GET",
      }),
    }),
    searchOtherChild: builder.mutation({
      query: (query) => ({
        url: `/child/search`,
        params: query,
        method: "GET",
      }),
    }),
    getSpecialCare: builder.mutation({
      query: () => ({
        url: `/child/specialcare`,
        method: "GET",
      }),
    }),
    registerChild: builder.mutation({
      query: (data) => ({
        url: `/child/register`,
        method: "POST",
        body: data,
      }),
    }),
    getVaccinationAppointments: builder.mutation({
      query: (query) => ({
        url: `/child/appointments`,
        params: query,
        method: "GET",
      }),
    }),
    getVaccinationDefaulters: builder.mutation({
      query: (query) => ({
        url: `/child/defaulters`,
        params: query,
        method: "GET",
      }),
    }),

    // For Vaccination
    downloadCard: builder.mutation({
      query: (childNo) => ({
        url: `/child/download/vaccinationcard/${childNo}`,
        method: "GET",
      }),
    }),
    updateChildContact: builder.mutation({
      query: ({ query, contactData }) => ({
        url: `/child/update-contact/${query.childId}`,
        method: "PUT",
        body: contactData,
      }),
    }),
    getChildVaccinesForTable: builder.mutation({
      query: () => ({
        url: `/vaccines/vaccine-list/`,
        method: "GET",
      }),
    }),
    getChildVaccinesWithCid: builder.mutation({
      query: (param) => ({
        url: `/child/vaccine-list`,
        params: param,
        method: "GET",
      }),
    }),
    AddVaccinationRecord: builder.mutation({
      query: (data) => ({
        url: `/child/vaccines/record`,
        method: "POST",
        body: data,
      }),
    }),
    getChildNutritionForTable: builder.mutation({
      query: () => ({
        url: `/vaccines/nutrition-list`,
        method: "GET",
      }),
    }),
    getChildNutritionWithCid: builder.mutation({
      query: (param) => ({
        url: `/child/nutrition-list`,
        params: param,
        method: "GET",
      }),
    }),
    AddNutritionRecord: builder.mutation({
      query: (data) => ({
        url: `/child/nutritions/record`,
        method: "POST",
        body: data,
      }),
    }),
    SendReminderToDefaulter: builder.mutation({
      query: (childNo) => ({
        url: `/child/send/reminder/${childNo}`,
        method: "GET",
      }),
    }),

    // For TD
    registerTD: builder.mutation({
      query: (data) => ({
        url: `/TD/add-patient`,
        method: "POST",
        body: data,
      }),
    }),
    getTDRegisterBook: builder.mutation({
      query: (query) => ({
        url: `/TD/td-register-book`,
        params: query,
        method: "GET",
      }),
    }),
    getTDVaccinationCard: builder.mutation({
      query: (query) => ({
        url: `/TD/vaccination-card`,
        params: query,
        method: "GET",
      }),
    }),
    AddTDDoseRecord: builder.mutation({
      query: (data) => ({
        url: `/TD/updateDoseData`,
        method: "PUT",
        body: data,
      }),
    }),
    getTDDoseData: builder.mutation({
      query: (query) => ({
        url: `/TD/getDoseData`,
        params: query,
        method: "GET",
      }),
    }),
    getTDVaccinationAppointments: builder.mutation({
      query: (query) => ({
        url: `/TD/client-appointments`,
        params: query,
        method: "GET",
      }),
    }),
    getTDVaccinationDefaulters: builder.mutation({
      query: (query) => ({
        url: `/TD/client-defaulters`,
        params: query,
        method: "GET",
      }),
    }),
    getTDDashboardVaccinatedCounts: builder.mutation({
      query: (data) => ({
        url: `/TD/dashboard/vaccinated`,
        method: "POST",
        body: data,
      }),
    }),
    getTDDashboardVaccinated: builder.mutation({
      query: (data) => ({
        url: `/TD/dashboard/vaccinated-cards`,
        method: "POST",
        body: data,
      }),
    }),
    getTDDashboardDefaulters: builder.mutation({
      query: (data) => ({
        url: `/TD/dashboard/defaulter-cards`,
        method: "POST",
        body: data,
      }),
    }),
    getTDDashboardChart: builder.mutation({
      query: (data) => ({
        url: `/TD/dashboard/vaccinatedvsdefaulted`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useForgetPasswordMutation,
  useVerifyOTPMutation,
  useResetPasswordMutation,
  useRegisterHealthWorkerMutation,
  useRegisterMutation,
  useGetUserDetailsMutation,
  useGetDistrictsMutation,
  useGetSubCountiesMutation,
  useGetHospitalsMutation,
  useGetDashboardVaccinatedCountsMutation,
  useGetChildDashboardChartMutation,
  useGetChildDashboardVaccinatedMutation,
  useGetChildDashboardDefaultersMutation,
  useGetChildListMutation,
  useGetChildListWithCidMutation,
  useSearchOtherChildMutation,
  useGetSpecialCareMutation,
  useRegisterChildMutation,
  useGetVaccinationAppointmentsMutation,
  useAddVaccinationRecordMutation,
  useGetVaccinationDefaultersMutation,
  useDownloadCardMutation,
  useUpdateChildContactMutation,
  useGetChildVaccinesForTableMutation,
  useGetChildNutritionForTableMutation,
  useGetChildVaccinesWithCidMutation,
  useGetChildNutritionWithCidMutation,
  useAddNutritionRecordMutation,
  useSendReminderToDefaulterMutation,
  useRegisterTDMutation,
  useGetTDRegisterBookMutation,
  useGetTDVaccinationCardMutation,
  useAddTDDoseRecordMutation,
  useGetTDDoseDataMutation,
  useGetTDVaccinationAppointmentsMutation,
  useGetTDVaccinationDefaultersMutation,
  useGetTDDashboardChartMutation,
  useGetTDDashboardDefaultersMutation,
  useGetTDDashboardVaccinatedCountsMutation,
  useGetTDDashboardVaccinatedMutation,
} = authApi;
