import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import {
  useGetUserDetailsMutation
} from "../../apis/authApi";
import AddChild from "../../pages/childRegister/AddChild";
import ChildAppointments from "../../pages/childRegister/ChildAppointments";
import ChildDashboard from "../../pages/childRegister/ChildDashboard";
import ChildDefaulters from "../../pages/childRegister/ChildDefaulters";
import ChildRegister from "../../pages/childRegister/ChildRegister";
import ChildVaccinationCard from "../../pages/childRegister/ChildVaccinationCard";
import AddNewHPV from "../../pages/hpv/AddNewHPV";
import HPVAppointments from "../../pages/hpv/HPVAppointments";
import HPVDashboard from "../../pages/hpv/HPVDashboard";
import HPVDefaulters from "../../pages/hpv/HPVDefaulters";
import HPVRegister from "../../pages/hpv/HPVRegister";
import HPVVaccinationCard from "../../pages/hpv/HPVVaccinationCard";
import AddNewTD from "../../pages/td/AddNewTD";
import TDAppointments from "../../pages/td/TDAppointments";
import TDDashboard from "../../pages/td/TDDashboard";
import TDDefaulters from "../../pages/td/TDDefaulters";
import TDVaccinationCard from "../../pages/td/TDVaccinationCard";
import TdRegister from "../../pages/td/TdRegister";
import "../../scss/common/layout.scss";
import { addNotification } from "../../store/slices/toaster-slice";
import { setUserDetails } from "../../store/slices/userDetail-slice";
import Sidebar from "../form/Sidebar";
import AppHeader from "./AppHeader";
import SearchOtherHFChild from '../../pages/childRegister/SearchOtherHFChild';

const Layout = () => {
  const { userDetails } = useSelector((state) => state.user);
  const [menuOpen, setMenuOpen] = useState(false);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getUserDetails, userDetailsLoading] = useGetUserDetailsMutation();
  let accessToken = sessionStorage.getItem("access_token");

  useEffect(() => {
    if (pathname === "/") {
      navigate("/login");
    }
    if (accessToken) {
      handleUserDetails(accessToken);
    }
    if (pathname) {
      setMenuOpen(false);
    }
  }, [pathname]);

  useEffect(() => {
    if (!accessToken) {
      navigate("/login");
    }
  }, [accessToken]);

  const handleMenuStatus = () => {
    setMenuOpen(!menuOpen);
  };

  const handleUserDetails = async (token) => {
    try {
      const response = await getUserDetails(token).unwrap();
      dispatch(setUserDetails(response?.result));
    } catch (error) {
      if (error?.status == 401 || error?.status == 403) {
        sessionStorage.removeItem("access_token");
        navigate("/login");
        dispatch(
          addNotification({
            type: "error",
            message: "Token is invalid or expired",
          })
        );
      } else {
        dispatch(
          addNotification({ type: "error", message: "Unable to fetch!" })
        );
      }
    }
  };

  return (
    <div className="layout flex">
      <div
        className={`sidebar-wrapper min-h-screen lg:sticky top-0 left-0 min-w-[320px] max-h-screen z-[99] overflow-y-auto ${
          menuOpen && "open-menu"
        }`}
      >
        <Sidebar />
      </div>
      <div
        className={`bg-[#FAFAFA] flex-1 lg:max-w-[calc(100%-320px)] w-full `}
      >
        <div>
          <AppHeader mobileMenuHandler={handleMenuStatus} menuOpen={menuOpen} />
        </div>
        <div
          className="body-content h-full lg:max-h-[calc(100dvh-80px)] max-h-[calc(100dvh-72px)] lg:min-h-[calc(100dvh-80px)] min-h-[calc(100dvh-72px)] overflow-auto "
          onClick={() => {
            setMenuOpen(false);
          }}
        >
          {userDetails?.hwType === "child" && (
            <Routes>
              {/* Child Routes */}
              <Route path="/dashboard" element={<ChildDashboard />} />
              <Route path="/child-register" element={<ChildRegister />} />
              <Route path="/add-child" element={<AddChild />} />
              <Route
                path="/vaccination-card/:childNo?"
                element={<ChildVaccinationCard />}
              />
              <Route
                path="/search-child"
                element={<SearchOtherHFChild />}
              />
              
              <Route
                path="/vaccination-appointments"
                element={<ChildAppointments />}
              />
              <Route
                path="/vaccination-defaulters"
                element={<ChildDefaulters />}
              />
            </Routes>
          )}

          {/* TD Routes */}
          {userDetails?.hwType === "td" && (
            <Routes>
              <Route path="/dashboard" element={<TDDashboard />} />
              <Route path="/td-register" element={<TdRegister />} />
              <Route path="/add-patient" element={<AddNewTD />} />
              <Route
                path="/vaccination-card/:clientNo?"
                element={<TDVaccinationCard />}
              />
              <Route
                path="/vaccination-appointments"
                element={<TDAppointments />}
              />
              <Route
                path="/vaccination-defaulters"
                element={<TDDefaulters />}
              />
            </Routes>
          )}

          {/* HPV Routes */}
          {userDetails?.hwType === "hpv" && (
            <Routes>
              <Route path="/dashboard" element={<HPVDashboard />} />
              <Route path="/hpv-register" element={<HPVRegister />} />
              <Route path="/add-patient" element={<AddNewHPV />} />
              <Route
                path="/vaccination-card/:clientNo?"
                element={<HPVVaccinationCard />}
              />
              <Route
                path="/vaccination-appointments"
                element={<HPVAppointments />}
              />
              <Route
                path="/vaccination-defaulters"
                element={<HPVDefaulters />}
              />
            </Routes>
          )}
        </div>
      </div>
    </div>
  );
};

export default Layout;
