import React from "react";
import { NoDataIcon } from "../../../assets/Icons";

const TableNoDataFound = () => {
  return (
    <tr>
      <td colSpan={1000} className="!p-0 border-none">
        <span className="flex gap-2 items-center w-full text-center justify-center p-3">
          <NoDataIcon />
          <h4 className="lg:text-lg md:text-base text-sm font-semibold">
            No Data Found
          </h4>
        </span>
      </td>
    </tr>
  );
};

export default TableNoDataFound;
