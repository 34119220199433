import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useGetChildDashboardChartMutation,
  useGetChildDashboardDefaultersMutation,
  useGetChildDashboardVaccinatedMutation,
  useGetDashboardVaccinatedCountsMutation,
} from "../../apis/authApi";
import ChildDashboardChart from "../../components/childRegister/ChildDashboardChart";
import CustomDateRange from "../../components/common/CustomDateRange";
import DefaulterChildCard from "../../components/common/DefaulterChildCard";
import MinistryLabel from "../../components/common/MinistryLabel/MinistryLabel";
import VaccinatedChildCard from "../../components/common/VaccinatedChildCard";
import ComponentLoader from "../../components/common/loader/ComponentLoader";
import NoDataFound from "../../components/common/loader/NoDataFound";
import CustomPagination from "../../components/common/pagination/CustomPagination";
import "../../scss/childRegister/childDashboard.scss";
import { addNotification } from "../../store/slices/toaster-slice";
import { calculateDateDiff } from "../../utils/helper";

const today = new Date();
const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

const ChildDashboard = () => {
  const [countsData, setCountsData] = useState({});
  const [vaccinatedData, setVaccinatedData] = useState({});
  const [defaulterData, setDefaulterData] = useState({});
  const [dateRange, setDateRange] = useState({
    from: firstDayOfMonth,
    to: lastDayOfMonth,
  });
  const [getDefaulter, defaulterLoading] =
    useGetChildDashboardDefaultersMutation();
  const [getVaccinatedCounts, vaccinatedCountsLoading] =
    useGetDashboardVaccinatedCountsMutation();
  const [dashboardChart, dashboardChartLoading] =
    useGetChildDashboardChartMutation();
  const [getVaccinatedChild, vaccinatedChildLoading] =
    useGetChildDashboardVaccinatedMutation();
  const [dashboardChartData, setDashboardChartData] = useState({});
  const [vaccinatedPage, setVaccinatedPage] = useState(1);
  const [defaulterPage, setDefaulterPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getDashboardVaccinatedCounts();
    getDashboardChartData();
    getVaccinatedChildLists();
    getDefaulterChildLists();
  }, [dateRange]);

  const getDashboardVaccinatedCounts = async () => {
    try {
      let data = {
        from: dateRange?.from || firstDayOfMonth,
        to: dateRange?.to || lastDayOfMonth,
      };
      let response = await getVaccinatedCounts(data).unwrap();
      setCountsData(response?.result);
    } catch (error) {
      if (error?.status == 401 || error?.status == 403) {
        sessionStorage.removeItem("access_token");
        navigate("/login");
        dispatch(
          addNotification({
            type: "error",
            message: "Token is invalid or expired",
          })
        );
      } else {
        dispatch(
          addNotification({ type: "error", message: "Unable to fetch!" })
        );
      }
    }
  };

  const setType = (data) => {
    let type = "date";

    const diff = calculateDateDiff(data.from, data.to);
    if (diff === 0) {
      type = "hour";
    } else if (diff < 7) {
      type = "day";
    } else if (diff < 31) {
      type = "date";
    } else {
      type = "month";
    }

    return type;
  };

  const getDashboardChartData = async () => {
    try {
      let type = setType({
        from: dateRange?.from || firstDayOfMonth,
        to: dateRange?.to || lastDayOfMonth,
      });
      let data = {
        from: dateRange?.from || firstDayOfMonth,
        to: dateRange?.to || lastDayOfMonth,
        type: type,
      };
      let response = await dashboardChart(data).unwrap();
      setDashboardChartData(response?.result?.formattedData);
    } catch (error) {
      if (error?.status == 401 || error?.status == 403) {
        sessionStorage.removeItem("access_token");
        navigate("/login");
        dispatch(
          addNotification({
            type: "error",
            message: "Token is invalid or expired",
          })
        );
      } else {
        dispatch(
          addNotification({ type: "error", message: "Unable to fetch!" })
        );
      }
    }
  };

  useEffect(() => {
    getVaccinatedChildLists()
  }, [vaccinatedPage]);
  
  const getVaccinatedChildLists = async () => {
    try {
      let data = {
        from: dateRange?.from || firstDayOfMonth,
        to: dateRange?.to || lastDayOfMonth,
        page: vaccinatedPage,
      };
      let response = await getVaccinatedChild(data).unwrap();
      setVaccinatedData(response?.result);
    } catch (error) {
      if (error?.status == 401 || error?.status == 403) {
        sessionStorage.removeItem("access_token");
        navigate("/login");
        dispatch(
          addNotification({
            type: "error",
            message: "Token is invalid or expired",
          })
        );
      } else {
        dispatch(
          addNotification({ type: "error", message: "Unable to fetch!" })
        );
      }
    }
  };

  useEffect(() => {
    getDefaulterChildLists()
  }, [defaulterPage]);
  const getDefaulterChildLists = async () => {
    try {
      let data = {
        from: dateRange?.from || firstDayOfMonth,
        to: dateRange?.to || lastDayOfMonth,
        page: defaulterPage,
      };
      let response = await getDefaulter(data).unwrap();
      setDefaulterData(response?.result);
    } catch (error) {
      if (error?.status == 401 || error?.status == 403) {
        sessionStorage.removeItem("access_token");
        navigate("/login");
        dispatch(
          addNotification({
            type: "error",
            message: "Token is invalid or expired",
          })
        );
      } else {
        dispatch(
          addNotification({ type: "error", message: "Unable to fetch!" })
        );
      }
    }
  };

  return (
    <div className="child-dashboard xl:px-[35px] xl:py-12 lg:px-5 lg:py-7 p-4">
      <div className="flex gap-3 justify-between md:items-end max-md:flex-col">
        <div>
          <MinistryLabel title="Home" />
        </div>
      </div>
      <div className="mt-5">
        <CustomDateRange setDateRange={setDateRange} />
      </div>
      <div className="pt-6 flex gap-6 max-lg:flex-col">
        <div className="flex flex-col gap-4 lg:w-1/3">
          <div className="cards">
            <div className="card">
              <p className="md:text-sm text-xs text-[#000000B2] font-medium">
                Static Vaccinated (Dose)
              </p>
              <p className="xl:pt-6 lg:pt-4 pt-2 xl:text-2xl lg:text-lg md:text-base text-sm text-font font-bold">
                {countsData?.staticVaccinated ?? 0}
              </p>
            </div>
            <div className="card">
              <p className="md:text-sm text-xs text-[#000000B2] font-medium">
                Out- Reached Vaccinated (Dose)
              </p>
              <p className="xl:pt-6 lg:pt-4 pt-2 xl:text-2xl lg:text-lg md:text-base text-sm text-font font-bold">
                {countsData?.outReachedVaccinated ?? 0}
              </p>
            </div>
            <div className="card">
              <p className="md:text-sm text-xs text-[#000000B2] font-medium">
                Total Vaccinated (Dose)
              </p>
              <p className="xl:pt-6 lg:pt-4 pt-2 xl:text-2xl lg:text-lg md:text-base text-sm text-font font-bold">
                {countsData?.totalVaccinated ?? 0}
              </p>
            </div>
            {/* <div className="card chart-card">
              <p className="md:text-sm text-xs text-[#000000B2] font-medium">
                Child Vaccine Appointments
              </p>
              <p className="xl:pt-6 lg:pt-4 pt-2 xl:text-2xl lg:text-lg md:text-base text-sm text-font font-bold">
                {countsData?.appointments ? countsData?.appointments : 0}
              </p>
            </div> */}
            <div className="card chart-card">
              <p className="md:text-sm text-xs text-[#000000B2] font-medium">
                Child Vaccine Defaulters
              </p>
              <p className="xl:pt-6 lg:pt-4 pt-2 xl:text-2xl lg:text-lg md:text-base text-sm text-font font-bold">
                {countsData?.defaultedvaccines ?? 0}
              </p>
            </div>
          </div>
        </div>
        <div className="flex-1 lg:w-2/3 min-h-[100px] bg-white">
          {dashboardChartLoading.isLoading ? (
            <div className="skeleton-box w-full h-full" />
          ) : (
            <ChildDashboardChart data={dashboardChartData} />
          )}
        </div>
      </div>

      <div className="lg:mt-8 mt-6 md:p-6 p-4 bg-white rounded-[20px] border border-stroke_color">
        <div className="heading mb-5">
          <h4 className="lg:text-lg text-base font-semibold text-black">
            Vaccinated Children’s
          </h4>
        </div>
        <div className="flex xl:gap-10 md:gap-7 gap-5 max-md:flex-col flex-wrap">
          {vaccinatedChildLoading.isLoading ? (
            <ComponentLoader />
          ) : vaccinatedData?.vaccinatedChild?.length ? (
            vaccinatedData?.vaccinatedChild.map((child, index) => {
              return (
                <div
                  className="xl:basis-[calc(50%-20px)] md:basis-[calc(50%-14px)]"
                  key={index}
                >
                  <VaccinatedChildCard data={child} />
                </div>
              );
            })
          ) : (
            <NoDataFound />
          )}
        </div>
        {vaccinatedData?.page?.total > 6 && (
          <CustomPagination
            setPage={setVaccinatedPage}
            page={vaccinatedPage}
            count={vaccinatedData?.page?.total}
            limit={6}
          />
        )}
      </div>
      <div className="lg:mt-8 mt-6 md:p-6 p-4 bg-white rounded-[20px] border border-stroke_color">
        <div className="heading mb-5">
          <h4 className="lg:text-lg text-base font-semibold text-black">
            Defaulter’s List
          </h4>
        </div>
        <div className="flex xl:gap-10 md:gap-7 gap-5 max-md:flex-col flex-wrap">
          {defaulterLoading.isLoading ? (
            <ComponentLoader />
          ) : defaulterData?.defaultedChild?.length ? (
            defaulterData?.defaultedChild.map((child, index) => {
              return (
                <div
                  className="xl:basis-[calc(50%-20px)] md:basis-[calc(50%-14px)]"
                  key={index}
                >
                  <DefaulterChildCard data={child} />
                </div>
              );
            })
          ) : (
            <NoDataFound />
          )}
        </div>
        {defaulterData?.page?.total > 6 && (
          <CustomPagination
            setPage={setDefaulterPage}
            page={defaulterPage}
            count={defaulterData?.page?.total}
            limit={6}
          />
        )}
      </div>
    </div>
  );
};

export default ChildDashboard;
