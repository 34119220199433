import React from "react";
import { formatDate, formatDateAsscen } from "../../utils/helper";

const ViewDefaulterDetailsPopup = ({ closePopup, vaccines }) => {
  return (
    <div className="max-h-[300px] overflow-y-auto">
      <table className="w-full">
        <thead className="border-b border-[rgba(0, 0, 0, 0.2)]">
          <tr>
            <td className="py-1.5 px-3 font-medium text-sm whitespace-nowrap text-font">
              Vaccine
            </td>
            <td className="py-1.5 px-3 font-medium text-sm whitespace-nowrap text-font">
              Date
            </td>
          </tr>
        </thead>
        <tbody>
          {vaccines?.length > 0 &&
            vaccines.map((vaccine) => {
              return (
                <tr className="">
                  <td className="py-1.5 px-3 text-xs text-font">
                    {vaccine?.vaccineeName ?? vaccine?.doseName}
                  </td>
                  <td className="py-1.5 px-3 text-xs text-font">
                    {formatDateAsscen(vaccine?.scheduledDate, "-")}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default ViewDefaulterDetailsPopup;
