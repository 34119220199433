import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetDistrictsMutation,
  useGetSpecialCareMutation,
  useGetSubCountiesMutation,
  useRegisterChildMutation,
} from "../../apis/authApi";
import PasswordLikeInput from "../../components/PasswordLikeInput";
import BlueHeaderContainer from "../../components/common/BlueHeaderContainer/BlueHeaderContainer";
import ListWithCheckbox from "../../components/common/ListWithCheckbox/ListWithCheckbox";
import MinistryLabel from "../../components/common/MinistryLabel/MinistryLabel";
import Input from "../../components/form/input";
import { addNotification } from "../../store/slices/toaster-slice";
import Button from "../../components/common/Button";
import { formatDate, regEx } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import DropDown from "../../components/common/Dropdown";

const validateRegForm = (data) => {
  let errors = {};
  let isValid = true;

  if (!data?.childName) {
    errors.childName = "Child name is required";
    isValid = false;
  } else if (!regEx.NAME.test(data?.childName)) {
    errors.childName = "Invalid child name";
    isValid = false;
  }

  if (!data?.motherContact) {
    errors.motherContact = "Mother Contact is required";
    isValid = false;
  } else if (!regEx.PHONE.test(data?.motherContact)) {
    errors.motherContact = "Please enter valid contact number";
    isValid = false;
  }
  if (!data?.birthOrder) {
    errors.birthOrder = "Birth order is required";
    isValid = false;
  }
  if (!data?.childSex) {
    errors.childSex = "Child sex is required";
    isValid = false;
  }
  if (!data?.childCategory) {
    errors.childCategory = "Child category is required";
    isValid = false;
  }
  if (!data?.birthWeight) {
    errors.birthWeight = "Birth weight is required";
    isValid = false;
  }else if (isNaN(data.birthWeight) || data.birthWeight <= 0) {
    errors.birthWeight = "Birth weight must be a number";
    isValid = false;
}
  if (!data?.birthPlace) {
    errors.birthPlace = "Birth place is required";
    isValid = false;
  }
  if (!data?.district) {
    errors.district = "District is required";
    isValid = false;
  }
  if (!data?.subCounty) {
    errors.subCounty = "Sub county is required";
    isValid = false;
  }
  if (!data?.parish) {
    errors.parish = "Parish is required";
    isValid = false;
  }
  if (!data?.village) {
    errors.village = "Village place is required";
    isValid = false;
  }

  return { isValid, errors };
};

const initialState = {
  childName: "",
  nin: "",
  childSex: "",
  childCategory: "",
  dob: new Date(),
  birthOrder: "",
  birthWeight: null,
  birthPlace: "",
  specialCare: [],
  motherName: "",
  fatherName: "",
  motherOccupation: "",
  fatherOccupation: "",
  motherContact: null,
  fatherContact: null,
  nextKin: "",
  nextKinOccupation: "",
  nextKinContact: null,
  nextKinAddress: "",
  district: "",
  subCounty: "",
  parish: "",
  village: "",
};

const AddChild = () => {
  const [addChildObj, setAddChildObj] = useState(initialState);
  const [getSpecialCare, specialCareLoading] = useGetSpecialCareMutation();
  const [formError, setFormError] = useState({});
  const [specialCareList, setSpecialCareList] = useState({});
  const [registerChild, registerChildLoading] = useRegisterChildMutation();
  const [selectedItems, setSelectedItems] = useState([]);
  const [allDistricts, setAllDistricts] = useState([]);
  const [allSubcounties, setAllSubcounties] = useState([]);
  const [getDistricts, districtsLoading] = useGetDistrictsMutation();
  const [getSubcounties, subcountiesLoading] = useGetSubCountiesMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    handleGetDistricts();
  }, []);

  // fetch data based on selected district
  useEffect(() => {
    if (addChildObj?.district) {
      handleGetSubcounty();
    }
  }, [addChildObj?.district]);

  const handleGetDistricts = async () => {
    try {
      const response = await getDistricts().unwrap();

      // // Convert the response back to an array of objects
      const uniqueDistrictsArray = Array.from(response?.result?.districts).map(
        (district) => ({
          label: district.district,
          value: district.district,
          id: district.id,
        })
      );
      // Update the state with unique districts
      setAllDistricts(uniqueDistrictsArray);
    } catch (error) {
      dispatch(addNotification({ type: "error", message: "Unable to fetch!" }));
    }
  };
  const handleGetSubcounty = async () => {
    try {
      let param = {
        districtId: addChildObj?.district?.id,
      };
      const response = await getSubcounties(param).unwrap();

      // Convert the Set back to an array of objects
      const uniqueSubcountiesArray = Array.from(
        response?.result?.subCounties
      ).map((sub_county) => ({
        label: sub_county.subCountyName,
        value: sub_county.subCountyName,
        id: sub_county.id,
      }));
      // Update the state with unique sub-county
      setAllSubcounties(uniqueSubcountiesArray);
    } catch (error) {
      dispatch(addNotification({ type: "error", message: "Unable to fetch!" }));
    }
  };

  useEffect(() => {
    handleGetSpecialCare();
  }, []);

  const handleGetSpecialCare = async () => {
    try {
      const response = await getSpecialCare().unwrap();
      setSpecialCareList(response?.result?.specialCares);
    } catch (error) {
      if (error?.status == 401 || error?.status == 403) {
        sessionStorage.removeItem("access_token");
        navigate("/login");
        dispatch(
          addNotification({
            type: "error",
            message: "Token is invalid or expired",
          })
        );
      } else {
        dispatch(
          addNotification({ type: "error", message: error?.data?.error })
        );
      }
    }
  };

  const handleDateChange = (name, value) => {
    setAddChildObj((prevObj) => ({
      ...prevObj,
      [name]: value,
    }));
  };
  const handleNinInput = (name, value) => {
    setAddChildObj((prevObj) => ({
      ...prevObj,
      [name]: value,
    }));
  };
  const handleAddressChange = (name, value) => {
    setAddChildObj((prevObj) => ({
      ...prevObj,
      [name]: value,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name == "specialCare") {
      const newSelectedItems = [...selectedItems];
      if (newSelectedItems.includes(value)) {
        newSelectedItems.splice(newSelectedItems.indexOf(value), 1);
      } else {
        newSelectedItems.push(value);
      }
      setSelectedItems(newSelectedItems);
      setAddChildObj((prevObj) => ({
        ...prevObj,
        specialCare: newSelectedItems,
      }));
    } else {
      setAddChildObj((prevObj) => ({
        ...prevObj,
        [name]: value,
      }));
    }
  };

  const handleRegisterChild = async () => {
    try {
      let childInfo = {
        child: {
          name: addChildObj?.childName,
          nin: addChildObj?.nin,
          gender: addChildObj?.childSex,
          category: addChildObj?.childCategory,
          dateOfBirth: addChildObj?.dob,
          birthOrder: addChildObj?.birthOrder,
          birthWeight: addChildObj?.birthWeight,
          place: addChildObj?.birthPlace,
          specialCare: addChildObj?.specialCare,
        },
        parent: {
          motherName: addChildObj?.motherName,
          fatherName: addChildObj?.fatherName,
          motherOccupation: addChildObj?.motherOccupation,
          fatherOccupation: addChildObj?.fatherOccupation,
          motherContact:
            addChildObj?.motherContact && `+256${addChildObj?.motherContact}`,
          fatherContact:
            addChildObj?.fatherContact && `+256${addChildObj?.fatherContact}`,
          nextKin: addChildObj?.nextKin,
          nextKinOccupation: addChildObj?.nextKinOccupation,
          nextKinContact:
            addChildObj?.nextKinContact && `+256${addChildObj?.nextKinContact}`,
          nextKinAddress: addChildObj?.nextKinAddress,
        },
        address: {
          district: addChildObj?.district.value,
          subCounty: addChildObj?.subCounty.value,
          ward: addChildObj?.parish,
          village: addChildObj?.village,
        },
      };
      const response = await registerChild(childInfo).unwrap();
      dispatch(
        addNotification({ type: "success", message: "Successfully Added" })
      );
      navigate(`/vaccination-card/${response?.result?.childNo}`);
      setAddChildObj(initialState);
    } catch (error) {
      dispatch(
        addNotification({ type: "error", message: "Something went wrong" })
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { isValid, errors } = validateRegForm(addChildObj);
    setFormError(errors);

    if (isValid) {
      setFormError({});
      handleRegisterChild();
    }
  };

  return (
    <div className="xl:px-[35px] xl:py-12 lg:px-5 lg:py-7 p-4">
      <div className="flex gap-3 justify-between md:items-end max-md:flex-col">
        <div>
          <MinistryLabel title="Add Child Card" />
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="lg:mt-8 mt-6 bg-[#FFFFFF] rounded-xl lg:px-[26px] px-[14px] lg:py-[32px] py-[20px] flex flex-col gap-3 md:gap-5 xl:gap-7">
          <div className="flex flex-col md:flex-row gap-3 md:gap-5 xl:gap-7 flex-wrap">
            <Input
              label="Child’s Name"
              placeholder={"Enter Child’s Name"}
              type="text"
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
              changeEvent={handleInputChange}
              name="childName"
              isErrorMsg={formError?.childName}
              attributes={{ value: addChildObj?.childName }}
            />
            <PasswordLikeInput
              label="NIN"
              name="nin"
              className="w-auto"
              changeEvent={handleNinInput}
              attributes={{ value: addChildObj?.nin }}
            />
          </div>

          <div className="flex flex-col md:flex-row gap-3 md:gap-5 xl:gap-7 flex-wrap">
            <Input
              label="Child Category"
              placeholder={"Enter Child Category"}
              type="radio"
              options={[
                { name: "National", id: "national" },
                { name: "Refugee", id: "refugee" },
                { name: "Foreigner", id: "foreigner" },
              ]}
              isErrorMsg={formError?.childCategory}
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full text-font"
              changeEvent={handleInputChange}
              name="childCategory"
            />
            <Input
              label="Sex"
              placeholder={"Enter Child Category"}
              type="radio"
              options={[
                { name: "Male", id: "male" },
                { name: "Female", id: "female" },
                { name: "Other", id: "other" },
              ]}
              isErrorMsg={formError?.childSex}
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
              changeEvent={handleInputChange}
              name="childSex"
            />
          </div>

          <div className="flex flex-col md:flex-row gap-3 md:gap-5 xl:gap-7 flex-wrap">
            <Input
              label="Birth Weight"
              placeholder={"Enter Birth Weight"}
              type="text"
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
              changeEvent={handleInputChange}
              name="birthWeight"
              isErrorMsg={formError?.birthWeight}
              attributes={{ value: addChildObj?.birthWeight }}
            />
            <Input
              label="Birth Order"
              placeholder={"Enter Birth Order"}
              type="text"
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
              changeEvent={handleInputChange}
              name="birthOrder"
              isErrorMsg={formError?.birthOrder}
              attributes={{ value: addChildObj?.birthOrder }}
            />
            <Input
              label="Date of Birth"
              placeholder={"DD/MM/YYYY"}
              type="datePicker"
              name="dob"
              changeEvent={handleDateChange}
            />
          </div>

          <div className="flex flex-col md:flex-row gap-3 md:gap-5 xl:gap-7 flex-wrap">
            <Input
              label="Mother’s Name"
              placeholder={"Enter Mother’s Name"}
              type="text"
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
              changeEvent={handleInputChange}
              name="motherName"
              isErrorMsg={formError?.motherName}
              attributes={{ value: addChildObj?.motherName }}
            />
            <Input
              label="Mother’s Contact No."
              placeholder={"Enter Contact No."}
              type="tel"
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
              changeEvent={handleInputChange}
              name="motherContact"
              attributes={{
                value: addChildObj?.motherContact,
              }}
              isErrorMsg={formError?.motherContact}
            />
            <Input
              label="Father’s Name"
              placeholder={"Enter Father’s Name"}
              type="text"
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
              changeEvent={handleInputChange}
              name="fatherName"
              isErrorMsg={formError?.fatherName}
              attributes={{ value: addChildObj?.fatherName }}
            />
            <Input
              label="Father’s Contact No."
              placeholder={"Enter Contact No."}
              type="tel"
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
              changeEvent={handleInputChange}
              name="fatherContact"
              attributes={{
                value: addChildObj?.fatherContact,
              }}
              isErrorMsg={formError?.fatherContact}
            />
            <Input
              label="Father’s Occupation"
              placeholder={"Enter Father’s Occupation"}
              type="text"
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
              changeEvent={handleInputChange}
              name="fatherOccupation"
              isErrorMsg={formError?.fatherOccupation}
              attributes={{ value: addChildObj?.fatherOccupation }}
            />
            <Input
              label="Mother’s Occupation"
              placeholder={"Mother’s Occupation"}
              type="text"
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
              changeEvent={handleInputChange}
              name="motherOccupation"
              isErrorMsg={formError?.motherOccupation}
              attributes={{ value: addChildObj?.motherOccupation }}
            />
            <Input
              label="Next Kin:"
              placeholder={"Enter Next Kin"}
              type="text"
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
              changeEvent={handleInputChange}
              name="nextKin"
              isErrorMsg={formError?.nextKin}
              attributes={{ value: addChildObj?.nextKin }}
            />
            <Input
              label="Next Kin Address:"
              placeholder={"Enter Next Kin Address:"}
              type="text"
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
              changeEvent={handleInputChange}
              name="nextKinAddress"
              isErrorMsg={formError?.nextKinAddress}
              attributes={{ value: addChildObj?.nextKinAddress }}
            />
            <Input
              label="Next Kin Occupation"
              placeholder={"Enter Next Kin Occupation"}
              type="text"
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
              changeEvent={handleInputChange}
              name="nextKinOccupation"
              isErrorMsg={formError?.nextKinOccupation}
              attributes={{ value: addChildObj?.nextKinOccupation }}
            />
            <Input
              label="Next Kin Contact No"
              placeholder={"Enter Contact No"}
              type="tel"
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
              changeEvent={handleInputChange}
              name="nextKinContact"
              attributes={{
                value: addChildObj?.nextKinContact,
              }}
              isErrorMsg={formError?.nextKinContact}
            />
          </div>

          <div className="flex flex-col md:flex-row gap-3 md:gap-5 xl:gap-7 flex-wrap">
            <Input
              label="Place of Birth: Ticks what applies"
              type="radio"
              options={[
                { name: "Community", id: "community" },
                { name: "Health Facility", id: "health_facility" },
              ]}
              isErrorMsg={formError?.birthPlace}
              className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
              changeEvent={handleInputChange}
              name="birthPlace"
            />
          </div>

          <div className="pt-5 border-t ">
            <div className="input-label font-semibold">
              Address of the Child
            </div>
            <div className="flex flex-col md:flex-row gap-3 md:gap-5 xl:gap-7 flex-wrap mt-4">
              <div className="flex flex-col md:flex-row gap-3 md:gap-5 xl:gap-7 flex-wrap">
                <div
                  className={`input-wrapper flex flex-col xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full ${
                    formError?.district ? "error" : ""
                  } `}
                >
                  <label className="input-label mb-2.5">District/City</label>
                  <DropDown
                    options={allDistricts}
                    inputWidth={"100%"}
                    isMultiSelected={false}
                    placeholder="Select Districts"
                    isLoading={districtsLoading?.isLoading}
                    onSelectChange={(value) =>
                      handleAddressChange("district", value)
                    }
                    selectedValue={addChildObj?.district}
                  />
                  {formError?.district !== null && (
                    <p className="error-text">
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M8 12.173a0.591 0.591 0 1 0 0 -1.182 0.591 0.591 0 0 0 0 1.182Zm0 -2.123a0.444 0.444 0 0 1 -0.445 -0.445v-5.333a0.444 0.444 0 1 1 0.889 0v5.333a0.444 0.444 0 0 1 -0.444 0.444Z"
                            fill="red"
                          />
                          <path
                            d="M8 15.111a7.112 7.112 0 1 1 0 -14.222 7.112 7.112 0 0 1 0 14.222Zm0 -13.333a6.222 6.222 0 1 0 0 12.445 6.222 6.222 0 0 0 0 -12.445Z"
                            fill="red"
                          />
                        </svg>
                      </span>
                      {formError?.district}
                    </p>
                  )}
                </div>
                <div
                  className={`input-wrapper flex flex-col xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full ${
                    formError?.subCounty ? "error" : ""
                  } `}
                >
                  <label className="input-label mb-2.5">
                    Sub Country/ Division
                  </label>
                  <DropDown
                    options={allSubcounties}
                    isSearchable={true}
                    inputWidth={"100%"}
                    isMultiSelected={false}
                    placeholder="Select Sub-counties"
                    isLoading={subcountiesLoading?.isLoading}
                    onSelectChange={(value) =>
                      handleAddressChange("subCounty", value)
                    }
                    selectedValue={addChildObj?.subCounty}
                    disabled={addChildObj?.district ? false : true}
                  />
                  {formError?.subCounty !== null && (
                    <p className="error-text">
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M8 12.173a0.591 0.591 0 1 0 0 -1.182 0.591 0.591 0 0 0 0 1.182Zm0 -2.123a0.444 0.444 0 0 1 -0.445 -0.445v-5.333a0.444 0.444 0 1 1 0.889 0v5.333a0.444 0.444 0 0 1 -0.444 0.444Z"
                            fill="red"
                          />
                          <path
                            d="M8 15.111a7.112 7.112 0 1 1 0 -14.222 7.112 7.112 0 0 1 0 14.222Zm0 -13.333a6.222 6.222 0 1 0 0 12.445 6.222 6.222 0 0 0 0 -12.445Z"
                            fill="red"
                          />
                        </svg>
                      </span>
                      {formError?.subCounty}
                    </p>
                  )}
                </div>
                <Input
                  label="Parish/Ward:"
                  placeholder={"Enter Parish/Ward:"}
                  type="text"
                  className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
                  changeEvent={handleInputChange}
                  name="parish"
                  isErrorMsg={formError?.parish}
                  attributes={{ value: addChildObj?.parish }}
                />
                <Input
                  label="LC 1/Village/Cell"
                  placeholder={"Enter LC 1/Village/Cell"}
                  type="text"
                  className="normal-input xl:max-w-[295px] lg:max-w-[240px] md:max-w-[200px] w-full"
                  changeEvent={handleInputChange}
                  name="village"
                  isErrorMsg={formError?.village}
                  attributes={{ value: addChildObj?.village }}
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <BlueHeaderContainer
            headertext={"Tick Reasons For Special Care"}
            className="bg-white rounded-xl"
            headerClass="xl:h-[64px] h-[50px] rounded-t-[12px] bg-primary text-white text-[20px] font-semibold leading-normal"
          >
            <ListWithCheckbox
              list={specialCareList}
              changeEvent={handleInputChange}
              name="specialCare"
            />
          </BlueHeaderContainer>
        </div>
        <div className="lg:mt-9 mt-6 flex justify-end">
          <Button
            type="submit"
            btnType="normal"
            isLoading={registerChildLoading?.isLoading}
            classname="md:max-w-[232px]"
          >
            Save Child
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddChild;
