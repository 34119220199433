import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  useGetTDDoseDataMutation,
  useGetTDVaccinationCardMutation,
} from "../../apis/authApi";
import { DownloadIcon, SearchIcon } from "../../assets/Icons";
import Button from "../../components/common/Button";
import MinistryLabel from "../../components/common/MinistryLabel/MinistryLabel";
import Popup from "../../components/common/Popup";
import ComponentLoader from "../../components/common/loader/ComponentLoader";
import NoDataFound from "../../components/common/loader/NoDataFound";
import Input from "../../components/form/input";
import TDVaccinationTable from "../../components/td/TDVaccinationTable";
import TDVaccinePopup from "../../components/td/TDVaccinePopup";
import "../../scss/tdRegister/tdRegister.scss";
import { addNotification } from "../../store/slices/toaster-slice";
import TableLoader from "../../components/common/loader/TableLoader";
import UpdateContactDetails from "../../components/childRegister/UpdateContactDetails";

const TDVaccinationCard = () => {
  const [dosePopup, setDosePopup] = useState(false);
  const [searchByClientNumber, SetSearchByClientNumber] = useState("");
  const [searchedClient, setSearchedClient] = useState({});
  const [searchedDose, setSearchedDose] = useState({});
  const [getClientData, getClientDataLoading] =
    useGetTDVaccinationCardMutation();
  const [getClientDose, getClientDoseLoading] = useGetTDDoseDataMutation();
  const [doseData, setDoseData] = useState();
  const [updateContactPopup, setUpdateContactPopup] = useState(false);

  const dispatch = useDispatch();
  const { clientNo } = useParams();

  useEffect(() => {
    if (clientNo) {
      SetSearchByClientNumber(clientNo);
      callAllClientAPI(clientNo);
    }
  }, [clientNo]);

  const handleGetClientData = async (clientNo) => {
    if (clientNo) {
      try {
        let param = {
          clientNo: clientNo,
        };
        const response = await getClientData(param).unwrap();
        setSearchedClient(response?.result?.clientData?.[0] ?? "");
        setSearchedDose(response?.result?.clientData?.[0]?.doses ?? "");
      } catch (error) {
        dispatch(
          addNotification({ type: "error", message: error?.data?.error })
        );
      }
    }
  };

  // Handle on Refresh Doses
  const handleGetClientDose = async (clientNo) => {
    if (clientNo) {
      try {
        let param = {
          clientNo: clientNo,
        };
        const response = await getClientDose(param).unwrap();
        setSearchedDose(response?.result ?? "");
      } catch (error) {
        dispatch(
          addNotification({ type: "error", message: error?.data?.error })
        );
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "client number") {
      SetSearchByClientNumber(value);
    }
  };

  const callAllClientAPI = (id) => {
    handleGetClientData(id);
    handleGetClientDose(id);
  };

  const handleHPVDosePopup = (dose) => {
    setDoseData(dose);
    setDosePopup(!dosePopup);
  };

  const handleUpdateContactPopup = () => {
    setUpdateContactPopup(!updateContactPopup);
  };
  return (
    <div className="xl:px-[35px] xl:py-12 lg:px-5 lg:py-7 p-4 td-vaccination">
      <div className="flex gap-3 justify-between md:items-end max-md:flex-col">
        <div>
          <MinistryLabel title="Vaccination Card" />
        </div>
      </div>
      <div className="my-6 flex md:items-center md:justify-between max-md:flex-col gap-4">
        <div className="flex items-center">
          <Input
            placeholder={"Search by patient no."}
            type="text"
            className="normal-input xl:w-[475px] lg:w-[250px]"
            changeEvent={handleInputChange}
            name="client number"
            value={searchByClientNumber}
          />
          <div className="ml-[20px]">
            <Button
              type="submit"
              btnType="normal"
              isLoading={getClientDataLoading.isLoading}
              clickHandler={() => callAllClientAPI(searchByClientNumber)}
            >
              <SearchIcon />
              Search
            </Button>
          </div>
        </div>
        {Object.keys(searchedClient)?.length > 0 && (
          <div>
            <Link
              to={`https://api-backend.medrefug.com/v1/TD/download/vaccinationcard/${searchByClientNumber}`}
              target="_blank"
              className="btn"
            >
              <DownloadIcon />
              Download
            </Link>
          </div>
        )}
      </div>
      {getClientDataLoading?.isLoading ? (
        <ComponentLoader />
      ) : Object.keys(searchedClient)?.length > 0 ? (
        <>
          <div className="bg-white w-full rounded-xl">
            <div className="p-2 mb-4 flex justify-end">
              <Button
                type="submit"
                btnType="normal"
                // isLoading={childWithIdLoading.isLoading}
                clickHandler={() => handleUpdateContactPopup()}
                classname="w-min"
              >
                Update Contact
              </Button>
            </div>
            {searchedClient && <TDVaccinationTable data={searchedClient} />}
          </div>
          <div className="bg-white w-full rounded-xl mt-5 dose-table overflow-auto scrollbar scrollbar-w-1.5 scrollbar-h-1.5 scrollbar-thumb-primary scrollbar-track-slate-200">
            <table>
              <thead>
                <tr>
                  <th>Date of 1st Dose</th>
                  <th>Date of 2nd Dose</th>
                  <th>Date of 3rd Dose</th>
                  <th>Date of 4th Dose</th>
                  <th>Date of 5th Dose</th>
                </tr>
              </thead>
              <tbody>
                {getClientDoseLoading.isLoading ? (
                  <TableLoader />
                ) : (
                  <tr>
                    {searchedDose?.length &&
                      searchedDose.map((dose, index) => {
                        return (
                          <td key={index}>
                            <span className="flex items-center justify-center">
                              <span
                                onClick={() => handleHPVDosePopup(dose)}
                                className={`py-3 px-5 font-semibold text-white !w-auto rounded-md shadow-buttonShadow cursor-pointer ${
                                  dose?.status === "T"
                                    ? "bg-color_01F17E "
                                    : dose?.status === "D"
                                    ? "bg-warning"
                                    : dose?.status === "M"
                                    ? "bg-danger"
                                    : "bg-[rgba(113,_97,_239,_0.70)]"
                                } `}
                              >
                                Schedule Dose
                              </span>
                            </span>
                          </td>
                        );
                      })}
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div className="w-full h-[200px] flex justify-center items-center bg-white rounded-xl">
          <NoDataFound />
        </div>
      )}

      {dosePopup && (
        <Popup
          title="Update Vaccine Dose"
          width="600px"
          closeBtn={true}
          closeHandler={handleHPVDosePopup}
        >
          <TDVaccinePopup
            closePopup={handleHPVDosePopup}
            clientNo={clientNo}
            doseData={doseData}
            refreshDoses={handleGetClientDose}
          />
        </Popup>
      )}

      {updateContactPopup && (
        <Popup
          title="Update Contact Details"
          width="400px"
          closeBtn={false}
          closeHandler={handleUpdateContactPopup}
        >
          <UpdateContactDetails
            closeHandler={handleUpdateContactPopup}
            data={searchedClient}
            updateRecord={handleGetClientData}
          />
        </Popup>
      )}
    </div>
  );
};

export default TDVaccinationCard;
