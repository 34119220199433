import React from "react";
import { Link } from "react-router-dom";
import { calculateAgeInMonths, formatDateAsscen } from "../../utils/helper";

const ChildRegisterTableBody = ({ child }) => {
  return (
    <>
      <tr key={child?.childNo}>
        <td rowSpan={2}>
          <Link
            to={`/vaccination-card/${child?.childNo}`}
            className="text-primary"
          >
            {child?.childNo}
          </Link>
        </td>
        <td>{child?.name}</td>
        <td
          rowSpan={6}
        >{`${child?.address?.village}, ${child?.address?.subCounty}, ${child?.address?.district} `}</td>
        <td rowSpan={6}>{child?.category}</td>
        <td rowSpan={3}>{formatDateAsscen(child?.dateOfBirth)}</td>
        <td rowSpan={3}>{child?.gender}</td>
        <td rowSpan={1} colSpan={2}>
          At birth
        </td>
        <td rowSpan={2}>{child?.birthWeight ? child?.birthWeight : ""}</td>
        <td rowSpan={6} className="vertical-text">
          <span className="text-color_758092CC">Code</span>
        </td>
        <td colSpan={2}>At 6 weeks</td>
        <td rowSpan={2}>
          {child?.childVaccines?.[5]?.vaccineSchedules[0]?.weight ?? ""}
        </td>
        <td rowSpan={6} className="vertical-text">
          <span className="text-color_758092CC">Code</span>
        </td>
        <td rowSpan={6} className="vertical-text">
          <span className="text-color_758092CC">Received LLIN & Data</span>
        </td>
        <td rowSpan={6} className="vertical-text">
          <span className="text-color_758092CC">Y/N</span>
        </td>
        <td rowSpan={6} className="vertical-text">
          <span className="text-color_758092CC">Y/N</span>
        </td>
        <td rowSpan={6} className="vertical-text">
          <span className="text-color_758092CC">(Y/N/NA)</span>
        </td>
        <td rowSpan={6} className="vertical-text">
          <span className="text-color_758092CC">(Y/N/NA)</span>
        </td>
        <td rowSpan={2} colSpan={5}>
          At 10 weeks
        </td>
        <td rowSpan={2}>
          {child?.childVaccines?.[2]?.vaccineSchedules[1]?.weight ?? ""}
        </td>
        <td rowSpan={6} className="vertical-text">
          <span className="text-color_758092CC">Code</span>
        </td>
        <td rowSpan={2} colSpan={5}>
          At 14 weeks
        </td>
        <td rowSpan={2}>
          {child?.childVaccines?.[2]?.vaccineSchedules[2]?.weight ?? ""}
        </td>
        <td rowSpan={6} className="vertical-text">
          <span className="text-color_758092CC">Code</span>
        </td>
        <td rowSpan={2} colSpan={3}>
          Data Malaria Vaccine
        </td>
        <td rowSpan={2}>
          MUAC
          <br /> (CM +R/Y/G)
        </td>
        <td rowSpan={6} className="vertical-text">
          <span className="text-color_758092CC">Code</span>
        </td>
        <td colSpan={2}>At 9 Months</td>
        <td rowSpan={2}>
          MUAC
          <br /> (CM +R/Y/G)
        </td>
        <td rowSpan={6} className="vertical-text">
          <span className="text-color_758092CC">Code</span>
        </td>
        <td rowSpan={2} colSpan={2}>
          At 18 Months
        </td>
        <td rowSpan={2}>
          MUAC
          <br /> (CM +R/Y/G)
        </td>
        <td rowSpan={6} className="vertical-text">
          <span className="text-color_758092CC">Code</span>
        </td>
        <td rowSpan={6} className="vertical-text">
          <span className="text-color_758092CC">Y/N</span>
        </td>
        <td rowSpan={6} className="vertical-text">
          <span className="text-color_758092CC">Y/N</span>
        </td>
        <td rowSpan={6} className="vertical-text">
          <span className="text-color_758092CC">(Y/N/NA)</span>
        </td>
        <td rowSpan={6} className="vertical-text">
          <span className="text-color_758092CC">(Y/N/NA)</span>
        </td>
        <td rowSpan={6} className="vertical-text">
          <span className="text-color_758092CC">Tick</span>
        </td>
        <td rowSpan={3} colSpan={2}>
          Disability
          <br /> (Write Code)
        </td>
        <td rowSpan={6} className="!p-0 border-collapse">
          <table className="!border-x-0 !border-y-0 w-full">
            <tr>
              <td className="!border-x-0 h-9  !border-t-0">
                {child?.chilNutritions?.[0]?.nutritionSchedules?.[0]
                  ?.vaccinatedDate &&
                  calculateAgeInMonths(
                    child?.dateOfBirth,
                    child?.chilNutritions?.[0]?.nutritionSchedules?.[0]
                      ?.vaccinatedDate
                  )}
              </td>
            </tr>
            <tr>
              <td className="!border-x-0 h-9 ">
                {child?.chilNutritions?.[0]?.nutritionSchedules?.[1]
                  ?.vaccinatedDate &&
                  calculateAgeInMonths(
                    child?.dateOfBirth,
                    child?.chilNutritions?.[0]?.nutritionSchedules?.[1]
                      ?.vaccinatedDate
                  )}
              </td>
            </tr>
            <tr>
              <td className="!border-x-0 h-9 ">
                {child?.chilNutritions?.[0]?.nutritionSchedules?.[2]
                  ?.vaccinatedDate &&
                  calculateAgeInMonths(
                    child?.dateOfBirth,
                    child?.chilNutritions?.[0]?.nutritionSchedules?.[2]
                      ?.vaccinatedDate
                  )}
              </td>
            </tr>
            <tr>
              <td className="!border-x-0 h-9 ">
                {child?.chilNutritions?.[0]?.nutritionSchedules?.[3]
                  ?.vaccinatedDate &&
                  calculateAgeInMonths(
                    child?.dateOfBirth,
                    child?.chilNutritions?.[0]?.nutritionSchedules?.[3]
                      ?.vaccinatedDate
                  )}
              </td>
            </tr>
            <tr>
              <td className="!border-x-0 h-9 ">
                {child?.chilNutritions?.[0]?.nutritionSchedules?.[4]
                  ?.vaccinatedDate &&
                  calculateAgeInMonths(
                    child?.dateOfBirth,
                    child?.chilNutritions?.[0]?.nutritionSchedules?.[4]
                      ?.vaccinatedDate
                  )}
              </td>
            </tr>
            <tr>
              <td className="!border-x-0 h-9 ">
                {child?.chilNutritions?.[0]?.nutritionSchedules?.[5]
                  ?.vaccinatedDate &&
                  calculateAgeInMonths(
                    child?.dateOfBirth,
                    child?.chilNutritions?.[0]?.nutritionSchedules?.[5]
                      ?.vaccinatedDate
                  )}
              </td>
            </tr>
            <tr>
              <td className="!border-x-0 h-9 ">
                {child?.chilNutritions?.[0]?.nutritionSchedules?.[6]
                  ?.vaccinatedDate &&
                  calculateAgeInMonths(
                    child?.dateOfBirth,
                    child?.chilNutritions?.[0]?.nutritionSchedules?.[6]
                      ?.vaccinatedDate
                  )}
              </td>
            </tr>
            <tr>
              <td className="!border-x-0 h-9 ">
                {child?.chilNutritions?.[0]?.nutritionSchedules?.[7]
                  ?.vaccinatedDate &&
                  calculateAgeInMonths(
                    child?.dateOfBirth,
                    child?.chilNutritions?.[0]?.nutritionSchedules?.[7]
                      ?.vaccinatedDate
                  )}
              </td>
            </tr>
            <tr>
              <td className="!border-x-0 h-9  !border-b-0">
                {child?.chilNutritions?.[0]?.nutritionSchedules?.[8]
                  ?.vaccinatedDate &&
                  calculateAgeInMonths(
                    child?.dateOfBirth,
                    child?.chilNutritions?.[0]?.nutritionSchedules?.[8]
                      ?.vaccinatedDate
                  )}
              </td>
            </tr>
          </table>
        </td>
        <td rowSpan={6} className="vertical-text">
          Doses
        </td>
        <td rowSpan={6} className="!p-0 border-collapse">
          <table className="!border-x-0 !border-y-0 w-full">
            <tr>
              <td className="!border-x-0 !border-t-0 h-9">
                {child?.chilNutritions?.[0]?.nutritionSchedules?.[0]
                  ?.vaccinatedDate
                  ? formatDateAsscen(
                      child?.chilNutritions?.[0]?.nutritionSchedules?.[0]
                        ?.vaccinatedDate,
                      "/"
                    )
                  : ""}
              </td>
            </tr>
            <tr>
              <td className="!border-x-0 h-9">
                {child?.chilNutritions?.[0]?.nutritionSchedules?.[1]
                  ?.vaccinatedDate
                  ? formatDateAsscen(
                      child?.chilNutritions?.[0]?.nutritionSchedules?.[1]
                        ?.vaccinatedDate,
                      "/"
                    )
                  : ""}
              </td>
            </tr>
            <tr>
              <td className="!border-x-0 h-9">
                {child?.chilNutritions?.[0]?.nutritionSchedules?.[2]
                  ?.vaccinatedDate
                  ? formatDateAsscen(
                      child?.chilNutritions?.[0]?.nutritionSchedules?.[2]
                        ?.vaccinatedDate,
                      "/"
                    )
                  : ""}
              </td>
            </tr>
            <tr>
              <td className="!border-x-0 h-9">
                {child?.chilNutritions?.[0]?.nutritionSchedules?.[3]
                  ?.vaccinatedDate
                  ? formatDateAsscen(
                      child?.chilNutritions?.[0]?.nutritionSchedules?.[3]
                        ?.vaccinatedDate,
                      "/"
                    )
                  : ""}
              </td>
            </tr>
            <tr>
              <td className="!border-x-0 h-9">
                {child?.chilNutritions?.[0]?.nutritionSchedules?.[4]
                  ?.vaccinatedDate
                  ? formatDateAsscen(
                      child?.chilNutritions?.[0]?.nutritionSchedules?.[4]
                        ?.vaccinatedDate,
                      "/"
                    )
                  : ""}
              </td>
            </tr>
            <tr>
              <td className="!border-x-0 h-9">
                {child?.chilNutritions?.[0]?.nutritionSchedules?.[5]
                  ?.vaccinatedDate
                  ? formatDateAsscen(
                      child?.chilNutritions?.[0]?.nutritionSchedules?.[5]
                        ?.vaccinatedDate,
                      "/"
                    )
                  : ""}
              </td>
            </tr>
            <tr>
              <td className="!border-x-0 h-9">
                {child?.chilNutritions?.[0]?.nutritionSchedules?.[6]
                  ?.vaccinatedDate
                  ? formatDateAsscen(
                      child?.chilNutritions?.[0]?.nutritionSchedules?.[6]
                        ?.vaccinatedDate,
                      "/"
                    )
                  : ""}
              </td>
            </tr>
            <tr>
              <td className="!border-x-0 h-9">
                {child?.chilNutritions?.[0]?.nutritionSchedules?.[7]
                  ?.vaccinatedDate
                  ? formatDateAsscen(
                      child?.chilNutritions?.[0]?.nutritionSchedules?.[7]
                        ?.vaccinatedDate,
                      "/"
                    )
                  : ""}
              </td>
            </tr>
            <tr>
              <td className="!border-x-0 h-9 !border-b-0">
                {child?.chilNutritions?.[0]?.nutritionSchedules?.[8]
                  ?.vaccinatedDate
                  ? formatDateAsscen(
                      child?.chilNutritions?.[0]?.nutritionSchedules?.[8]
                        ?.vaccinatedDate,
                      "/"
                    )
                  : ""}
              </td>
            </tr>
          </table>
        </td>
        <td rowSpan={6} className="!p-0 border-collapse">
          <table className="!border-x-0 !border-y-0 w-full">
            <tr>
              <td className="!border-x-0  h-9 !border-t-0">
                {child?.chilNutritions?.[0]?.nutritionSchedules?.[0]?.status ==
                "T"
                  ? "T"
                  : child?.chilNutritions?.[0]?.nutritionSchedules?.[0]
                      ?.status == "D"
                  ? "L"
                  : ""}
              </td>
            </tr>
            <tr>
              <td className="!border-x-0  h-9">
                {child?.chilNutritions?.[0]?.nutritionSchedules?.[1]?.status ==
                "T"
                  ? "T"
                  : child?.chilNutritions?.[1]?.nutritionSchedules?.[1]
                      ?.status == "D"
                  ? "L"
                  : ""}
              </td>
            </tr>
            <tr>
              <td className="!border-x-0  h-9">
                {child?.chilNutritions?.[0]?.nutritionSchedules?.[2]?.status ==
                "T"
                  ? "T"
                  : child?.chilNutritions?.[0]?.nutritionSchedules?.[2]
                      ?.status == "D"
                  ? "L"
                  : ""}
              </td>
            </tr>
            <tr>
              <td className="!border-x-0  h-9">
                {child?.chilNutritions?.[0]?.nutritionSchedules?.[3]?.status ==
                "T"
                  ? "T"
                  : child?.chilNutritions?.[0]?.nutritionSchedules?.[3]
                      ?.status == "D"
                  ? "L"
                  : ""}
              </td>
            </tr>
            <tr>
              <td className="!border-x-0  h-9">
                {child?.chilNutritions?.[0]?.nutritionSchedules?.[4]?.status ==
                "T"
                  ? "T"
                  : child?.chilNutritions?.[0]?.nutritionSchedules?.[4]
                      ?.status == "D"
                  ? "L"
                  : ""}
              </td>
            </tr>
            <tr>
              <td className="!border-x-0  h-9">
                {child?.chilNutritions?.[0]?.nutritionSchedules?.[5]?.status ==
                "T"
                  ? "T"
                  : child?.chilNutritions?.[0]?.nutritionSchedules?.[5]
                      ?.status == "D"
                  ? "L"
                  : ""}
              </td>
            </tr>
            <tr>
              <td className="!border-x-0  h-9">
                {child?.chilNutritions?.[0]?.nutritionSchedules?.[6]?.status ==
                "T"
                  ? "T"
                  : child?.chilNutritions?.[0]?.nutritionSchedules?.[6]
                      ?.status == "D"
                  ? "L"
                  : ""}
              </td>
            </tr>
            <tr>
              <td className="!border-x-0  h-9">
                {child?.chilNutritions?.[0]?.nutritionSchedules?.[7]?.status ==
                "T"
                  ? "T"
                  : child?.chilNutritions?.[0]?.nutritionSchedules?.[7]
                      ?.status == "D"
                  ? "L"
                  : ""}
              </td>
            </tr>
            <tr>
              <td className="!border-x-0  h-9 !border-b-0">
                {child?.chilNutritions?.[0]?.nutritionSchedules?.[8]?.status ==
                "T"
                  ? "T"
                  : child?.chilNutritions?.[0]?.nutritionSchedules?.[8]
                      ?.status == "D"
                  ? "L"
                  : ""}
              </td>
            </tr>
          </table>
        </td>
        <td rowSpan={6} className="vertical-text">
          Doses
        </td>
        <td rowSpan={6} className="!p-0 border-collapse">
          <table className="!border-x-0 !border-y-0 w-full">
            <tr>
              <td className="!border-x-0 !border-t-0 h-9">
                {child?.chilNutritions?.[1]?.nutritionSchedules?.[0]
                  ?.vaccinatedDate
                  ? formatDateAsscen(
                      child?.chilNutritions?.[1]?.nutritionSchedules?.[0]
                        ?.vaccinatedDate,
                      "/"
                    )
                  : ""}
              </td>
            </tr>
            <tr>
              <td className="!border-x-0 h-9">
                {child?.chilNutritions?.[1]?.nutritionSchedules?.[1]
                  ?.vaccinatedDate
                  ? formatDateAsscen(
                      child?.chilNutritions?.[1]?.nutritionSchedules?.[1]
                        ?.vaccinatedDate,
                      "/"
                    )
                  : ""}
              </td>
            </tr>
            <tr>
              <td className="!border-x-0 h-9">
                {child?.chilNutritions?.[1]?.nutritionSchedules?.[2]
                  ?.vaccinatedDate
                  ? formatDateAsscen(
                      child?.chilNutritions?.[1]?.nutritionSchedules?.[2]
                        ?.vaccinatedDate,
                      "/"
                    )
                  : ""}
              </td>
            </tr>
            <tr>
              <td className="!border-x-0 h-9">
                {child?.chilNutritions?.[1]?.nutritionSchedules?.[3]
                  ?.vaccinatedDate
                  ? formatDateAsscen(
                      child?.chilNutritions?.[1]?.nutritionSchedules?.[3]
                        ?.vaccinatedDate,
                      "/"
                    )
                  : ""}
              </td>
            </tr>
            <tr>
              <td className="!border-x-0 h-9">
                {child?.chilNutritions?.[1]?.nutritionSchedules?.[4]
                  ?.vaccinatedDate
                  ? formatDateAsscen(
                      child?.chilNutritions?.[1]?.nutritionSchedules?.[4]
                        ?.vaccinatedDate,
                      "/"
                    )
                  : ""}
              </td>
            </tr>
            <tr>
              <td className="!border-x-0 h-9">
                {child?.chilNutritions?.[1]?.nutritionSchedules?.[5]
                  ?.vaccinatedDate
                  ? formatDateAsscen(
                      child?.chilNutritions?.[1]?.nutritionSchedules?.[5]
                        ?.vaccinatedDate,
                      "/"
                    )
                  : ""}
              </td>
            </tr>
            <tr>
              <td className="!border-x-0 h-9">
                {child?.chilNutritions?.[1]?.nutritionSchedules?.[6]
                  ?.vaccinatedDate
                  ? formatDateAsscen(
                      child?.chilNutritions?.[1]?.nutritionSchedules?.[6]
                        ?.vaccinatedDate,
                      "/"
                    )
                  : ""}
              </td>
            </tr>
            <tr>
              <td className="!border-x-0 h-9">
                {child?.chilNutritions?.[1]?.nutritionSchedules?.[7]
                  ?.vaccinatedDate
                  ? formatDateAsscen(
                      child?.chilNutritions?.[1]?.nutritionSchedules?.[7]
                        ?.vaccinatedDate,
                      "/"
                    )
                  : ""}
              </td>
            </tr>
            <tr>
              <td className="!border-x-0 h-9 !border-b-0">
                {child?.chilNutritions?.[1]?.nutritionSchedules?.[8]
                  ?.vaccinatedDate
                  ? formatDateAsscen(
                      child?.chilNutritions?.[1]?.nutritionSchedules?.[8]
                        ?.vaccinatedDate,
                      "/"
                    )
                  : ""}
              </td>
            </tr>
          </table>
        </td>
        <td rowSpan={6} className="w-[300px] font-bold">
          Remarks
        </td>
      </tr>
      <tr>
        <td rowSpan={1}>{child?.parent?.motherName}</td>
        <td rowSpan={1} colSpan={2}>
          {child?.childVaccines?.[4]?.vaccineSchedules[0]?.vaccinatedDate
            ? formatDateAsscen(
                child?.childVaccines?.[4]?.vaccineSchedules[0]?.vaccinatedDate,
                "/"
              )
            : ""}
        </td>

        <td rowSpan={1} colSpan={1}>
          {child?.childVaccines?.[1]?.vaccineSchedules[1]?.vaccinatedDate
            ? formatDateAsscen(
                child?.childVaccines?.[1]?.vaccineSchedules[1]?.vaccinatedDate,
                "/"
              )
            : ""}
        </td>
        <td rowSpan={1} colSpan={1}>
          {child?.childVaccines?.[1]?.vaccineSchedules[1]?.status == "T"
            ? "T"
            : child?.childVaccines?.[1]?.vaccineSchedules[1]?.status == "D"
            ? "L"
            : ""}
        </td>
        <td rowSpan={4} className="vertical-text">
          {child?.childVaccines?.[7]?.vaccineSchedules[0]?.vaccinatedDate
            ? formatDateAsscen(
                child?.childVaccines?.[7]?.vaccineSchedules[0]?.vaccinatedDate,
                "/"
              )
            : ""}
        </td>
        <td rowSpan={4} className="vertical-text">
          {child?.childVaccines?.[8]?.vaccineSchedules[0]?.vaccinatedDate
            ? formatDateAsscen(
                child?.childVaccines?.[8]?.vaccineSchedules[0]?.vaccinatedDate,
                "/"
              )
            : ""}
        </td>
      </tr>
      <tr>
        <td rowSpan={4}>{child?.nin}</td>
        <td rowSpan={1}>{child?.parent?.fatherName}</td>
        <td rowSpan={1} colSpan={2}>
          {child?.childVaccines?.[1]?.vaccineSchedules[0]?.vaccinatedDate
            ? formatDateAsscen(
                child?.childVaccines?.[1]?.vaccineSchedules[0]?.vaccinatedDate,
                "/"
              )
            : ""}
        </td>

        <td rowSpan={2}>
          <span className="text-color_758092CC">
            {" "}
            Weight for age
            <br /> Z score (SU/U/N/
            <br /> PW/O)
          </span>
        </td>
        <td rowSpan={1} colSpan={1}>
          {child?.childVaccines?.[2]?.vaccineSchedules[0]?.vaccinatedDate
            ? formatDateAsscen(
                child?.childVaccines?.[2]?.vaccineSchedules[0]?.vaccinatedDate,
                "/"
              )
            : ""}
        </td>
        <td rowSpan={1} colSpan={1}>
          {child?.childVaccines?.[2]?.vaccineSchedules[0]?.status == "T"
            ? "T"
            : child?.childVaccines?.[2]?.vaccineSchedules[0]?.status == "D"
            ? "L"
            : ""}
        </td>
        <td rowSpan={2}>
          <span className="text-color_758092CC">
            {" "}
            Weight for age
            <br /> Z score (SU/U/N/
            <br /> PW/O)
          </span>
        </td>
        <td rowSpan={3} className="vertical-text">
          {child?.childVaccines?.[1]?.vaccineSchedules[2]?.vaccinatedDate
            ? formatDateAsscen(
                child?.childVaccines?.[1]?.vaccineSchedules[2]?.vaccinatedDate,
                "/"
              )
            : ""}
        </td>
        <td rowSpan={3} className="vertical-text">
          {child?.childVaccines?.[3]?.vaccineSchedules[1]?.vaccinatedDate
            ? formatDateAsscen(
                child?.childVaccines?.[3]?.vaccineSchedules[1]?.vaccinatedDate,
                "/"
              )
            : ""}
        </td>
        <td rowSpan={3} className="vertical-text">
          {child?.childVaccines?.[5]?.vaccineSchedules[1]?.vaccinatedDate
            ? formatDateAsscen(
                child?.childVaccines?.[5]?.vaccineSchedules[1]?.vaccinatedDate,
                "/"
              )
            : ""}
        </td>
        <td rowSpan={3} className="vertical-text">
          {child?.childVaccines?.[4]?.vaccineSchedules[2]?.vaccinatedDate
            ? formatDateAsscen(
                child?.childVaccines?.[4]?.vaccineSchedules[2]?.vaccinatedDate,
                "/"
              )
            : ""}
        </td>
        <td rowSpan={3} className="vertical-text">
          {child?.childVaccines?.[2]?.vaccineSchedules[1]?.vaccinatedDate
            ? formatDateAsscen(
                child?.childVaccines?.[2]?.vaccineSchedules[1]?.vaccinatedDate,
                "/"
              )
            : ""}
        </td>
        <td rowSpan={2}>
          <span className="text-color_758092CC">
            {" "}
            Weight for age
            <br /> Z score (SU/U/N/
            <br /> PW/O)
          </span>
        </td>
        <td rowSpan={3} className="vertical-text">
          {child?.childVaccines?.[1]?.vaccineSchedules[3]?.vaccinatedDate
            ? formatDateAsscen(
                child?.childVaccines?.[1]?.vaccineSchedules[3]?.vaccinatedDate,
                "/"
              )
            : ""}
        </td>
        <td rowSpan={3} className="vertical-text">
          {child?.childVaccines?.[3]?.vaccineSchedules[2]?.vaccinatedDate
            ? formatDateAsscen(
                child?.childVaccines?.[3]?.vaccineSchedules[2]?.vaccinatedDate,
                "/"
              )
            : ""}
        </td>
        <td rowSpan={3} className="vertical-text">
          {child?.childVaccines?.[5]?.vaccineSchedules[2]?.vaccinatedDate
            ? formatDateAsscen(
                child?.childVaccines?.[5]?.vaccineSchedules[2]?.vaccinatedDate,
                "/"
              )
            : ""}
        </td>
        <td rowSpan={3} className="vertical-text">
          {child?.childVaccines?.[4]?.vaccineSchedules[3]?.vaccinatedDate
            ? formatDateAsscen(
                child?.childVaccines?.[4]?.vaccineSchedules[3]?.vaccinatedDate,
                "/"
              )
            : ""}
        </td>
        <td rowSpan={3} className="vertical-text">
          {child?.childVaccines?.[2]?.vaccineSchedules[2]?.vaccinatedDate
            ? formatDateAsscen(
                child?.childVaccines?.[2]?.vaccineSchedules[2]?.vaccinatedDate,
                "/"
              )
            : ""}
        </td>
        <td rowSpan={2}>
          <span className="text-color_758092CC">
            {" "}
            Weight for age
            <br /> Z score (SU/U/N/
            <br /> PW/O)
          </span>
        </td>
        <td rowSpan={3} className="vertical-text">
          {child?.childVaccines?.[6]?.vaccineSchedules[0]?.vaccinatedDate
            ? formatDateAsscen(
                child?.childVaccines?.[6]?.vaccineSchedules[0]?.vaccinatedDate,
                "/"
              )
            : ""}
        </td>
        <td rowSpan={3} className="vertical-text">
          {child?.childVaccines?.[6]?.vaccineSchedules[1]?.vaccinatedDate
            ? formatDateAsscen(
                child?.childVaccines?.[6]?.vaccineSchedules[1]?.vaccinatedDate,
                "/"
              )
            : ""}
        </td>
        <td rowSpan={3} className="vertical-text">
          {child?.childVaccines?.[6]?.vaccineSchedules[2]?.vaccinatedDate
            ? formatDateAsscen(
                child?.childVaccines?.[6]?.vaccineSchedules[2]?.vaccinatedDate,
                "/"
              )
            : ""}
        </td>
        <td>{child?.childVaccines?.[6]?.vaccineSchedules[3]?.weight ?? ""}</td>
        <td>{child?.childVaccines?.[8]?.vaccineSchedules[0]?.weight ?? ""}</td>
        <td rowSpan={3} className="vertical-text">
          {child?.childVaccines?.[6]?.vaccineSchedules[3]?.vaccinatedDate
            ? formatDateAsscen(
                child?.childVaccines?.[6]?.vaccineSchedules[3]?.vaccinatedDate,
                "/"
              )
            : ""}
        </td>
        <td rowSpan={3} className="vertical-text">
          {child?.childVaccines?.[8]?.vaccineSchedules[1]?.vaccinatedDate
            ? formatDateAsscen(
                child?.childVaccines?.[8]?.vaccineSchedules[1]?.vaccinatedDate,
                "/"
              )
            : ""}
        </td>
        <td>{child?.childVaccines?.[8]?.vaccineSchedules[1]?.weight ?? ""}</td>
      </tr>
      <tr>
        <td rowSpan={3}>
          {child?.parent?.motherContact} <br /> {child?.parent?.fatherContact}
        </td>
        <td rowSpan={3}>{calculateAgeInMonths(child?.dateOfBirth)}</td>
        <td rowSpan={3}>Y/N</td>
        <td rowSpan={1} colSpan={2}>
          {child?.childVaccines?.[0]?.vaccineSchedules[0]?.vaccinatedDate
            ? formatDateAsscen(
                child?.childVaccines?.[0]?.vaccineSchedules[0]?.vaccinatedDate,
                "/"
              )
            : ""}
        </td>

        <td rowSpan={1} colSpan={1}>
          {child?.childVaccines?.[4]?.vaccineSchedules[1]?.vaccinatedDate
            ? formatDateAsscen(
                child?.childVaccines?.[4]?.vaccineSchedules[1]?.vaccinatedDate,
                "/"
              )
            : ""}
        </td>
        <td rowSpan={1} colSpan={1}>
          {child?.childVaccines?.[0]?.vaccineSchedules[0]?.status == "T"
            ? "T"
            : child?.childVaccines?.[0]?.vaccineSchedules[0]?.status == "D"
            ? "L"
            : ""}
        </td>
        <td rowSpan={2}>
          <span className="text-color_758092CC">
            {" "}
            Weight for age
            <br /> Z score (SU/U/N/
            <br /> PW/O)
          </span>
        </td>
        <td rowSpan={2}>
          <span className="text-color_758092CC">
            {" "}
            Weight for age
            <br /> Z score (SU/U/N/
            <br /> PW/O)
          </span>
        </td>
        <td rowSpan={2}>
          <span className="text-color_758092CC">
            {" "}
            Weight for age
            <br /> Z score (SU/U/N/
            <br /> PW/O)
          </span>
        </td>
        <td rowSpan={3} colSpan={2}>
          <b>
            Maternal eMTCT
            <br /> Status
          </b>
        </td>
      </tr>
      <tr>
        <td rowSpan={2} colSpan={2}>
          {child?.childVaccines?.[4]?.vaccineSchedules[1]?.status == "T" &&
          child?.childVaccines?.[4]?.vaccineSchedules[0]?.status == "T" &&
          child?.childVaccines?.[1]?.vaccineSchedules[0]?.status == "T"
            ? "T"
            : child?.childVaccines?.[4]?.vaccineSchedules[1]?.status == "D"
            ? "L"
            : ""}
        </td>
        <td rowSpan={2}>
          <span className="text-color_758092CC">INR No.</span>
        </td>
        <td rowSpan={1} colSpan={1}>
          {child?.childVaccines?.[3]?.vaccineSchedules[0]?.vaccinatedDate
            ? formatDateAsscen(
                child?.childVaccines?.[3]?.vaccineSchedules[0]?.vaccinatedDate,
                "/"
              )
            : ""}
        </td>
        <td rowSpan={1} colSpan={1}>
          {child?.childVaccines?.[3]?.vaccineSchedules[0]?.status == "T"
            ? "T"
            : child?.childVaccines?.[3]?.vaccineSchedules[0]?.status == "D"
            ? "L"
            : ""}
        </td>
        <td rowSpan={2}>
          <span className="text-color_758092CC">INR No.</span>
        </td>
        <td rowSpan={2}>
          <span className="text-color_758092CC">INR No.</span>
        </td>
        <td rowSpan={2}>
          <span className="text-color_758092CC">INR No.</span>
        </td>
      </tr>
      <tr>
        <td rowSpan={1} colSpan={1}>
          {child?.childVaccines?.[5]?.vaccineSchedules[0]?.vaccinatedDate
            ? formatDateAsscen(
                child?.childVaccines?.[5]?.vaccineSchedules[0]?.vaccinatedDate,
                "/"
              )
            : ""}
        </td>
        <td rowSpan={1} colSpan={1}>
          {child?.childVaccines?.[5]?.vaccineSchedules[0]?.status == "T"
            ? "T"
            : child?.childVaccines?.[5]?.vaccineSchedules[0]?.status == "D"
            ? "L"
            : ""}
        </td>
        <td>
          {child?.childVaccines?.[1]?.vaccineSchedules[2]?.status == "T"
            ? "T"
            : child?.childVaccines?.[1]?.vaccineSchedules[2]?.status == "D"
            ? "L"
            : ""}
        </td>
        <td>
          {child?.childVaccines?.[3]?.vaccineSchedules[1]?.status == "T"
            ? "T"
            : child?.childVaccines?.[3]?.vaccineSchedules[1]?.status == "D"
            ? "L"
            : ""}
        </td>
        <td>
          {child?.childVaccines?.[5]?.vaccineSchedules[1]?.status == "T"
            ? "T"
            : child?.childVaccines?.[5]?.vaccineSchedules[1]?.status == "D"
            ? "L"
            : ""}
        </td>
        <td>
          {child?.childVaccines?.[4]?.vaccineSchedules[2]?.status == "T"
            ? "T"
            : child?.childVaccines?.[4]?.vaccineSchedules[2]?.status == "D"
            ? "L"
            : ""}
        </td>
        <td>
          {child?.childVaccines?.[2]?.vaccineSchedules[1]?.status == "T"
            ? "T"
            : child?.childVaccines?.[2]?.vaccineSchedules[1]?.status == "D"
            ? "L"
            : ""}
        </td>

        <td>
          {child?.childVaccines?.[1]?.vaccineSchedules[3]?.status == "T"
            ? "T"
            : child?.childVaccines?.[1]?.vaccineSchedules[3]?.status == "D"
            ? "L"
            : ""}
        </td>
        <td>
          {child?.childVaccines?.[3]?.vaccineSchedules[2]?.status == "T"
            ? "T"
            : child?.childVaccines?.[3]?.vaccineSchedules[2]?.status == "D"
            ? "L"
            : ""}
        </td>
        <td>
          {child?.childVaccines?.[5]?.vaccineSchedules[2]?.status == "T"
            ? "T"
            : child?.childVaccines?.[5]?.vaccineSchedules[2]?.status == "D"
            ? "L"
            : ""}
        </td>
        <td>
          {child?.childVaccines?.[4]?.vaccineSchedules[3]?.status == "T"
            ? "T"
            : child?.childVaccines?.[4]?.vaccineSchedules[3]?.status == "D"
            ? "L"
            : ""}
        </td>
        <td>
          {child?.childVaccines?.[2]?.vaccineSchedules[2]?.status == "T"
            ? "T"
            : child?.childVaccines?.[2]?.vaccineSchedules[2]?.status == "D"
            ? "L"
            : ""}
        </td>

        <td>
          {child?.childVaccines?.[6]?.vaccineSchedules[0]?.status == "T"
            ? "T"
            : child?.childVaccines?.[6]?.vaccineSchedules[0]?.status == "D"
            ? "L"
            : ""}
        </td>
        <td>
          {child?.childVaccines?.[6]?.vaccineSchedules[1]?.status == "T"
            ? "T"
            : child?.childVaccines?.[6]?.vaccineSchedules[1]?.status == "D"
            ? "L"
            : ""}
        </td>
        <td>
          {child?.childVaccines?.[6]?.vaccineSchedules[2]?.status == "T"
            ? "T"
            : child?.childVaccines?.[6]?.vaccineSchedules[2]?.status == "D"
            ? "L"
            : ""}
        </td>
        <td>
          <span className="text-color_758092CC">INR No.</span>
        </td>

        <td>
          {child?.childVaccines?.[7]?.vaccineSchedules[0]?.status == "T"
            ? "T"
            : child?.childVaccines?.[7]?.vaccineSchedules[0]?.status == "D"
            ? "L"
            : ""}
        </td>
        <td>
          {child?.childVaccines?.[8]?.vaccineSchedules[0]?.status == "T"
            ? "T"
            : child?.childVaccines?.[8]?.vaccineSchedules[0]?.status == "D"
            ? "L"
            : ""}
        </td>
        <td>
          <span className="text-color_758092CC">INR No.</span>
        </td>
        <td>
          {child?.childVaccines?.[6]?.vaccineSchedules[3]?.status == "T"
            ? "T"
            : child?.childVaccines?.[6]?.vaccineSchedules[3]?.status == "D"
            ? "L"
            : ""}
        </td>
        <td>
          {child?.childVaccines?.[8]?.vaccineSchedules[1]?.status == "T"
            ? "T"
            : child?.childVaccines?.[8]?.vaccineSchedules[1]?.status == "D"
            ? "L"
            : ""}
        </td>
        <td>
          <span className="text-color_758092CC">INR No.</span>
        </td>
      </tr>
    </>
  );
};

export default ChildRegisterTableBody;
