// src/components/Toaster.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../scss/common/toaster.scss";
import { clearNotifications } from "../../store/slices/toaster-slice";

const Toaster = () => {
  const notification = useSelector((state) => state.toaster);
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();

  const handleClear = () => {
    dispatch(clearNotifications());
  };
  useEffect(() => {
    setIsVisible(true);

    const timeout = setTimeout(() => {
      setIsVisible(false);
    }, 4000);

    return () => clearTimeout(timeout);
  }, [notification]);
  return (
    <>
      {notification?.message ? (
        <div
          className={`toaster ${notification.type} ${isVisible && "visible"}`}
        >
          <div className={`notification `}>
            <span>{notification.message}</span>
            <span onClick={handleClear} className="pl-4 cursor-pointer">
              x
            </span>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Toaster;
