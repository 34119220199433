import React from "react";
import ComponentLoader from "./ComponentLoader";

const TableLoader = () => {
  return (
    <tr>
      <td colSpan={1000} className="!p-0 border-none">
        <span className="relative w-full h-[100px] bg-[rgba(0,0,0,0.02)] flex justify-center items-center">
          <span className="loader"></span>
        </span>
      </td>
    </tr>
  );
};

export default TableLoader;
