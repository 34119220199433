import React from "react";
import { CrossIcon } from "../../assets/Icons";

const Popup = ({
  title,
  children,
  width = "700px",
  height = "600px",
  closeBtn,
  closeHandler,
}) => {
  return (
    <div className="fixed w-full h-full left-0 top-0 z-[999] popup-open bg-[rgba(0,0,0,0.2)]">
      <span
        className="fixed w-full h-full left-0 top-0 bg-popupBg z-[10]"
        onClick={closeHandler}
      />
      <div
        className={`absolute left-1/2 top-1/2 bg-white -translate-x-1/2 -translate-y-1/2 w-full z-[12] h-auto max-h-[750px] border-borderColor shadow-card-shadow max-md:w-[95%] overflow-hidden`}
        style={{ maxWidth: width, maxHeight: height }}
      >
        <div
          className={`${
            closeBtn
              ? "lg:p-[15px] lg:pl-[20px] py-2 px-2.5"
              : "lg:py-[20px] lg:px-[20px] py-2 px-2.5"
          } bg-[#fafafa] flex justify-between items-center`}
        >
          <div>
            <h4 className="h-monda xl:text-xl text-base font-semibold">{title}</h4>
          </div>
          {closeBtn && (
            <div onClick={closeHandler} className="cursor-pointer">
              <CrossIcon />
            </div>
          )}
        </div>
        <div
          className="xl:p-6 lg:p-5 sm:p-5 p-2.5 overflow-y-auto"
          style={{ maxHeight: `calc(${height} - 60px)` }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Popup;
