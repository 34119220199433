import React from "react";
import { Link } from "react-router-dom";
import { AcceptIcon } from "../../assets/Icons";
import TableLoader from "../common/loader/TableLoader";
import TableNoDataFound from "../common/loader/TableNoDataFound";

const HPVDefaulterTable = ({ lists, loading }) => {
  return (
    <div className="defaulter-table">
      <table className="">
        <thead>
          <tr>
            <td>#</td>
            <td>Client No.</td>
            <td>Client Name</td>
            <td>Vaccine</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <TableLoader />
          ) : lists?.length > 0 ? (
            lists.map((list, index) => {
              return (
                <tr key={list?.id}>
                  <td>{index + 1}</td>
                  <td className="text-base">{list?.childNo}</td>
                  <td>{list?.name}</td>
                  <td className="">
                    <span className="flex flex-wrap gap-2">
                      {list?.vaccine?.length > 0 &&
                        list?.vaccine.map((vac) => {
                          return (
                            <span
                              key={vac.id}
                              className="px-2 py-0.5 rounded-lg bg-danger text-white md:text-xs text-[10px] uppercase"
                            >
                              {vac?.name}
                            </span>
                          );
                        })}
                    </span>
                  </td>
                  <td>
                    <span className="flex gap-4 items-center justify-end">
                      <Link
                        to=""
                        className="text-color_01F17E whitespace-nowrap flex gap-4 items-center"
                      >
                        <span className="cursor-pointer">
                          <AcceptIcon />
                        </span>
                        Send Reminder
                      </Link>
                    </span>
                  </td>
                </tr>
              );
            })
          ) : (
            <TableNoDataFound />
          )}
        </tbody>
      </table>
    </div>
  );
};

export default HPVDefaulterTable;
