import React from "react";
import TableLoader from "../loader/TableLoader";

const TableWithLeftAndTopHeader = ({
  tableHeader,
  tableLeftHeaderWithData = [],
  childId,
  updateData,
  isLoading,
  refreshTable,
}) => {
  const handleCheckboxChange = (e, currentValue, vaccine) => {
    const updatedParam = {
      status: e.target.value === "P" ? "T" : e.target.value === "M" ? "D" : "P",
      mode: "Static",
      childId: childId,
      nutritionScheduleId: [currentValue?.id],
      vaccinatedDate: new Date(),
    };

    updateData(updatedParam);
  };

  return (
    <div>
      <table className="w-full border-collapse border border-slate-400">
        <thead className="w-full">
          <tr>
            {tableHeader?.map((item, headerIndex) => {
              return (
                <td
                  key={headerIndex}
                  className={`border border-slate-300 ${
                    headerIndex === 0
                      ? "p-3 text-left font-semibold text-[16px]"
                      : "text-[12px] font-medium text-center"
                  }`}
                >
                  {item}
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <TableLoader />
          ) : (
            tableLeftHeaderWithData?.length > 0 &&
            tableLeftHeaderWithData.map((rowItems, index) => {
              return (
                <tr key={index}>
                  <td className="border border-slate-300 p-3 font-medium xl:text-base md:text-sm text-xs">
                    {(rowItems?.nutritionName).replace(/_/g, " ")}
                  </td>
                  {rowItems?.nutritionSchedules.map((item, idx) => {
                    const currentDate = new Date();
                    return (
                      <td
                        key={idx}
                        className={`border border-slate-300 text-[12px] font-medium text-center`}
                      >
                        <input
                          type="checkbox"
                          name={"Vaccine checkbox"}
                          value={item?.status}
                          checked={
                            item?.status === "T" || item?.status === "D"
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            handleCheckboxChange(e, item, rowItems)
                          }
                          className={`specialCare-check form-checkbox cursor-pointer ${
                            new Date(item?.scheduleDate) >= currentDate &&
                            "opacity-50"
                          }`}
                          disabled={new Date(item?.scheduleDate) > currentDate}
                        />
                      </td>
                    );
                  })}
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableWithLeftAndTopHeader;
