import React from "react";
import TableLoader from "../common/loader/TableLoader";
import TableNoDataFound from "../common/loader/TableNoDataFound";

const HPVRegisterTable = ({ list, loading }) => {
  return (
    <div>
      <table className="border-collapse w-full">
        <thead>
          <tr>
            <td>1</td>
            <td>2</td>
            <td>3</td>
            <td>4</td>
            <td>5</td>
            <td>6</td>
            <td>7</td>
            <td>8</td>
            <td>9</td>
            <td>10</td>
            <td>11</td>
            <td>12</td>
          </tr>
          <tr>
            <td>Date</td>
            <td>Serial No/ Card No</td>
            <td>NIN</td>
            <td>Client Name</td>
            <td>
              Client <br />
              Category
              <br /> N/R/F
            </td>
            <td>Date of Birth</td>
            <td>
              Caregiver <br /> Name & Contact
            </td>
            <td>Address</td>
            <td className="vertical-text">Age</td>
            <td className="vertical-text">Class</td>
            <td>HPV1 date & HPV2 date</td>
            <td>POC</td>
          </tr>
        </thead>
      </table>
    </div>
  );
};

export default HPVRegisterTable;
