// src/app/store.js
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
// import { setupListeners } from "@reduxjs/toolkit/query";
import { authApi } from "../apis/authApi";
import userReducer from "./slices/userDetail-slice";
import toasterReducer from "./slices/toaster-slice";
import childVaccineReducer from "./slices/childVaccinesDetail";
import childNutritionReducer from "./slices/childNutritionsDetail";

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    user: userReducer,
    toaster: toasterReducer,
    childVaccine: childVaccineReducer,
    childNutrition: childNutritionReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      authApi.middleware
    ),
});

setupListeners(store.dispatch);
