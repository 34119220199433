import React, { useEffect, useState } from "react";
import ChildVaccinePopup from "../../childRegister/ChildVaccinePopup";
import Popup from "../Popup";
import TableLoader from "../loader/TableLoader";

const headerData = [
  {
    name: "Vaccine",
    id: "Vaccine",
  },
  {
    name: "Birth",
    id: "Birth",
  },
  {
    name: "6 Weeks",
    id: "6 Week",
  },
  {
    name: "10 Weeks",
    id: "10 Week",
  },
  {
    name: "14 Weeks",
    id: "14 Week",
  },
  {
    name: "6 Months",
    id: "6 Month",
  },
  {
    name: "7 Months",
    id: "7 Month",
  },
  {
    name: "8 Months",
    id: "8 Month",
  },
  {
    name: "9 Months",
    id: "9 Month",
  },
  {
    name: "18 Months",
    id: "18 Month",
  },
  {
    name: "Protect Against",
    id: "Protect Against",
  },
  {
    name: "Mode of Administration",
    id: "Mode of Administration",
  },
];

const TableWithDropDowns = ({
  tableData,
  childId,
  childNo,
  refreshTable,
  loading,
}) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [updateVaccineParams, setUpdateVaccineParams] = useState({
    childId: 0,
    childWeight: 0,
    status: "P",
    mode: "Static",
    vaccines: [0],
    vaccinatedDate: "",
  });
  const [vaccineData, setVaccineData] = useState([]);

  const handlePopup = () => {
    setOpenPopup(!openPopup);
  };

  const handleValueChange = (currentValue, vaccine) => {
    let localUpdateVaccineParams = { ...updateVaccineParams };
    localUpdateVaccineParams = {
      childId: childId,
      childWeight: 0,
      status: currentValue?.status,
      mode: "Static",
      vaccines: [currentValue?.id],
      vaccineName: currentValue?.vaccineTypeName,
      scheduleDate: currentValue?.scheduleDate,
    };
    setUpdateVaccineParams(localUpdateVaccineParams);
    setVaccineData([vaccine]);
    handlePopup();
  };

  return (
    <div className="pb-3 overflow-auto scrollbar scrollbar-w-1.5 scrollbar-h-1.5 scrollbar-thumb-primary scrollbar-track-slate-200">
      <table className="w-full  border-collapse">
        <tr className="bg-primary rounded-xl">
          {headerData?.map((headerItem, index) => {
            return (
              <th
                className="text-white xl:text-xs text-[12px] lg:text-[14px] font-semibold leading-normal whitespace-nowrap py-2 px-3 border border-white"
                key={index}
              >
                {headerItem.name}
              </th>
            );
          })}
        </tr>
        {loading ? (
          <TableLoader />
        ) : (
          tableData.map((rowsItem, idx) => {
            return (
              <tr className="!max-h-[42px]" key={idx}>
                {headerData.map((item, index) => {
                  const scheduleItem = rowsItem?.vaccineSchedules.find(
                    (it) => it.scheduleAt === item.id
                  );
                  return (
                    <>
                      {index === 0 ? (
                        <td
                          className="h-[42px] font-semibold text-[11px] lg:text-[12px] shadow-[0px_1.36563px_15.70479px_0px_#E4E6E9] min-w-[200px] py-1 px-3"
                          key={index}
                        >
                          {rowsItem?.vaccineName.replace(/_/g, " ")}
                        </td>
                      ) : index === 10 ? (
                        <td className="h-[42px] text-[11px] lg:text-[12px] shadow-[0px_1.36563px_15.70479px_0px_#E4E6E9] min-w-[280px] py-1 px-3">
                          {rowsItem?.protectAgainst}
                        </td>
                      ) : index === 11 ? (
                        <td className="h-[42px] text-[11px] lg:text-[12px] shadow-[0px_1.36563px_15.70479px_0px_#E4E6E9] min-w-[280px] py-1 px-3">
                          {rowsItem?.modeOfAdministration}
                        </td>
                      ) : scheduleItem ? (
                        <td
                          key={index}
                          className={`!min-w-[110px] text-[11px] text-white font-bold lg:text-[12px] py-1 px-3 h-[42px] whitespace-nowrap shadow-[0px_1.36563px_15.70479px_0px_#E4E6E9]  box-border ${
                            scheduleItem?.status === "T"
                              ? "bg-color_01F17E "
                              : scheduleItem?.status === "D"
                              ? "bg-warning"
                              : scheduleItem?.status === "M"
                              ? "bg-danger"
                              : "bg-[rgba(113,_97,_239,_0.70)]"
                          } cursor-pointer text-center`}
                          onClick={() =>
                            handleValueChange(scheduleItem, rowsItem)
                          }
                        >
                          {scheduleItem?.vaccineTypeName}
                        </td>
                      ) : (
                        <td className="h-[42px] py-1 px-3 shadow-[0px_1.36563px_15.70479px_0px_#E4E6E9]">
                        </td>
                      )}
                    </>
                  );
                })}
              </tr>
            );
          })
        )}
      </table>

      {openPopup && (
        <Popup
          title="Update Vaccine Details"
          width="600px"
          closeBtn={true}
          closeHandler={handlePopup}
        >
          <ChildVaccinePopup
            closePopup={handlePopup}
            data={vaccineData}
            currentValue={updateVaccineParams}
            childId={childId}
            childNo={childNo}
            refreshTable={refreshTable}
          />
        </Popup>
      )}
    </div>
  );
};

export default TableWithDropDowns;
