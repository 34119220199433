import React from "react";
import { useSelector } from "react-redux";

const AppHeader = ({ mobileMenuHandler, menuOpen }) => {
  const { userDetails } = useSelector((state) => state.user);
  return (
    <div className="app-header lg:py-6 lg:px-7 p-5 flex items-center bg-white justify-between">
      <div
        className="lg:hidden p-2.5 mr-3 relative z-[100] cursor-pointer"
        onClick={mobileMenuHandler}
      >
        <span className={`headerHamburger ${menuOpen && "active"}`}></span>
      </div>
      <div className="breadcrumb"></div>
      {userDetails?.name && (
        <div className="flex items-center gap-4">
          <div className="w-8 h-8 rounded-full bg-gray"></div>
          <p className="lg:text-base text-sm font-medium tracking-[-0.16px] capitalize">
          {userDetails?.title} {userDetails?.name}
          </p>
        </div>
      )}
    </div>
  );
};

export default AppHeader;
