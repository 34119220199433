import React, { useEffect, useState } from "react";
import {
  BlankCheckboxIcon,
  CheckedCheckboxIcon,
} from "../../../../assets/Icons";

const RadioInput = (props) => {
  const {
    className,
    label,
    name,
    options,
    changeEvent,
    isErrorMsg = null,
  } = props;
  const [selectedOption, setSelectedOption] = useState("");

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
    changeEvent(e);
  };

  return (
    <div
      className={`input-wrapper flex gap-2.5 flex-col ${
        isErrorMsg ? "error" : ""
      }`}
    >
      <label className="input-label">{label}</label>

      <div className="">
        <div className="flex gap-4 flex-wrap pt-1.5">
          {options?.map((option, index) => (
            <div className="flex gap-2 items-center" key={index}>
              <div className="w-[23px] h-[23px] relative p-[1.5px]">
                <input
                  type="radio"
                  className="custom-radio"
                  name={name}
                  value={option.name}
                  checked={selectedOption === option.name}
                  onChange={handleRadioChange}
                />
                <span className="absolute left-0 top-0"></span>
              </div>

              <div className="text-font lg:text-sm text-xs">{option.name}</div>
            </div>
          ))}
        </div>
        {isErrorMsg !== null && (
          <p className="error-text">
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M8 12.173a0.591 0.591 0 1 0 0 -1.182 0.591 0.591 0 0 0 0 1.182Zm0 -2.123a0.444 0.444 0 0 1 -0.445 -0.445v-5.333a0.444 0.444 0 1 1 0.889 0v5.333a0.444 0.444 0 0 1 -0.444 0.444Z"
                  fill="red"
                />
                <path
                  d="M8 15.111a7.112 7.112 0 1 1 0 -14.222 7.112 7.112 0 0 1 0 14.222Zm0 -13.333a6.222 6.222 0 1 0 0 12.445 6.222 6.222 0 0 0 0 -12.445Z"
                  fill="red"
                />
              </svg>
            </span>
            {isErrorMsg}
          </p>
        )}
      </div>
    </div>
  );
};

export default RadioInput;
