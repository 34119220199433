import React from "react";
import { formatDate } from "../../utils/helper";
import useMediaQuery from "../common/mediaQuery/MediaQueryHook";

const HPVVaccinationTable = ({ data }) => {
  const isLargeScreen = useMediaQuery("(min-width: 768px)");

  return (
    <div className="vaccination-table">
      <table className="">
        <thead>
          {isLargeScreen ? (
            <tr>
              <td>Details</td>
              <td>Information</td>
              <td>Details</td>
              <td>Information</td>
            </tr>
          ) : (
            <tr>
              <td>Details</td>
              <td>Information</td>
            </tr>
          )}
        </thead>
        {isLargeScreen ? (
          <tbody>
            <tr>
              <td>Client No.</td>
              <td className="font-bold">{data?.childNo ? data?.childNo : "---"}</td>
              <td>Client Name</td>
              <td className="font-bold">{data?.name ? data?.name : "---"}</td>
            </tr>
            <tr>
              <td>Client Category</td>
              <td className="font-bold">
                {data?.category ? data?.category : "---"}
              </td>
              <td>Sex</td>
              <td className="font-bold">
                {data?.gender ? data?.gender : "---"}
              </td>
            </tr>
            <tr>
              <td>Date of Birth</td>
              <td className="font-bold">{data?.dob ? data?.dob : "---"}</td>
              <td>Age</td>
              <td className="font-bold">{data?.dob ? data?.dob : "---"}</td>
            </tr>
            <tr>
              <td>District/City</td>
              <td className="font-bold">
                {data?.address?.district ? data?.address?.district : "---"}
              </td>
              <td>Sub Country/ Division</td>
              <td className="font-bold">
                {data?.address?.sub_county ? data?.address?.sub_county : "---"}
              </td>
            </tr>
            <tr>
              <td>Parish/Ward:</td>
              <td className="font-bold">
                {data?.address?.ward ? data?.address?.ward : "---"}
              </td>
              <td>LC 1/Village/Cell</td>
              <td className="font-bold">
                {data?.address?.village ? data?.address?.village : "---"}
              </td>
            </tr>
            <tr>
              <td>Caretaker:</td>
              <td className="font-bold">
                {data?.careTaker ? data?.careTaker : "---"}
              </td>
              <td>Name of School</td>
              <td className="font-bold">
                {data?.schoolName ? data?.schoolName : "---"}
              </td>
            </tr>
            <tr>
              <td>Class in school</td>
              <td className="font-bold">
                {data?.schoolClass ? data?.schoolClass : "---"}
              </td>
              <td>Contact:</td>
              <td className="font-bold">
                {data?.contact ? data?.contact : "---"}
              </td>
            </tr>
            <tr>
              <td>NIN</td>
              <td className="font-bold">{data?.nin ?? "---"}</td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td>Client No.</td>
              <td className="font-bold">{data?.childNo ? data?.childNo : "---"}</td>
            </tr>
            <tr>
              <td>Client Name</td>
              <td className="font-bold">{data?.name ? data?.name : "---"}</td>
            </tr>
            <tr>
              <td>Client Category</td>
              <td className="font-bold">
                {data?.category ? data?.category : "---"}
              </td>
            </tr>
            <tr>
              <td>Sex</td>
              <td className="font-bold">
                {data?.gender ? data?.gender : "---"}
              </td>
            </tr>
            <tr>
              <td>NIN</td>
              <td className="font-bold">{data?.nin ?? "---"}</td>
            </tr>
            <tr>
              <td>Date of Birth</td>
              <td className="font-bold">{data?.dob ? data?.dob : "---"}</td>
            </tr>
            <tr>
              <td>Age</td>
              <td className="font-bold">{data?.dob ? data?.dob : "---"}</td>
            </tr>
            <tr>
              <td>District/City</td>
              <td className="font-bold">
                {data?.address?.district ? data?.address?.district : "---"}
              </td>
            </tr>
            <tr>
              <td>Sub Country/ Division</td>
              <td className="font-bold">
                {data?.address?.sub_county ? data?.address?.sub_county : "---"}
              </td>
            </tr>
            <tr>
              <td>Parish/Ward:</td>
              <td className="font-bold">
                {data?.address?.ward ? data?.address?.ward : "---"}
              </td>
            </tr>
            <tr>
              <td>LC 1/Village/Cell</td>
              <td className="font-bold">
                {data?.address?.village ? data?.address?.village : "---"}
              </td>
            </tr>
            <tr>
              <td>Contact:</td>
              <td className="font-bold">
                {data?.contact ? data?.contact : "---"}
              </td>
            </tr>
            <tr>
              <td>Created At</td>
              <td className="font-bold">
                {data?.created_at ? formatDate(data?.created_at) : "---"}
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
};

export default HPVVaccinationTable;
