import React, { useEffect, useState } from "react";
import { EditIcon } from "../../assets/Icons";
import AppointmentTable from "../../components/childRegister/AppointmentTable";
import Button from "../../components/common/Button";
import MinistryLabel from "../../components/common/MinistryLabel/MinistryLabel";
import "../../scss/childRegister/appointment.scss";
import Dropdown from "../../components/common/Dropdown";
import { useGetVaccinationAppointmentsMutation } from "../../apis/authApi";
import { useDispatch } from "react-redux";
import { addNotification } from "../../store/slices/toaster-slice";
import Popup from "../../components/common/Popup";
import AppointmentPopup from "../../components/childRegister/AppointmentPopup";
import CustomPagination from "../../components/common/pagination/CustomPagination";
import { useNavigate } from "react-router-dom";
import HPVAppointmentTable from "../../components/hpv/HPVAppointmentTable";

const HPVAppointments = () => {
  const [getAppointments, appointmentsLoading] =
    useGetVaccinationAppointmentsMutation();
  const [appointmentLists, setAppointmentLists] = useState({});
  const [openPopup, setOpenPopup] = useState(false);
  const [vaccineData, setVaccineData] = useState({});
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    handleGetAppointment();
  }, [page]);

  const handleGetAppointment = async () => {
    try {
      const query = {
        page: page,
      };
      let response = await getAppointments(query).unwrap();
      setAppointmentLists(response);
    } catch (error) {
      if (error?.status == 401 || error?.status == 403) {
        sessionStorage.removeItem("access_token");
        navigate("/login");
        dispatch(
          addNotification({
            type: "error",
            message: "Token is invalid or expired",
          })
        );
      } else {
        dispatch(
          addNotification({ type: "error", message: "Unable to fetch!" })
        );
      }
    }
  };

  const handlePopup = () => {
    setOpenPopup(!openPopup);
  };

  return (
    <div className="child-appointment xl:px-[35px] xl:py-12 lg:px-5 lg:py-7 p-4">
      <div className="flex gap-3 justify-between md:items-end max-md:flex-col">
        <div>
          <MinistryLabel
            title={`Appointments List - ${new Date().toLocaleDateString(
              "en-GB"
            )}`}
          />
        </div>
      </div>

      <div className="mt-8 bg-white rounded-xl overflow-auto ">
        <HPVAppointmentTable
          lists={appointmentLists?.results}
          handlePopup={handlePopup}
          setVaccineData={setVaccineData}
          loading={appointmentsLoading?.isLoading}
        />
      </div>
      {appointmentLists?.count > 10 && (
        <CustomPagination
          setPage={setPage}
          page={page}
          count={appointmentLists?.count}
        />
      )}

      {openPopup && (
        <Popup
          title="Vaccination Details"
          width="600px"
          closeBtn={true}
          closeHandler={handlePopup}
        >
          <AppointmentPopup
            closePopup={handlePopup}
            data={vaccineData}
            refreshTable={handleGetAppointment}
          />
        </Popup>
      )}
    </div>
  );
};

export default HPVAppointments;
