import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  AddIcon,
  DefaulterIcon,
  HomeIcon,
  InfoIcon,
  RegisterIcon,
  ScheduleIcon,
  SearchChildIcon,
  SearchIcon,
  SubmenuIcon,
} from "../../../assets/Icons";

const ChildRegisterMenu = () => {
  const { pathname } = useLocation();

  return (
    <>
      <ul className="menu">
        <li>
          <Link
            to="/dashboard"
            className={`menu-link ${pathname === "/dashboard" && "active"}`}
          >
            <span>
              <HomeIcon />
            </span>
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/child-register"
            className={`menu-link ${
              pathname === "/child-register" && "active"
            }`}
          >
            <span>
              <RegisterIcon />
            </span>
            Child Register Book
          </Link>
        </li>
        <li>
          <Link
            to="/add-child"
            className={`menu-link ${pathname === "/add-child" && "active"}`}
          >
            <span className="">
              <AddIcon />
            </span>
            Add New Child
          </Link>
        </li>
        <li>
          <Link
            to="/vaccination-card"
            className={`menu-link ${
              pathname.includes("/vaccination-card") && "active"
            }`}
          >
            <span className="relative -top-1">
              <InfoIcon />
            </span>
            Vaccination Card
          </Link>
        </li>
        <li>
          <Link
            to="/search-child"
            className={`menu-link ${
              pathname.includes("/search-child") && "active"
            }`}
          >
            <span className="relative -top-1">
              <SearchChildIcon />
            </span>
            Search Child
          </Link>
        </li>
        <li>
          <Link
            to="/vaccination-appointments"
            className={`menu-link ${
              pathname === "/vaccination-appointments" && "active"
            }`}
          >
            <span>
              <ScheduleIcon />
            </span>
            Vaccination Appointments
          </Link>
        </li>
        <li>
          <Link
            to="/vaccination-defaulters"
            className={`menu-link ${
              pathname === "/vaccination-defaulters" && "active"
            }`}
          >
            <span>
              <DefaulterIcon />
            </span>
            Vaccination Defaulter’s
          </Link>
        </li>
      </ul>
    </>
  );
};

export default ChildRegisterMenu;
