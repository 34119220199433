import React, { useState } from "react";
import {
  AdminIcon,
  CheckedIcon,
  LeftChevron,
  UserIcon,
  UsersIcon,
} from "../../assets/Icons";
import Button from "../common/Button";

const AccountType = ({
  handleSteps,
  handleAccountType,
  accountType,
  pendingHospital = "",
}) => {
  return (
    <div className="choose-account lg:pb-8 xl:pt-[100px] xl:px-[50px] lg:pt-[70px] lg:px-[40px] md:p-8 p-5 md:pt-[70px] pt-[70px] flex flex-col h-full max-h-[calc(100%-44px)] relative">
      {pendingHospital !== "False" && (
        <div
          className="absolute sm:left-6 left-4 top-6 cursor-pointer flex items-center gap-2"
          onClick={() => handleSteps("chooseAccount", "")}
        >
          <LeftChevron />{" "}
          <span className="text-base text-color_758092">Back</span>
        </div>
      )}
      <div className="lg:max-w-[450px] flex flex-col justify-between flex-1">
        <div className="flex-1">
          <div>
            <h3 className="xl:text-3xl lg:text-2xl md:text-xl text-lg h-inter font-bold">
              Choose Health worker Account Type
            </h3>
            <p className="text-color_8692A6 xl:text-lg md:text-base text-sm pt-4 h-inter">
              To begin this journey, tell us what type of account you’d be.
            </p>
          </div>
          <div className="lg:mt-10 lg:mb-8 my-6 flex flex-col gap-6">
            <div
              className={`account rounded-md relative bg-white shadow-cardShadow px-4 lg:py-6 py-4 cursor-pointer flex lg:gap-4 gap-2 justify-between items-center transition-all duration-300 border hover:border-primary hover:scale-105 max-sm:flex-col-reverse ${
                accountType === "child"
                  ? "active border-primary"
                  : "border-transparent"
              }`}
              onClick={() => handleAccountType("child")}
            >
              <div className="flex lg:gap-4 gap-3 items-center w-full">
                <div className="icon-shape">
                  <UserIcon />
                </div>
                <div className="flex-1">
                  <h5 className="text-base font-medium">Child</h5>
                  <p className="md:text-sm text-xs text-color_8692A6 lg:pt-4 pt-2">
                    You can manage Child Registry
                  </p>
                </div>
              </div>
              {accountType === "child" && (
                <div className="max-sm:absolute top-4 right-4">
                  <CheckedIcon />
                </div>
              )}
            </div>
            <div
              className={`account rounded-md relative bg-white shadow-cardShadow px-4 lg:py-6 py-4 cursor-pointer flex lg:gap-4 gap-2 justify-between items-center transition-all duration-300 border hover:border-primary hover:scale-105 max-sm:flex-col-reverse ${
                accountType === "td"
                  ? "active border-primary"
                  : "border-transparent"
              }`}
              onClick={() => handleAccountType("td")}
            >
              <div className="flex lg:gap-4 gap-3 items-center w-full">
                <div className="icon-shape">
                  <UsersIcon />
                </div>
                <div>
                  <h5 className="text-base font-medium">TD</h5>
                  <p className="md:text-sm text-xs text-color_8692A6 lg:pt-4 pt-2">
                    You can manage TD Registry
                  </p>
                </div>
              </div>
              {accountType === "td" && (
                <div className="max-sm:absolute top-4 right-4">
                  <CheckedIcon />
                </div>
              )}
            </div>
            <div
              className={`account rounded-md relative bg-white shadow-cardShadow px-4 lg:py-6 py-4 cursor-pointer flex lg:gap-4 gap-2 justify-between items-center transition-all duration-300 border hover:border-primary hover:scale-105 max-sm:flex-col-reverse ${
                accountType === "hpv"
                  ? "active border-primary"
                  : "border-transparent"
              }`}
              onClick={() => handleAccountType("hpv")}
            >
              <div className="flex lg:gap-4 gap-3 items-center w-full">
                <div className="icon-shape">
                  <AdminIcon />
                </div>
                <div>
                  <h5 className="text-base font-medium">HPV</h5>
                  <p className="md:text-sm text-xs text-color_8692A6 lg:pt-4 pt-2">
                    You can Manage HVC Registry
                  </p>
                </div>
              </div>
              {accountType === "hpv" && (
                <div className="max-sm:absolute top-4 right-4">
                  <CheckedIcon />
                </div>
              )}
            </div>
          </div>
          <div>
            <Button
              type="button"
              btnType="normal"
              clickHandler={() =>
                handleSteps(
                  pendingHospital !== "False"
                    ? "registerForm"
                    : "selectHospital",
                  accountType
                )
              }
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountType;
