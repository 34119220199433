export const BASE_URL = "https://api.medrefug.com/v1"; // For Production
// export const BASE_URL = "https://api-dev.medrefug.com/v1"; // For Development
// export const BASE_URL =
//   "https://9fb78468001bf62666e29a5d415677eb.serveo.net/v1";

// -------- Regex --------
export const regEx = {
  NAME: /^[a-zA-Z ]{2,20}$/,
  EMAIL: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
  // PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
  PHONE: /^[0-9]{3}[-\s.]?[0-9]{3}[-\s.]?[0-9]{3}$/im,
  PASSWORD: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
};

export const formatDate = (inputDate, dash = '-') => {
  const date = new Date(inputDate);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}${dash}${month}${dash}${day}`;
};
export const formatDateAsscen = (inputDate, dash = '/') => {
  const date = new Date(inputDate);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${day}${dash}${month}${dash}${year}`;
};

// Calculate Age in Years
export const calculateAge = dob => {
  if (dob) {
    let dobDate = new Date(dob);
    const currentDate = new Date();

    let calculatedAge = currentDate.getFullYear() - dobDate.getFullYear();

    if (
      currentDate.getMonth() < dobDate.getMonth() ||
      (currentDate.getMonth() === dobDate.getMonth() &&
        currentDate.getDate() < dobDate.getDate())
    ) {
      calculatedAge -= 1;
    }

    return calculatedAge;
  }
};

// Calculate Age in months
export const calculateAgeInMonths = (dob, futureDate = new Date()) => {
  let dobDate = new Date(dob);
  let currentDate = new Date(futureDate);

  let years = currentDate.getFullYear() - dobDate.getFullYear();
  let months = currentDate.getMonth() - dobDate.getMonth();
  let days = currentDate.getDate() - dobDate.getDate();

  let ageInMonths = years * 12 + months;

  if (days < 0) {
    ageInMonths -= 1;
  }

  // Check if age is less than 1 month
  if (ageInMonths < 1) {
    ageInMonths = 1;
  }

  return ageInMonths;
};

// ------------------------ Debounce -------------------------
export const debounce = (func, delay) => {
  let timeoutId;

  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

export const calculateDateDiff = (from, to) => {
  const MS_PER_DAY = 24 * 60 * 60 * 1000; // milliseconds per day
  const startDate = new Date(from);
  const endDate = new Date(to);
  const timeDifference = endDate - startDate;
  const diffInDays = Math.floor(timeDifference / MS_PER_DAY);

  return diffInDays;
};
