import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useResetPasswordMutation } from "../../apis/authApi";
import { Logo } from "../../assets/Icons";
import Button from "../../components/common/Button";
import Input from "../../components/form/input";
import AccountFooterLink from "../../components/register/AccountFooterLink";
import { addNotification } from "../../store/slices/toaster-slice";
import { regEx } from "../../utils/helper";

const validateLoginForm = (data) => {
  let errors = {};
  let isValid = true;

  if (!data?.password) {
    errors.password = "Password is required";
    isValid = false;
  } else if (!regEx.PASSWORD.test(data?.password)) {
    errors.password =
      "Password must be between 8 to 15 characters, should contain at least one lowercase letter, one uppercase letter, one digit, and one special character";
    isValid = false;
  }
  if (!data?.cnfPassword) {
    errors.cnfPassword = "Password is required";
    isValid = false;
  } else if (!regEx.PASSWORD.test(data?.cnfPassword)) {
    errors.cnfPassword =
      "Password must be between 8 to 15 characters, should contain at least one lowercase letter, one uppercase letter, one digit, and one special character";
    isValid = false;
  }
  if (data?.password !== data?.cnfPassword) {
    errors.cnfPassword = "Confirm password is not same";
    isValid = false;
  }
  return { isValid, errors };
};

const initialState = {
  password: "",
  verificationCode: "",
};

const ResetPassword = () => {
  const { state } = useLocation();
  const [formData, setFormData] = useState(initialState);
  const [formError, setFormError] = useState({});
  const [resetPassword, resetPasswordLoading] = useResetPasswordMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!state) {
      navigate("/login");
    }
  }, [state]);

  // Function to handle change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((state) => ({ ...state, [name]: value }));
    setFormError((state) => ({ ...state, [name]: "" }));
  };

  // Handle User Login
  const handleResetPassword = async () => {
    try {
      const userData = {
        verificationCode: state,
        password: formData.password,
      };
      const response = await resetPassword(userData).unwrap();
      if (response?.message == "OK") {
        navigate("/login");
      }
      dispatch(
        addNotification({
          type: "success",
          message: "Successfully reset the password",
        })
      );
    } catch (error) {
      dispatch(
        addNotification({ type: "error", message: error?.data?.result })
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { isValid, errors } = validateLoginForm(formData);
    setFormError(errors);

    if (isValid) {
      setFormError({});
      handleResetPassword();
    }
  };
  return (
    <div className="login-page">
      <div className="flex min-h-screen lg:flex-row flex-col">
        <div className="basis-1/2 login-banner bg-cover bg-center bg-no-repeat bg-account-banner flex items-center justify-center max-h-screen sticky top-0 left-0 max-lg:hidden">
          <div className="flex flex-col gap-6 account-logo">
            <Logo />
            <h2 className="xl:text-[35px] lg:text-[28px] text-black">
              Welcome to MedRef
            </h2>
          </div>
        </div>
        <div className="block lg:hidden pt-8">
          <div className="flex flex-col gap-4 items-center account-logo">
            <Logo />
            <div className="text-center">
              <h2 className="text-2xl text-black">Welcome to MedRef</h2>
            </div>
          </div>
        </div>
        <div className=" lg:basis-1/2 basis-full flex-1 pb-8 lg:pb-8 xl:pt-[100px] xl:px-[50px] lg:pt-[70px] lg:px-[40px] md:p-8 p-5 md:pt-[40px] pt-[40px] flex">
          <div className="lg:max-w-[450px] flex flex-col justify-between flex-1">
            <div>
              <div className="">
                <h3 className="xl:text-3xl lg:text-2xl md:text-xl text-lg h-inter font-bold">
                  Reset Your Password!
                </h3>
                <p className="text-color_8692A6 xl:text-lg md:text-base text-sm pt-4 h-inter">
                  For the purpose of MedRef regulation, your details are
                  required.
                </p>
              </div>

              <div className="pt-4 border-t border-[#F5F5F5] mt-[14px]">
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-col lg:gap-6 gap-4">
                    <Input
                      label="New Password"
                      placeholder={"Enter your password"}
                      type="password"
                      className="border border-color_758092 h-[40px] rounded-[6px] px-4 py-2"
                      name="password"
                      changeEvent={handleChange}
                      isErrorMsg={formError?.password}
                      attributes={{ value: formData?.password }}
                    />
                    <Input
                      label="Confirm New Password"
                      placeholder={"Enter your password"}
                      type="password"
                      className="border border-color_758092 h-[40px] rounded-[6px] px-4 py-2"
                      name="cnfPassword"
                      changeEvent={handleChange}
                      isErrorMsg={formError?.cnfPassword}
                      attributes={{ value: formData?.cnfPassword }}
                    />
                  </div>

                  <div className="lg:mt-9 mt-6">
                    <Button
                      type="submit"
                      onClick={handleResetPassword}
                      isLoading={resetPasswordLoading.isLoading}
                    >
                      Reset Password
                    </Button>
                  </div>
                </form>
                <div className="pt-3 lg:hidden">
                  <p className="lg:text-base md:text-base text-sm text-black flex items-center justify-center gap-1 max-md:flex-col">
                    If you don’t have an account You can{" "}
                    <Link to="/register" className="text-primary font-semibold">
                      Register here !
                    </Link>
                  </p>
                </div>
              </div>
            </div>

            <AccountFooterLink />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
