import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAddTDDoseRecordMutation } from "../../apis/authApi";
import { addNotification } from "../../store/slices/toaster-slice";
import { formatDateAsscen } from "../../utils/helper";
import Button from "../common/Button";
import Input from "../form/input";

// 1. Date should be disable and gray if vaccine is timely taken
// 2. User will not be allowed to perform operstion on upcomming dates

const validateRegForm = (data) => {
  let errors = {};
  let isValid = true;

  if (!data?.vaccineMode) {
    errors.vaccineMode = "Vaccination mode is required";
    isValid = false;
  }
  if (data?.vaccineMode == "School" && !data?.clientClass) {
    errors.clientClass = "Class is required";
    isValid = false;
  }
  return { isValid, errors };
};

const HPVVaccinePopup = ({ closePopup, doseData, clientNo }) => {
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState({});
  const [alreadyVaccinated, setAlreadyVaccinated] = useState(false);
  const [futureSchedule, setFutureSchedule] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [addDoseRecord, doseRecordLoading] = useAddTDDoseRecordMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    const currentDate = new Date();
    setAlreadyVaccinated(
      doseData?.vaccinatedDate || doseData?.status === "T" ? true : false
    );
    setFutureSchedule(
      new Date(doseData?.scheduledDate) > currentDate ? true : false
    );
  }, [doseData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevObj) => ({
      ...prevObj,
      [name]: value,
    }));
  };

  const handleUpdateDose = async () => {
    try {
      let vaccineData = {
        clientID: doseData?.clientId,
        doseId: doseData?.doseId,
        mode: formData?.vaccineMode,
        vaccinatedDate: selectedDate ?? new Date(),
      };
      if (formData?.vaccineMode == "School") {
        vaccineData = { ...vaccineData, clientClass: formData?.clientClass };
      }

      // const response = await addDoseRecord(vaccineData).unwrap();
      // dispatch(
      //   addNotification({
      //     type: "success",
      //     message: "Vaccine recorded successfully",
      //   })
      // );
      closePopup();
      // refreshTable(clientNo);
    } catch (error) {
      dispatch(
        addNotification({
          type: "error",
          message:
            error?.data?.child || error?.data?.vaccine || error?.data?.weight,
        })
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { isValid, errors } = validateRegForm(formData);
    setFormError(errors);
    if (isValid) {
      handleUpdateDose();
    }
  };

  useEffect(() => {
    setSelectedDate(doseData?.scheduledDate);
  }, [doseData]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="flex lg:gap-6 gap-4 max-md:flex-col">
          <div className="basis-1/2">
            <Input
              label="Client No."
              type="text"
              isReadOnly={true}
              className="border !border-primary lg:h-[40px] h-[40px] rounded-[6px] px-4 py-2 w-full"
              name="patientId"
              attributes={{ value: clientNo }}
            />
          </div>
          <div className="basis-1/2">
            {alreadyVaccinated ? (
              <Input
                label="Vaccinated On"
                placeholder={"Vaccinated Date"}
                type="text"
                isReadOnly={true}
                className="normal-input"
                name="patientName"
                attributes={{
                  value: formatDateAsscen(doseData?.vaccinatedDate),
                }}
              />
            ) : (
              <Input
                label="Scheduled Date"
                placeholder={"Scheduled Date"}
                type="text"
                isReadOnly={true}
                className="normal-input"
                name="patientName"
                attributes={{
                  value: formatDateAsscen(doseData?.scheduledDate),
                }}
              />
            )}
          </div>
        </div>
        {!futureSchedule && !alreadyVaccinated && (
          <div className="flex lg:gap-6 gap-4 flex-col mt-5">
            <div className="">
              <Input
                label="Vaccination Mode"
                placeholder={"Vaccination Mode"}
                type="radio"
                options={[
                  { name: "Static", id: "static" },
                  { name: "Outreached", id: "outreached" },
                  { name: "School", id: "school" },
                ]}
                isErrorMsg={formError?.vaccineMode}
                className="normal-input"
                changeEvent={handleInputChange}
                name="vaccineMode"
              />
            </div>
            {formData?.vaccineMode === "School" && (
              <div className="w-1/2">
                <Input
                  label="Class in School"
                  type="text"
                  placeholder={"Enter class in school"}
                  className="border !border-primary lg:h-[40px] h-[40px] rounded-[6px] px-4 py-2 w-full"
                  name="clientClass"
                  changeEvent={handleInputChange}
                  isErrorMsg={formError?.clientClass}
                  attributes={{ value: formData?.clientClass }}
                />
              </div>
            )}
          </div>
        )}
        {alreadyVaccinated && (
          <div className="flex lg:gap-6 gap-4 max-md:flex-col mt-5">
            <div className="lg:basis-[calc(50%-12px)] basis-[calc(50%-8px)]">
              <Input
                label="Vaccine Mode"
                type="text"
                isReadOnly={true}
                className="border !border-primary lg:h-[40px] h-[40px] rounded-[6px] px-4 py-2 w-full"
                name="vaccineMode"
                attributes={{ value: doseData?.mode }}
              />
            </div>
          </div>
        )}

        <div className="flex gap-3 items-center lg:mt-9 mt-6">
          <div className="w-1/2">
            <Button
              type="submit"
              btnType="normal"
              isLoading={doseRecordLoading?.isLoading}
              classname={`${
                (futureSchedule || alreadyVaccinated) &&
                "opacity-50 pointer-events-none"
              }`}
            >
              Save
            </Button>
          </div>
          <div className="w-1/2">
            <Button type="submit" btnType="normal" clickHandler={closePopup}>
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default HPVVaccinePopup;
