import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { AcceptIcon } from "../../assets/Icons";
import TableLoader from "../common/loader/TableLoader";
import TableNoDataFound from "../common/loader/TableNoDataFound";
import { useState } from "react";
import Popup from "../common/Popup";
import Button from "../common/Button";
import { useSendReminderToDefaulterMutation } from "../../apis/authApi";
import { useDispatch } from "react-redux";
import { addNotification } from "../../store/slices/toaster-slice";

const DefaulterTable = ({ lists, loading }) => {
  const [reminderPopup, setReminderPopup] = useState(false);
  const [sendReminder, sendReminderLoading] =
    useSendReminderToDefaulterMutation();
  const [reminderData, setReminderData] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSendReminderPopup = (data) => {
    setReminderPopup(!reminderPopup);
    if (data) {
      setReminderData(data);
    }
  };

  // Handle Send Reminder
  const handleSendReminder = async (data) => {
    try {
      const response = await sendReminder(data?.childNo).unwrap();
      dispatch(addNotification({ type: "success", message: "Sent Reminder" }));
      handleSendReminderPopup();
    } catch (error) {
      if (error?.status == 401 || error?.status == 403) {
        sessionStorage.removeItem("access_token");
        navigate("/login");
        dispatch(
          addNotification({
            type: "error",
            message: "Token is invalid or expired",
          })
        );
      } else {
        dispatch(
          addNotification({ type: "error", message: "Unable to fetch!" })
        );
      }
    }
  };
  return (
    <div className="defaulter-table">
      <table className="">
        <thead>
          <tr>
            <td>#</td>
            <td>Child No.</td>
            <td>Child Name</td>
            <td>Mother's Name</td>
            <td>Vaccine</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <TableLoader />
          ) : lists?.length > 0 ? (
            lists.map((list, index) => {
              return (
                <tr key={list?.id}>
                  <td>{index + 1}</td>
                  <td className="text-base">
                    <Link
                      to={`/vaccination-card/${list?.childNo}`}
                      className="text-primary"
                    >
                      {list?.childNo}
                    </Link>
                  </td>
                  <td className="capitalize">{list?.childName}</td>
                  <td className="capitalize">{list?.motherName}</td>
                  <td className="">
                    <span className="flex flex-wrap gap-2">
                      {list?.vaccines?.length > 0 &&
                        list?.vaccines.map((vaccine, index) => {
                          return (
                            <span
                              key={index}
                              className="px-2 py-0.5 rounded-lg bg-danger text-white md:text-xs text-[10px] uppercase"
                            >
                              {vaccine}
                            </span>
                          );
                        })}
                    </span>
                  </td>
                  <td>
                    <span className="flex gap-2 items-center justify-end">
                      <div
                        onClick={() => handleSendReminderPopup(list)}
                        className="text-color_01F17E whitespace-nowrap flex gap-2 items-center cursor-pointer"
                      >
                        <span className="">
                          <AcceptIcon />
                        </span>
                        Send Reminder
                      </div>
                    </span>
                  </td>
                </tr>
              );
            })
          ) : (
            <TableNoDataFound />
          )}
        </tbody>
      </table>

      {reminderPopup && (
        <Popup
          title="Confirmation"
          width="400px"
          closeBtn={true}
          closeHandler={handleSendReminderPopup}
        >
          <div>
            <h3 className="text-center mb-5 font-medium text-lg">
              Want to send reminder?
            </h3>
            <div className="flex items-center gap-5">
              <Button
                type="submit"
                btnType="normal"
                clickHandler={handleSendReminderPopup}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                btnType="normal"
                clickHandler={() => handleSendReminder(reminderData)}
                isLoading={sendReminderLoading?.isLoading}
              >
                Send
              </Button>
            </div>
          </div>
        </Popup>
      )}
    </div>
  );
};

export default DefaulterTable;
